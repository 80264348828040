import moment from "moment-timezone";

export const getAmountAud = (obj: { [key: string]: any }) => {
  const amount = Number(obj.quantity) * Number(obj.unitPrice);
  const discount = (amount * Number(obj.discount)) / 100;
  const total = amount - discount;
  return total;
};
export const getTotalAmountAud = (obj: { [key: string]: any }) => {
  const amount = Number(obj.quantity) * Number(obj.unitPrice);
  const discount = (amount * Number(obj.discount)) / 100;
  const total = amount - discount;
  return total + (total * 10) / 100;
};

export const getTotalGst = (obj: { [key: string]: any }) => {
  const amount = Number(obj.quantity) * Number(obj.unitPrice);
  const discount = (amount * Number(obj.discount)) / 100;
  const total = amount - discount;
  return (total * 10) / 100;
};

export const makeScratchParameter = (
  id: string,
  isDraft: boolean,
  values: { [key: string]: any }
) => {
  const {
    todayDate,
    validDate,
    quoteno,
    projectname,
    draggableForms,
    comments,
  } = values;
  console.log(values,"DDFF")
  const todaysDate = moment().format("YYYY-MM-DD");
  const itemCosting = draggableForms
    .filter((form: any) => form.boxType === 1)[0]
    .field.costItems.map((cost: any) => ({
      ...cost,
      amountAud: getAmountAud(cost).toFixed(2),
    }));
  const titleAndDescription = draggableForms.map((form: any) =>
    form.boxType === 0
      ? {
          ...form.field,
        }
      : {
          title: "COSTS",
          description: "String",
        }
  );
  console.log(validDate,"DD")
  if (id && values) {
    return {
      companyId: localStorage.getItem("user_id"),
      reqJobId: id,
      quoteSelection: "SCRATCH",
      quoteDate: todaysDate,
      quoteValidDate: validDate,
      quoteNumber: quoteno,
      requestType: "RFQ",
      projectName: projectname,
      isDraft,
      itemCosting,
      titleAndDescription: [...titleAndDescription],
      comments,
    };
  }
  return {};
};

export const makeParameterFromObjValues = (
  id: string,
  isDraft: boolean,
  obj: { [key: string]: any | null },
  comments: string
) => {
  console.log(obj, "object");
  if (obj) {
    
    return {
      companyId: localStorage.getItem("user_id"),
      reqJobId: id,
      quoteSelection: "SCRATCH",
      quoteDate: moment(new Date(obj["0.quoteDate"])).format("YYYY-MM-DD"),
      quoteValidDate: moment(new Date(obj["0.quoteValidDate"]))
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
      quoteNumber: obj["0.quoteNumber"],
      requestType: "RFQ",
      projectName: obj["0.projectName"],
      isDraft,
      itemCosting: obj["0.itemCosting"],
      titleAndDescription: obj["0.titleAndDescription"],
      comments,
    };
  }
  return {};
};

export const convertNestedObjectToOne = (obj: { [key: string]: any }) => {
  // The object which contains the
  // final result
  let result: { [key: string]: any } = {};

  // loop through the object "ob"
  for (const i in obj) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
      const temp = convertNestedObjectToOne(obj[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + "." + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = obj[i];
    }
  }
  return result;
};
