import Utils from "../../Utils";
import { RatesDetailsModel, ServiceProviderSearchModel } from "../../model";

export const ratesDetailsReducer = (
  state: RatesDetailsModel = new RatesDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.RATE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const serviceProviderSearchReducer = (
  state: ServiceProviderSearchModel = new ServiceProviderSearchModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SERVICE_PROVIDER_SERACH:
      return { ...state, ...action.payload };
    case Utils.ActionName.SERVICE_PROVIDER_SEARCH_RESET:
      return {
        serviceProviderSearch: [],
      };
    default:
      return state;
  }
};

