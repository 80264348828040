import {
  AdminTeamPermissionModel,
  EmployeeTeamPermissionModel,
  TeamMembersDataModel,
  EditTeamPermissionModel,
} from "../../../../model";
import Utils from "../../../../Utils";

export const teamMembersDataReducer = (
  state: TeamMembersDataModel = new TeamMembersDataModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EMPLOYEE_LIST:
      return { ...state, ...action.payload };
    case Utils.ActionName.SWITCH_SEARCH_RESULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const employeeTeamPermissionReducer = (
  state: EmployeeTeamPermissionModel = new EmployeeTeamPermissionModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EMPLOYEE_TEAM_PERMISSION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const adminTeamPermissionReducer = (
  state: AdminTeamPermissionModel = new AdminTeamPermissionModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.ADMIN_TEAM_PERMISSION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const editadminTeamPermissionReducer = (
  state: EditTeamPermissionModel = new EditTeamPermissionModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EDIT_PERMISSION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
