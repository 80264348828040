import { Typography } from "@mui/material";
import React from "react";

const NoResult = () => {
  return (
    <Typography
      sx={{
        display: "flex",
        justifyContent: "center",
        color: "#4f4f4f",
        fontSize: "24px",
        marginTop: "15%",
      }}
    >
      No Records Found
    </Typography>
  );
};

export default NoResult;
