import { TextField } from '@mui/material';
import { useField } from 'formik';

const DateTimePicker = ({
  name,
  disabled,
  ...otherProps
}:any) => {
  const [field, meta] = useField(name);
  const configDateTimePicker = {
    ...field,
    ...otherProps,
    type: 'date',
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
      placeholder:'Select'
    },  
  };

  if(meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return (
    <TextField
      {...configDateTimePicker}
      disabled={disabled}
    />
  );
};

export default DateTimePicker;
