export const MyDetailsReducer = (
  state = {
    success: false,
    profilePicture: "",
    name: "",
    email: "",
    isEdit: false,
    userType: localStorage.getItem("userType") || "",
    tools: false,
    detailRoles:null
  },
  action: any
) => {
  switch (action.type) {
    case "GET_CURRENT_USER":
      return {
        success: true,
        isEdit: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const getMyDetailsState = (state: any) => state.MyDetailsReducer;
