import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type ButtonContainedProps = {
  children: React.ReactNode;
  icon?: boolean;
} & ButtonProps;

const ButtonCustomize = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '1px solid #0DA4CE',
  textTransform: 'capitalize',
  padding: theme.spacing(0.8, 1.5),
  font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
    1.4
  )} Segoe UI, sans-serif`,
  color: 'white',
  borderRadius: 'unset',
  backgroundColor: '#0DA4CE',
  '&:hover': {
    backgroundColor: '#0DA4CE',
  },
}));

function ButtonContained({
  children,
  icon,
  ...otherProps
}: ButtonContainedProps) {
  return (
    <>
      {icon ? (
        <ButtonCustomize endIcon={<ArrowDropDownIcon />} {...otherProps}>
          {children}
        </ButtonCustomize>
      ) : (
        <ButtonCustomize {...otherProps}>{children}</ButtonCustomize>
      )}
    </>
  );
}

export default ButtonContained;
