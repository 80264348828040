import { FormikErrors, FormikTouched } from "formik";

export const COST_ITEM_SCHEMA = {
  description: "",
  quantity: "",
  unitPrice: "",
  gst: "10",
  discount: "0",
};

export const TABLE_HEADINGS = [
  {
    isBdr: true,
    width: "40%",
    name: "description",
    headText: "Description",
    isField: true,
  },
  {
    isBdr: true,
    width: "8%",
    name: "quantity",
    headText: "Qty",
    isField: true,
  },
  {
    isBdr: true,
    width: "12%",
    name: "unitPrice",
    headText: "Unit Price",
    isField: true,
  },
  {
    isBdr: true,
    width: "8%",
    name: "gst",
    headText: "GST",
    isField: true,
  },
  {
    isBdr: true,
    width: "10%",
    name: "discount",
    headText: "Dis (%)",
    isField: true,
  },
  {
    width: "15%",
    headText: "Amount AUD",
    name: "amountAud",
    isBdr: true,
    isField: false,
  },
  {
    width: "7%",
    headText: "",
    name: "crossbtn",
    isBdr: false,
    isField: false,
  },
];

export const getInitialValue = (isEdit: boolean, obj: any) => ({
  title: isEdit ? obj.title : "",
  costItems: isEdit ? obj.costItems : [COST_ITEM_SCHEMA],
});

export const checkErrors = (
  errors: FormikErrors<{ [key: string]: any }>,
  touched: FormikTouched<{ [key: string]: any }>
) => {
    if(Object.keys(errors).length === 0 && Object.keys(touched).length > 0) return 1;
    return 0;
};
