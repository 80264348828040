import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../reducer/globalReducer";
import Utils from "../../Utils";

export const scratchQuote =
  (parametersValues: { [key: string]: any }, callback?: () => void) =>
  (dispatch: any) => {
    if (!navigator.onLine) {
      dispatch(globalLoaderStart());
    }
    dispatch(globalLoaderStart());
    console.log("API", parametersValues);
    // return;

    Utils.API.putApiCall(
      Utils.endpoint.buildQuotesScratch,
      parametersValues,
      (res: any) => {
        const { data } = res;
        console.log("API", data);
        if (callback) callback();
        
          Utils.showAlert(1,data?.message)
        
        dispatch(globalLoaderEnd());
        window.location.reload()
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error);
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };
