import React from "react";
import { Stack } from "@mui/material";
import { FieldContainer } from "../../../components/styledComponents";
import InputField from "../../../components/inputfield/ScratchQuoteInput";
import CustomDatePicker from "../../../components/DataTimePicker/ScratchQuoteDatePicker";
import moment from "moment";
import { getInitialValues, checkErrors } from "./helper";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import useRedux from "../../../hooks/useRedux";
import Utils from "../../../Utils";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

const QuoteForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  submitRef,
  isValid,
  dirty,
}: any) => {
  const { dispatch, reducers } = useRedux();
  // const isReadyForSubmit = checkErrors(errors, touched);

  const stringifyObj = JSON.stringify(values);
  const {
    draggableForms,
    errorsForms,
    clientErrors,
    clientDetails,
  } = reducers.scratchTemplateReducer;

  const { JobDetailsData } = reducers.jobDetailsModelReducer;

  React.useEffect(() => {
    if ((isValid && dirty) && submitRef && submitRef.current) {
      submitRef.current.click();
    }
  }, [isValid, dirty, stringifyObj]);

  console.log({ values, clientDetails, errors, touched, isValid, dirty }, "Pname");

  return (
    <Form>
      <FieldContainer>
        <Stack direction="row" justifyContent={"space-between"}>
          <InputField
            fieldTag="Client"
            placeholder="Client Name"
            type={"text"}
            name={"clientname"}
            value={values.clientname}
          />
          <Stack direction={"row"} spacing={2}>
            <CustomDatePicker
              label="Date"
              placeholder="Select"
              disabled={true}
              value={moment().format("YYYY-MM-DD")}
              inputProps={{
                min: moment().add(1).format("YYYY-MM-DD"),
              }}
            />
            <CustomDatePicker
              label="Valid Until"
              placeholder="Please Select"
              value={
                JobDetailsData[0].isDraft
                  ? values.validDate
                  : new Date(values.validDate)
              }
              minDate={moment().add(1, "days").toDate()}
              maxDate={moment().add(1, "years").toDate()}
              onChange={(dateString: any) => {
                console.log(dateString, "DST");
                setFieldValue(
                  "validDate",
                  moment(new Date(dateString)).format("YYYY-MM-DD")
                );

                // dispatch({
                //   type: SCRATCH_TEMPLATE,
                //   payload: {
                //     clientErrors: false,
                //   },
                // });
              }}
            />
            <InputField
              fieldTag="Quote No."
              placeholder="Quote No."
              value={values.quoteno}
              type={"text"}
              name={"quoteno"}
              onChange={(evt: any) => {
                handleChange(evt);
                // dispatch({
                //   type: SCRATCH_TEMPLATE,
                //   payload: {
                //     clientErrors: false,
                //   },
                // });
              }}
              onBlur={handleBlur}
              error={Boolean(touched.quoteno && errors.quoteno)}
              helperText={
                Boolean(touched.quoteno && errors.quoteno) ? errors.quoteno : ""
              }
            />
          </Stack>
        </Stack>
        <Stack marginTop={"1.5rem"} direction="row" spacing={2}>
          <InputField
            type={"text"}
            name={"projectname"}
            fieldTag="Project Name"
            placeholder="RFQ"
            value={values.projectname}
            onChange={(evt: any) => {
              handleChange(evt);
              // dispatch({
              //   type: SCRATCH_TEMPLATE,
              //   payload: {
              //     clientErrors: true,
              //   },
              // });
            }}
            onBlur={handleBlur}
            error={Boolean(touched.projectname && errors.projectname)}
            helperText={
              Boolean(touched.projectname && errors.projectname)
                ? errors.projectname
                : ""
            }
          />

          <InputField
            fieldTag="Client Location"
            placeholder="Client Location"
            type={"text"}
            name={"clientlocation"}
            value={values.clientlocation}
          />
        </Stack>
        <button type="submit" style={{ display: "none" }} ref={submitRef} />
      </FieldContainer>
    </Form>
  );
};

export default function QuoteWrapper({ initialObj }: { [key: string]: any }) {
  const submitRef = React.useRef(null);
  const { dispatch } = useRedux();

  React.useEffect(() => {
    dispatch({
      type: SCRATCH_TEMPLATE,
      payload: {
        clientErrors: true,
        clientDetails: getInitialValues(initialObj),
      },
    });
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(initialObj)}
      validationSchema={Schema.ClientScratchSchema}
      onSubmit={(values) => {
        console.log("MMM/finalValues", values);
        dispatch({
          type: SCRATCH_TEMPLATE,
          payload: {
            clientErrors: true,
            clientDetails: { ...values },
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        submitForm,
        isValid,
        dirty,
      }) => {
        console.log("MMM/errors", { errors, touched });
        const quoteAttributes = {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          submitRef,
          isValid,
          dirty,
        };
        return <QuoteForm {...quoteAttributes} />;
      }}
    </Formik>
  );
}
