import { Box, Button, Stack, styled, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Utils from "../../../Utils";

import LocalImages from "../../../Utils/localImages";
import { UploadSpQuoteAsDraft } from "../actions";

const { BqOwnFolder } = LocalImages;

const Limit = 10 * 1024 * 1024;
 
const SPOwnMobile = () => {
  const { id } = useParams<any>();

  const history = useHistory();

  const dispatch = useDispatch();

  const FileHandler = (pdf: any) => {
    const data = {
      reqJobId: id,
      pdf,
    };
    dispatch(UploadSpQuoteAsDraft(data, history));
  };

  const onDrop = useCallback(async (acceptedFiles: any) => {
    // Do something with the files

    if (acceptedFiles[0]?.type !== "application/pdf") {
      Utils.showAlert(2, "Unsupported format");
      return;
    }

    if (acceptedFiles[0]?.size > Limit) {
      Utils.showAlert(2, "PDF size must not be more than 10 MB");
      return;
    }

    FileHandler(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noDrag: true,
  });

  return (
    <Box
      onClick={open}
      {...getRootProps()}
      sx={{
        height: "37px",
        width: "162px",
        // padding: "10px .8rem",
      }}
    >
      <input {...getInputProps()} />
      <Stack
        alignItems={"center"}
        direction="row"
        justifyContent="space-between"
        height="100%"
      >
        <Typography fontSize={"14px"}>Choose Files</Typography>
        <img src={BqOwnFolder} alt="folder" />
      </Stack>
    </Box>
  );
};

export default SPOwnMobile;
