import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";

type InputFieldProps = {
  type: string;
  getFieldProps?: any;
  id?: string;
  label: string;
  placeholder: string;
  errors?: any;
  touched?: any;
  disabled?: boolean;
  onChange?: any;
  fullWidth?: boolean;
  style?: any;
};

const InputField = ({
  type,
  getFieldProps,
  id,
  label,
  placeholder,
  errors,
  touched,
  disabled,
  style,
  ...rest
}: InputFieldProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          color: "var(--clientColor)",
        }}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <TextField
        sx={{
          marginTop: ".7rem",
          "& input": {
            padding: ".9rem .7rem",
            background: "#fff",
            // "&:disabled": {
            //   background: "#fff",
            // },
            "&:disabled": {
              backgroundColor: "#E0E0E0",
              color: "#828282",
            },
          },
          "& fieldset": {
            borderRadius: 0,
            border: "1px solid #bdbdbd !important",
          },
        }}
        style={style}
        placeholder={placeholder}
        type={type}
        disabled={Boolean(disabled)}
        {...getFieldProps(id)}
        {...rest}
      />
    </Box>
  );
};

export default InputField;
