/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Grid, Button, TextField } from "@mui/material";
import ModalWrapperInherit from "../../../../components/modal/modalWrapperInherit";
import CommonFunctions from "../../../../Utils/commonFunctions";
import { mobileUseStyles } from ".././selectTemplateCss";
import { ReducersModel } from "../../../../model";
import { theme } from "../../../../theme/theme";
import Schema from "../../../../schema";
import { Form, Formik } from "formik";
import Utils from "../../../../Utils";

const initialValue = {
  discount: "0",
  description: "",
  quantity: "",
  unitPrice: "",
  gst: "10",
  amountAud: "",
};

function BuildQuoteItemModal({ open, handleClose, heading }: any) {
  const classes = mobileUseStyles();
  const dispatch = useDispatch();

  const { itemCosting } = useSelector(
    (state: ReducersModel) => state.jobDetailsModelReducer
  );

  const handleChange = (evt: any, setFieldValue: any) => {
    const { value, name } = evt.target;

    setFieldValue(name, value);
  };

  const handleUpdate = (valueData: any, resetForm: any) => {
    dispatch({
      type: Utils.ActionName.JOB_DETAILS,
      payload: {
        itemCosting: [...itemCosting, valueData],
      },
    });
    resetForm();
    handleClose();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Formik
          initialValues={{
            ...initialValue,
          }}
          validationSchema={Schema.SelectTemplateSchemaMobileField}
          onSubmit={(values: any, { resetForm }: any) => {
            handleUpdate(values, resetForm);
          }}
          enableReinitialize
        >
          {({ values, errors, touched, handleBlur, setFieldValue }: any) => {
            useEffect(() => {
              let result;
              const { quantity, unitPrice, discount } = values;
              if (quantity !== "" || unitPrice !== "" || discount !== "") {
                if (+discount !== 0) {
                  result = String(
                    CommonFunctions.discountCal(quantity, unitPrice, discount)
                  );
                } else {
                  result = String(
                    CommonFunctions.withoutDiscountCal(quantity, unitPrice)
                  );
                }
                setFieldValue("amountAud", result);
              }
            }, [setFieldValue, values]);

            return (
              <ModalWrapperInherit
                open={open}
                handleClose={handleClose}
                sx={
                  {
                    // minHeight: "70vh",
                    // width: "70vw",
                    // borderRadius: (theme: any) => theme.spacing(0),
                  }
                }
              >
                <Form>
                  <Box
                    sx={{
                      height: "100vh",
                      width: "100vw",
                      overflow: "auto",
                    }}
                  >
                    <Box className={classes.modalWrapperChild}>
                      <Box
                        onClick={handleClose}
                        className={classes.handleCloseButton}
                      >
                        <ClearIcon />
                      </Box>
                      <Box sx={{ margin: 5 }}>
                        <Typography
                          sx={{
                            color: "var(--modalHeadingColor)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "20px",
                          }}
                        >
                          {heading}
                        </Typography>

                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography
                              sx={{ padding: "3px 0px" }}
                              variant="h5"
                            >
                              Description
                            </Typography>
                            <TextField
                              name="description"
                              size="small"
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              onBlur={handleBlur}
                              value={values.description}
                              error={Boolean(
                                errors.description &&
                                  touched.description &&
                                  errors.description
                              )}
                              helperText={
                                errors.description &&
                                touched.description &&
                                errors.description
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="h5">Quantity</Typography>
                            <TextField
                              name="quantity"
                              type="number"
                              size="small"
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              onBlur={handleBlur}
                              value={values.quantity}
                              error={Boolean(
                                errors.quantity &&
                                  touched.quantity &&
                                  errors.quantity
                              )}
                              helperText={
                                errors.quantity &&
                                touched.quantity &&
                                errors.quantity
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="h5">Unit Price</Typography>
                            <TextField
                              name="unitPrice"
                              type="number"
                              size="small"
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              onBlur={handleBlur}
                              value={values.unitPrice}
                              error={Boolean(
                                errors.unitPrice &&
                                  touched.unitPrice &&
                                  errors.unitPrice
                              )}
                              helperText={
                                errors.unitPrice &&
                                touched.unitPrice &&
                                errors.unitPrice
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ padding: "10px 0" }} variant="h5">
                              Discount (%)
                            </Typography>
                            <TextField
                              name="discount"
                              type="number"
                              size="small"
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              onBlur={handleBlur}
                              value={values.discount}
                              error={Boolean(
                                errors.discount &&
                                  touched.discount &&
                                  errors.discount
                              )}
                              helperText={
                                errors.discount &&
                                touched.discount &&
                                errors.discount
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ padding: "10px 0" }} variant="h5">
                              {" "}
                              GST
                            </Typography>
                            <TextField
                              disabled
                              type="number"
                              name="gst"
                              size="small"
                              value={values.gst}
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              onBlur={handleBlur}
                              // value={values.gst}
                              error={Boolean(
                                errors.gst && touched.gst && errors.gst
                              )}
                              helperText={
                                errors.gst && touched.gst && errors.gst
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={5}>
                            <Typography sx={{ padding: "10px 0" }} variant="h5">
                              Amount AUD
                            </Typography>
                            <TextField
                              name="amountAud"
                              size="small"
                              fullWidth
                              className={classes.textField}
                              onChange={(e: any) =>
                                handleChange(e, setFieldValue)
                              }
                              value={values.amountAud}
                              // onBlur={handleBlur}
                              error={Boolean(
                                errors.amountAud &&
                                  touched.amountAud &&
                                  errors.amountAud
                              )}
                              helperText={
                                errors.amountAud &&
                                touched.amountAud &&
                                errors.amountAud
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        ml: 5,
                        mr: 5,
                      }}
                    >
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          color: "var(--red)",
                          borderRadius: "unset",
                          border: "1px solid var(--clientColor)",
                          "&:hover": {
                            color: "var(--red)",
                          },
                          marginRight: "1rem",
                        }}
                        type="reset"
                      >
                        Delete
                      </Button>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                          borderRadius: "unset",
                          "&:hover": {
                            backgroundColor: "var(--buttonHover)",
                            color: "var(--white)",
                          },
                        }}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </ModalWrapperInherit>
            );
          }}
        </Formik>
      </ThemeProvider>
    </>
  );
}

export default memo(BuildQuoteItemModal);

// /* eslint-disable react-hooks/rules-of-hooks */
// import { memo, useEffect } from "react";
// import ClearIcon from "@mui/icons-material/Clear";
// import { ThemeProvider } from "@mui/material/styles";
// import { useDispatch, useSelector } from "react-redux";
// import { Typography, Box, Grid, Button, TextField } from "@mui/material";
// import ModalWrapperInherit from "../../../../components/modal/modalWrapperInherit";
// import CommonFunctions from "../../../../Utils/commonFunctions";
// import { mobileUseStyles } from ".././selectTemplateCss";
// import { ReducersModel } from "../../../../model";
// import { theme } from "../../../../theme/theme";
// import Schema from "../../../../schema";
// import { Form, Formik } from "formik";
// import Utils from "../../../../Utils";

// const initialValue = {
//   discount: "0",
//   description: "",
//   quantity: "",
//   unitPrice: "",
//   gst: "10",
//   amountAud: "",
// };

// function BuildQuoteItemModal({ open, handleClose, heading }: any) {
//   const classes = mobileUseStyles();
//   const dispatch = useDispatch();

//   const { itemCosting } = useSelector(
//     (state: ReducersModel) => state.jobDetailsModelReducer
//   );

//   const handleChange = (evt: any, setFieldValue: any) => {
//     const { value, name } = evt.target;

//     setFieldValue(name, value);
//   };

//   const handleUpdate = (valueData: any, resetForm: any) => {
//     dispatch({
//       type: Utils.ActionName.JOB_DETAILS,
//       payload: {
//         itemCosting: [...itemCosting, valueData],
//       },
//     });
//     resetForm();
//     handleClose();
//   };

//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         <Formik
//           initialValues={{
//             ...initialValue,
//           }}
//           validationSchema={Schema.SelectTemplateSchemaMobileField}
//           onSubmit={(values: any, { resetForm }: any) => {
//             handleUpdate(values, resetForm);
//           }}
//           enableReinitialize
//         >
//           {({ values, errors, touched, handleBlur, setFieldValue }: any) => {
//             useEffect(() => {
//               let result;
//               const { quantity, unitPrice, discount } = values;
//               if (quantity !== "" || unitPrice !== "" || discount !== "") {
//                 if (+discount !== 0) {
//                   result = String(
//                     CommonFunctions.discountCal(quantity, unitPrice, discount)
//                   );
//                 } else {
//                   result = String(
//                     CommonFunctions.withoutDiscountCal(quantity, unitPrice)
//                   );
//                 }
//                 setFieldValue("amountAud", result);
//               }
//             }, [setFieldValue, values]);

//             return (
//               <ModalWrapperInherit
//                 open={open}
//                 handleClose={handleClose}
//                 sx={{
//                   minHeight: "70vh",
//                   width: "70vw",
//                   borderRadius: (theme: any) => theme.spacing(0),
//                 }}
//               >
//                 <Form>
//                   <Box  className={classes.modalWrapperChild}>
//                     <Box
//                       onClick={handleClose}
//                       className={classes.handleCloseButton}
//                     >
//                       <ClearIcon />
//                     </Box>
//                     <Box sx={{ margin: 5 }}>
//                       <Typography
//                         sx={{
//                           color: "var(--modalHeadingColor)",
//                           fontFamily: "Inter",
//                           fontSize: "16px",
//                           fontWeight: 500,
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           flexWrap: "wrap",
//                           marginBottom: "20px",
//                         }}
//                       >
//                         {heading}
//                       </Typography>

//                       <Grid container spacing={1}>
//                         <Grid item xs={12} sm={12} md={12}>
//                           <Typography sx={{ padding: "3px 0px" }} variant="h5">
//                             Description
//                           </Typography>
//                           <TextField
//                             name="description"
//                             size="small"
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             onBlur={handleBlur}
//                             value={values.description}
//                             error={Boolean(
//                               errors.description &&
//                                 touched.description &&
//                                 errors.description
//                             )}
//                             helperText={
//                               errors.description &&
//                               touched.description &&
//                               errors.description
//                             }
//                           />
//                         </Grid>

//                         <Grid item xs={6}>
//                           <Typography variant="h5">Quantity</Typography>
//                           <TextField
//                             name="quantity"
//                             type="number"
//                             size="small"
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             onBlur={handleBlur}
//                             value={values.quantity}
//                             error={Boolean(
//                               errors.quantity &&
//                                 touched.quantity &&
//                                 errors.quantity
//                             )}
//                             helperText={
//                               errors.quantity &&
//                               touched.quantity &&
//                               errors.quantity
//                             }
//                           />
//                         </Grid>

//                         <Grid item xs={6}>
//                           <Typography variant="h5">Unit Price</Typography>
//                           <TextField
//                             name="unitPrice"
//                             type="number"
//                             size="small"
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             onBlur={handleBlur}
//                             value={values.unitPrice}
//                             error={Boolean(
//                               errors.unitPrice &&
//                                 touched.unitPrice &&
//                                 errors.unitPrice
//                             )}
//                             helperText={
//                               errors.unitPrice &&
//                               touched.unitPrice &&
//                               errors.unitPrice
//                             }
//                           />
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography sx={{ padding: "10px 0" }} variant="h5">
//                             Discount (%)
//                           </Typography>
//                           <TextField
//                             name="discount"
//                             type="number"
//                             size="small"
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             onBlur={handleBlur}
//                             value={values.discount}
//                             error={Boolean(
//                               errors.discount &&
//                                 touched.discount &&
//                                 errors.discount
//                             )}
//                             helperText={
//                               errors.discount &&
//                               touched.discount &&
//                               errors.discount
//                             }
//                           />
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography sx={{ padding: "10px 0" }} variant="h5">
//                             {" "}
//                             GST
//                           </Typography>
//                           <TextField
//                             type="number"
//                             name="gst"
//                             size="small"
//                             value={values.gst}
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             onBlur={handleBlur}
//                             // value={values.gst}
//                             error={Boolean(
//                               errors.gst && touched.gst && errors.gst
//                             )}
//                             helperText={errors.gst && touched.gst && errors.gst}
//                           />
//                         </Grid>
//                         <Grid item xs={12} sm={6} md={5}>
//                           <Typography sx={{ padding: "10px 0" }} variant="h5">
//                             Amount AUD
//                           </Typography>
//                           <TextField
//                             name="amountAud"
//                             size="small"
//                             fullWidth
//                             className={classes.textField}
//                             onChange={(e: any) =>
//                               handleChange(e, setFieldValue)
//                             }
//                             value={values.amountAud}
//                             // onBlur={handleBlur}
//                             error={Boolean(
//                               errors.amountAud &&
//                                 touched.amountAud &&
//                                 errors.amountAud
//                             )}
//                             helperText={
//                               errors.amountAud &&
//                               touched.amountAud &&
//                               errors.amountAud
//                             }
//                           />
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   </Box>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       ml: 5,
//                       mr: 5,
//                     }}
//                   >
//                     <Button
//                       variant="outlined"
//                       sx={{
//                         color: "var(--red)",
//                         borderRadius: "unset",
//                         border: "1px solid var(--clientColor)",
//                         "&:hover": {
//                           color: "var(--red)",
//                         },
//                       }}
//                       type="reset"
//                     >
//                       Delete
//                     </Button>
//                     <Button
//                       type="submit"
//                       variant="contained"
//                       sx={{
//                         color: "var(--white)",
//                         borderRadius: "unset",
//                         "&:hover": {
//                           backgroundColor: "var(--buttonHover)",
//                           color: "var(--white)",
//                         },
//                       }}
//                     >
//                       Update
//                     </Button>
//                   </Box>
//                 </Form>
//               </ModalWrapperInherit>
//             );
//           }}
//         </Formik>
//       </ThemeProvider>
//     </>
//   );
// }

// export default memo(BuildQuoteItemModal);
