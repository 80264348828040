import { useHistory, useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Utils from  "../../Utils/index";
import React, {useEffect} from "react";
import {
  BoxContainer,
  StyledIconButton,
} from "../../components/styledComponents";
import { scratchQuote } from "./actions";
import { makeScratchParameter } from "./helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; 
import DownloadIcon from "@mui/icons-material/Download";
import QuoteWrapper from "./QuoteWrapper";
import DraggableWrapper from "./DraggableWrapper";
import FilledButton from "../../components/button/filledButton";
import {getIsDisabledValue}from "./DraggableWrapper/helper";
import { Link } from "@mui/material";
import { companyJobDetails } from "../sp/spBuildsQuotes/action";
import useRedux from "../../hooks/useRedux";
import { convertNestedObjectToOne } from "./helper";

const HeaderContainer = () => {
  const {
    dispatch,
    reducers: {
      jobDetailsModelReducer: { JobDetailsData },
    },
  } = useRedux();
  const history = useHistory();
  const { reducers } = useRedux();
  const { draggableForms, errorsForms, clientErrors, clientDetails } =
    reducers.scratchTemplateReducer;

    const isDisabled: boolean = getIsDisabledValue(errorsForms, clientErrors);
  let { id } = useParams<{ id: string }>(); 

  useEffect(() => {
    dispatch(companyJobDetails(id));
    dispatch({
      type: Utils.ActionName.SCRATCH_TEMPLATE, 
      payload: {
        draggableForms: [],
        errorsForms: [],
        clientErrors: false,
        clientDetails: null,
      },
    });
  }, [dispatch, id]);

  

  const handleClickPreview = () => {
    history.push(
      Utils.Pathname.BUILD_QUOTE_SCRATCH_PREVIEW+`/${id}`
    );
  };

  const objValues =
    Array.isArray(JobDetailsData) && JobDetailsData.length > 0
      ? convertNestedObjectToOne(JobDetailsData[0])
      : null;
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Typography>Build Quote</Typography>
      <Stack direction={"row"} spacing={2}>
      {JobDetailsData[0]?.isDraft ?
        <StyledIconButton>
          <RemoveRedEyeIcon onClick={handleClickPreview} />  
        </StyledIconButton>:
        <StyledIconButton disabled>
        <RemoveRedEyeIcon />  
      </StyledIconButton>
        }
        {
          objValues?.downloadUrl ?
          <StyledIconButton>
          <Link href={objValues?.downloadUrl} underline="none">
            <DownloadIcon />
          </Link>
        </StyledIconButton> :
        <StyledIconButton disabled>
          <DownloadIcon />
      </StyledIconButton>
        }
        
        <FilledButton 
            width={125}
            height={35}
            background={isDisabled ? "var(--primaryBlueColor)" : "#E0E0E0"}
            color={isDisabled ? "var(--white)" :"#000000"}
            border={"none"}
            disabled={!isDisabled}
            onClick={() => {
              dispatch(
                scratchQuote(
                  makeScratchParameter(id, true, {
                    ...clientDetails,
                    draggableForms,
                    comments: "",
                  }), 
                )
              );
            }}>
          {"Save as draft"}
        </FilledButton>
      </Stack>
    </Stack>
  );
};

const SpCreateScratch = () => {
  const { id } = useParams<{ id: string }>();
  const {
    dispatch,
    reducers: {
      jobDetailsModelReducer: { JobDetailsData },
    },
  } = useRedux();

  const costObj= {title:"COSTS", description:"String"}

  const objValues =
    Array.isArray(JobDetailsData) && JobDetailsData.length > 0
      ? convertNestedObjectToOne(JobDetailsData[0])
      : null;

  // console.log("NNNN", objValues);


  React.useEffect(() => {
    dispatch(companyJobDetails(id));
  }, [dispatch]);

  return (
    <BoxContainer>
      {objValues && (
        <React.Fragment>
          <HeaderContainer />
          <QuoteWrapper initialObj={objValues} />
          <DraggableWrapper initialObj={objValues} />
        </React.Fragment>
      )}
    </BoxContainer>
  );
};

export default SpCreateScratch;
