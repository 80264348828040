import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import moment from "moment";
import { capitalizeFirstLetter, checkTimeRange } from "../../../../../../Utils/commonFunctions";
import {
  RfqAcceptedChip,
  RfqCancelledChip,
  RfqCompletedChip,
  RfqDraftChip,
  RfqSentChip,
} from "../../common/styledComponent";
import { useHistory } from "react-router-dom";
import Utils from "../../../../../../Utils";
import NoRequests from "../../../common/NoRequests";
import SearchNotFound from "../../common/SearchNotFound";

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 500,
  flex: 1,
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  flex: 1,
});

const StyledButton = styled(Button)({
  textTransform: "initial",
  border: "1px solid #9EDBEB",
  color: "#097390",
  fontSize: ".875rem",
  fontWeight: 500,
  borderRadius: 0,
  padding: ".688rem 1.35rem",
  "&:hover": {
    border: "1px solid #9EDBEB",
    color: "#097390",
  },
});

const ViewAllMobile = ({ data, search }: any) => {
  const getMonth = (date: any) => {
    return `${moment(date).format("MMM")} ${moment(date).format("D")}, ${moment(
      date
    ).format("YYYY")}`;
  };

  const history = useHistory();

  const BuildQuoteHandler = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`);
  };

  const routeToDetailBqmodalOpen = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`, true);
  };

  return (
    <>
      {data?.length
        ? data?.map((el: any) => {
            return (
              <Box
                sx={{
                  padding: "1.5rem",
                  borderBottom: "1px solid #e0e0e0",
                  borderTop: "1px solid #e0e0e0",
                }}
                key={el?._id}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                    Date received
                  </PrimaryBlackTypoGraphy>
                  <Box flex={1}>
                    {/* {getMomentDate(el?.createdAt, "DD/MM/YYYY")} */}

                    <SecondaryBlackTypoGraphy marginBottom={".37rem"}>
                      {getMonth(el?.createdAt || new Date())}
                    </SecondaryBlackTypoGraphy>
                    <SecondaryBlackTypoGraphy>
                      Received {checkTimeRange(el?.createdAt)}
                    </SecondaryBlackTypoGraphy>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Client</PrimaryBlackTypoGraphy>
                  <SecondaryBlackTypoGraphy>
                    {el?.job_detail?.clientDetail?.businessName}
                  </SecondaryBlackTypoGraphy>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Request type</PrimaryBlackTypoGraphy>
                  <SecondaryBlackTypoGraphy>
                  {capitalizeFirstLetter(el?.requestType).replaceAll("_"," ")}
                  </SecondaryBlackTypoGraphy>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>
                    Request details
                  </PrimaryBlackTypoGraphy>
                  <Box flex="1">
                    {el?.job_detail?.projectType?.length
                      ? el?.job_detail?.projectType?.map((name: any) => {
                          return (
                            <SecondaryBlackTypoGraphy key={name}>
                              {capitalizeFirstLetter(name).replaceAll("_"," ")}
                            </SecondaryBlackTypoGraphy>
                          );
                        })
                      : null}
                  </Box>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Date needed</PrimaryBlackTypoGraphy>
                  {el?.requestType === "RFQ" ? (
                    <SecondaryBlackTypoGraphy marginBottom={".31rem"}>
                      -
                    </SecondaryBlackTypoGraphy>
                  ) : (
                    <>
                      <SecondaryBlackTypoGraphy marginBottom={".31rem"}>
                      {moment(
                                new Date(
                                  el?.job_detail?.timeFrame[0]?.startDate
                                )
                              ).format("DD/MM/YYYY")}
                      </SecondaryBlackTypoGraphy>
                      
                    </>
                  )}
                </Stack>

                {el?.requestType === "RFQ" && el?.status !== "PENDING" ? (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    marginTop="1.125rem"
                  >
                    <PrimaryBlackTypoGraphy>Status</PrimaryBlackTypoGraphy>
                    <Box flex="1">
                      {el?.status === "SENT" ? (
                        <RfqSentChip>Sent</RfqSentChip>
                      ) : el?.status === "DRAFT" ? (
                        <RfqDraftChip>draft</RfqDraftChip>
                      ) : el?.status === "COMPLETED" ? (
                        <RfqCompletedChip>Completed</RfqCompletedChip>
                      ) : el?.status === "CANCELLED" ? (
                        <RfqCancelledChip>Cancelled</RfqCancelledChip>
                      ) : (
                        <Typography>{el?.status}</Typography>
                      )}
                    </Box>
                  </Stack>
                ) : null}

                {el?.requestType === "RFQ" && el?.status === "PENDING" ? (
                  el?.isDraft ? (
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      marginTop="1.125rem"
                    >
                      <PrimaryBlackTypoGraphy>Status</PrimaryBlackTypoGraphy>
                      <Box flex="1">
                        <RfqDraftChip>Draft</RfqDraftChip>
                      </Box>
                    </Stack>
                  ) : (
                    <Stack direction="row" marginTop="1.75rem">
                      <StyledButton
                        sx={{
                          marginRight: "1rem",
                        }}
                        fullWidth
                        onClick={routeToDetailBqmodalOpen(el?._id)}
                        disableRipple
                      >
                        Build Quote
                      </StyledButton>
                    </Stack>
                  )
                ) : null}
              </Box>
            );
          })
        : null}
      {!search && !data?.length ? <NoRequests /> : null}
      {search && !data?.length ? <SearchNotFound /> : null}
    </>
  );
};

export default ViewAllMobile;
