import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';

type ButtonOutlinedProps = {
  children: React.ReactNode;
  icon: boolean;
  editValue: boolean;
} & ButtonProps;

const ButtonCustomize = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '1px solid #E0E0E0',
  textTransform: 'capitalize',
  padding: theme.spacing(0.8, 1.5),
  font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
    1.4
  )} Segoe UI, sans-serif`,
  borderRadius: 'unset',
  backgroundColor: '#FAFAFA',
  '&:hover': {
    backgroundColor: '#fff',
  },
}));

function ButtonOutlined({
  children,
  icon,
  editValue,
  ...otherProps
}: ButtonOutlinedProps) {
  return (
    <>
      {icon ? (
        editValue ? (
          <Box>
            {/* <EditIcon /> */}
          <ButtonCustomize startIcon={<EditIcon style={{color: 'white', fontSize: 'small'}} />} {...otherProps}>
            {children}
          </ButtonCustomize>
          </Box>
        ) : (
          <ButtonCustomize endIcon={<ArrowDropDownIcon />} {...otherProps}>
            {children}
          </ButtonCustomize>
        )
      ) : (
        <ButtonCustomize {...otherProps}>{children}</ButtonCustomize>
      )}
    </>
  );
}

export default ButtonOutlined;
