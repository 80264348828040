import { Box, Breadcrumbs, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import Utils from "../../Utils";

type LinkData = {
  name: string;
  link?: string;
};

type BreadcrumbProps = {
  metaData: LinkData[];
  rfqId: number | string;
};

function Breadcrumb({ metaData, rfqId }: BreadcrumbProps) {
  const history = useHistory();

  const handleClickBreadcrumb = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: { id: rfqId },
    });
  };
  return (
    <Breadcrumbs
      separator={<ArrowForwardIosIcon fontSize="small" color="inherit" />}
      aria-label="breadcrumb"
    >
      {metaData.map((item: LinkData, index: number) => {
        if (item?.link) {
          return (
            <Box key={index} onClick={handleClickBreadcrumb}>
              <Typography variant="h4">{item.name}</Typography>
            </Box>
          );
        } else {
          return (
            <Typography
              variant="h5"
              color="inherit"
              key={index}
              sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            >
              {item.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
