import { JobDetailsModel } from "../../model";
import Utils from "../../Utils";

export const jobDetailsReducer = (
    state: JobDetailsModel = new JobDetailsModel(),
    action: any
  ) => {
    switch (action.type) {
      case Utils.ActionName.JOB_DETAILS:
        return { ...state, ...action.payload };
      case Utils.ActionName.OTHER_JOB_DETAIL:
        return { ...state, ...action.payload };
      case Utils.ActionName.JOB_ID:
        return { ...state, ...action.payload };
      case Utils.ActionName.TAB_VALUE:
        return { ...state, ...action.payload };
      case Utils.ActionName.BOOKED_DETAILS:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };