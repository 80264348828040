import moment from "moment";
import LocalImages from "./localImages";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

import Utils from ".";

const collectClass = (multiClass: [string]) => {
  let makeClass = "";
  multiClass?.forEach((cls: string) => makeClass + cls + " ");
  return makeClass;
};

const setLocalStorage = (data: any) => {
  const { accessToken, email, name, userId, isEmailVerified } = data;
  const accToken = localStorage.getItem("accessToken");
  const user_email = localStorage.getItem("user_email");
  const user_id = localStorage.getItem("user_id");
  const user_name = localStorage.getItem("user_name");
  const user_isEmailVerified = localStorage.getItem("user_isEmailVerified");

  if (!accToken) {
    localStorage.setItem("accessToken", accessToken);
  }
  if (!user_email) {
    localStorage.setItem("user_email", email);
  }
  if (!user_id) {
    localStorage.setItem("user_id", userId);
  }
  if (!user_name) {
    localStorage.setItem("user_name", name);
  }
  if (!user_isEmailVerified) {
    localStorage.setItem("user_isEmailVerified", isEmailVerified);
  }
};
const momentFunction = (presentTime: any, createdAt: any) => {
  return moment(presentTime).valueOf() - moment(createdAt).valueOf();
};

export const getMomentDate = (date: any, Format: any) => {
  return moment(date).format(Format);
};

const convertTimeIntoSec = (presentTime: any, createdAt: any) => {
  const s = Math.trunc(momentFunction(presentTime, createdAt) / 1000);
  return s;
};

const convertTimeIntoMin = (presentTime: any, createdAt: any) => {
  const m = Math.trunc(momentFunction(presentTime, createdAt) / 1000 / 60);
  return m;
};
const convertTimeIntoHour = (presentTime: any, createdAt: any) => {
  const h = Math.trunc(momentFunction(presentTime, createdAt) / 1000 / 60 / 60);
  return h;
};
const convertTimeIntoDay = (presentTime: any, createdAt: any) => {
  const d = Math.trunc(
    momentFunction(presentTime, createdAt) / 1000 / 60 / 60 / 24
  );
  return d;
};
const convertTimeIntoWeek = (presentTime: any, createdAt: any) => {
  const d = Math.trunc(
    momentFunction(presentTime, createdAt) / 1000 / 60 / 60 / 24 / 7
  );
  return d;
};

const discountCal = (quantity: any, unitPrice: any, discount: any) => {
  const result =
    +quantity * +unitPrice - (+discount * +quantity * +unitPrice) / 100;
  return result;
};

const withoutDiscountCal = (quantity: any, unitPrice: any) => {
  const result = +quantity * +unitPrice;
  return result;
};
export function getFileType(file: string): string {
  let fileType = "";
  if (file.includes(".pdf")) {
    fileType = "pdf";
  }
  if (file.includes(".doc")) {
    fileType = "doc";
  }
  if (file.includes(".xlsx")) {
    fileType = "xlsx";
  }
  if (file.includes(".jpg")) {
    fileType = "jpeg";
  }
  if (file.includes(".jpeg")) {
    fileType = "jpeg";
  }
  if (file.includes(".png")) {
    fileType = "png";
  }
  return fileType;
}



export const getNotificationTimeStatus = function (date: any) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  let seconds = Math.floor((new Date().valueOf() - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "month";
    } else {
      interval = Math.floor(seconds / 604800);
      if (interval >= 1) {
        intervalType = "week";
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = "day";
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }

  return `${interval} ${intervalType}`;
};

const ComputeTime = (createdAt: any, timeUnit: any) => {
  let created = moment(createdAt);
  let now = moment(Date.now());
  return now.diff(created, timeUnit);
};

export const checkTimeRange = (date: any) => {
  const seconds = ComputeTime(date, "seconds");

  // 5 sec
  if (seconds <= 5) {
    return "Just now";
  }
  //59sec
  if (seconds <= 59) {
    return `${seconds} Seconds ago`;
  }
  //59min
  if (seconds <= 3599) {
    return `${ComputeTime(date, "minutes")} minutes ago`;
  }
  // 1 to 23:59hours
  if (seconds <= 86399) {
    return `${ComputeTime(date, "hours")} hours ago`;
  }
  // 1 to 7 days
  if (seconds <= 604800) {
    return `${moment(date).format("dddd")} ${moment(date).format("hh:mm A")}`;
  }
  if (seconds > 604800) {
    return `${getMomentDate(date, "DD/MM/YYYY")} ${moment(date).format(
      "hh:mm A"
    )}`;
  }
};

export const ifStringThenTrim = (value: any) => {
  if (typeof value === "string") {
    return value.trim();
  }
  return value;
};

export const capitalizeFirstLetter = (str: string) => {
  let i,
    frags = str?.toLowerCase()?.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i].slice(1);
  }
  return frags?.join(" ");
  // converting first letter to uppercase
  // let words = input.split(' ');
  //   const CapitalizedWords:any = [];
  //   words.forEach(element => {
  //       CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length).toLowerCase());
  //   });
  //   return CapitalizedWords.join(' ');
};


  export const getDatesInRange = (startDate: any, endDate: any) => {
    const diffDays =
      (new Date(endDate).getTime() + 1 - new Date(startDate).getTime()) /
      1000 /
      60 /
      60 /
      24;
    if (Math.floor(diffDays) <= 2) {
      return `1 day`;
    } else if(Math.floor(diffDays) >=7 && Math.floor(diffDays)<= 14){
      return `1 week`;
    } else if(Math.floor(diffDays) >=14 && Math.floor(diffDays)<= 30) 
    {return `${(Math.floor(diffDays)/7).toFixed(0)} weeks`;} 
    else if(Math.floor(diffDays) >=30 &&  Math.floor(diffDays)<45  ){
      return `1 month`;
    }
    else if (Math.floor(diffDays) >=45 && Math.floor(diffDays)<=365 ){
      return `${(Math.floor(diffDays)/30).toFixed(0)} months`;
    }
    else {
      return `${Math.floor(diffDays)} days`;
    }
  };

  export function getDatesInRangeM(startDate: any, endDate: any) {
    const date = new Date(startDate);
  
    // ✅ Exclude start date
    // date.setDate(date?.getDate() + 1);
  
    const dates = [];
  
    // ✅ Exclude end date
    while (date < endDate) {
      dates.push(moment(new Date(date)).format("YYYY-MM-DD"));
      date.setDate(date?.getDate() + 1);
    }
  
    return dates;
  }
  


export const checkAbn = (str: string) => {
  const abnArray = Array.from(str);
  if (Number(abnArray[0]) < 1) return true;

  const { abnWeight } = Utils.Constants;
  const totalSum = abnArray.reduce(
    (sum: number, currVal: string, currIndex: number) => {
      if (currIndex === 0) {
        return sum + (Number(currVal) - 1) * abnWeight[currIndex];
      }
      return sum + Number(currVal) * abnWeight[currIndex];
    },
    0
  );
  return totalSum % 89 === 0;
};

export const getCreatedDate = (date: any) => {
  return moment(date).utc().local().format("DD/MM/YYYY");
};

export const getTime = (time: string) => {
  return moment(time).utc().local().format("hh:mm A");
};

const convertHtmlStringToEditorState = (htmlString: any) => {
  const blocksFromHtml = htmlToDraft(htmlString);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  // const blockFromHTML = htmlToDraft(htmlString);
  // return EditorState.createWithContent(
  //   ContentState.createFromBlockArray(
  //     blockFromHTML.contentBlocks,
  //     blockFromHTML.entityMap
  //   )
  // );
  return editorState;
};

// function convert_date_format(date_input:string)
// {
//     var split_space = date_input.split(' ');
//     var date_part = space[0];
//     var date_explode = date_part.split('-');
//     var new_date = date_explode[2]+'-'+date_explode[1]+'-'+date_explode[0]+' '+space[1];
//     return new_date;
// }

const CommonFunction = {
  collectClass,
  setLocalStorage,
  convertTimeIntoSec,
  convertTimeIntoMin,
  convertTimeIntoHour,
  convertTimeIntoDay,
  convertTimeIntoWeek,
  capitalizeFirstLetter,
  discountCal,
  withoutDiscountCal,
  getNotificationTimeStatus,
  getFileType,
  ifStringThenTrim,
  checkAbn,
  getCreatedDate,
  getTime,
  convertHtmlStringToEditorState,
};

export default CommonFunction;
