import { Box, Button, Stack, styled, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import Utils from "../../../../Utils";

import LocalImages from "../../../../Utils/localImages";
import { getBuildQuoteOwnState } from "../../reducer";

const { SpOwnUpload } = LocalImages;

const SubmitButton = styled(Button)(({ theme }) => ({
  padding: "16px 12px",
  width: "94px",
  height: "36px",
  background: "#0da4ce",
  "&:hover": {
    background: "#0da4ce",
  },
  fontSize: "10px",
  color: "#fff",
  borderRadius: "0",
  fontWeight: 600,
}));

const baseStyle = {
  border: "1px dashed #e0e0e0",
};

const Limit = 10 * 1024 * 1024;

const SelectFileDrop = ({ id, fileHandler }: any) => {
  const onDrop = useCallback(async (acceptedFiles: any) => {
    // Do something with the files

    if (acceptedFiles[0]?.type !== "application/pdf") {
      Utils.showAlert(2, "Unsupported format");
      return;
    }
    if (acceptedFiles[0]?.size > Limit) {
      Utils.showAlert(2, "PDF size must not be more than 10 MB");
      return;
    }

    fileHandler(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  const styles = () => {
    return { ...baseStyle };
  };

  return (
    <Box
      {...getRootProps({ style: styles() })}
      sx={{
        height: "100%",
      }}
    >
      <input {...getInputProps()} />
      <Stack
        sx={{ height: "100%" }}
        justifyContent="center"
        alignItems={"center"}
      >
        <Stack direction={"column"} alignItems={"center"}>
          <img src={SpOwnUpload} alt="spown" />
          <Typography fontWeight={500} color="#333" marginTop="1.8rem">
            Select a file or drag and drop here
          </Typography>
          <Typography
            fontWeight={400}
            color="#828282"
            marginTop="12px"
            marginBottom={"1.5rem"}
          >
            PDF file size no more than 10MB
          </Typography>

          <SubmitButton onClick={open}>Select File</SubmitButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SelectFileDrop;
