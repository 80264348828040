import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import moment from "moment";
import CommonFunction, { capitalizeFirstLetter, checkTimeRange, getDatesInRange } from "../../../../../../Utils/commonFunctions";
import {
  CompletedChip,
  PendingChip,
  RfqAcceptedChip,
  RfqCancelledChip,
  RfqCompletedChip,
  RfqDraftChip,
  RfqSentChip,
} from "../../common/styledComponent";
import { useHistory } from "react-router-dom";
import Utils from "../../../../../../Utils";
import NoRequests from "../../../common/NoRequests";
import SearchNotFound from "../../common/SearchNotFound";
import LocalImages from "../../../../../../Utils/localImages";

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 500,
  flex: 1,
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  flex: 1,
});

const StyledButton = styled(Button)({
  textTransform: "initial",
  border: "1px solid #9EDBEB",
  color: "#097390",
  fontSize: ".875rem",
  fontWeight: 500,
  borderRadius: 0,
  padding: ".688rem 1.35rem",
  "&:hover": {
    border: "1px solid #9EDBEB",
    color: "#097390",
  },
});

const ViewAllMobile = ({ data, search }: any) => {
  const getMonth = (date: any) => {
    return `${moment(date).format("MMM")} ${moment(date).format("D")}, ${moment(
      date
    ).format("YYYY")}`;
  };

  const history = useHistory();

  const BuildQuoteHandler = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`);
  };

  const routeToDetailBqmodalOpen = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`, true);
  };

  return (
    <>
      {data?.length
        ? data?.map((el: any) => {
            return (
              <Box
                sx={{
                  padding: "1.5rem",
                  borderBottom: "1px solid #e0e0e0",
                  borderTop: "1px solid #e0e0e0",
                }}
                key={el?._id}
              >
                <Stack direction={"row"} justifyContent="space-between">
                  <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                    Date received
                  </PrimaryBlackTypoGraphy>
                  <Box flex={1}>
                    {/* {getMomentDate(el?.createdAt, "DD/MM/YYYY")} */}

                    <SecondaryBlackTypoGraphy marginBottom={".37rem"}>
                      {getMonth(el?.createdAt || new Date())}
                    </SecondaryBlackTypoGraphy>
                    <SecondaryBlackTypoGraphy>
                      Received {checkTimeRange(el?.createdAt)}
                    </SecondaryBlackTypoGraphy>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Client</PrimaryBlackTypoGraphy>
                  <SecondaryBlackTypoGraphy>
                    {el?.job_detail?.clientDetail?.businessName}
                  </SecondaryBlackTypoGraphy>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Request type</PrimaryBlackTypoGraphy>
                  <SecondaryBlackTypoGraphy>
                  {capitalizeFirstLetter(el?.requestType).replaceAll("_"," ")}
                  </SecondaryBlackTypoGraphy>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>
                    Request details
                  </PrimaryBlackTypoGraphy>
                  <Box flex="1">
                    {el?.job_detail?.projectType?.length
                      ? el?.job_detail?.projectType?.map((name: any) => {
                          return (
                            <SecondaryBlackTypoGraphy key={name}>
                              {capitalizeFirstLetter(name).replaceAll("_"," ")}
                            </SecondaryBlackTypoGraphy>
                          );
                        })
                      : null}
                  </Box>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  marginTop="1.125rem"
                >
                  <PrimaryBlackTypoGraphy>Date needed</PrimaryBlackTypoGraphy>
                  {el?.requestType == "MAINTENANCE_SERVICE" ? (
                            <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                              {moment(
                                new Date(
                                  el?.job_detail?.timeFrame[0]?.startDate
                                )
                              ).format("DD/MM/YYYY")}
                              <SecondaryBlackTypoGraphy>
                                {/* {checkTimeRange(el?.createdAt)} */}
                                {`${` (${CommonFunction.getTime(
                                    el?.job_detail?.timeFrame[0]?.startDate
                                  )}`}-${`${CommonFunction.getTime(
                                    el?.job_detail?.timeFrame[0]?.endDate
                                  )})`}`}
                              </SecondaryBlackTypoGraphy>
                            </PrimaryBlackTypoGraphy>
                          ) : el?.requestType == "PROJECT_SUPPORT" ? (
                            <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                              {moment(
                                new Date(el?.job_detail?.estimateStartDate)
                              ).format("DD/MM/YYYY")}

                              <SecondaryBlackTypoGraphy>
                                {/* {checkTimeRange(el?.createdAt)} */}~
                                { getDatesInRange(
                                      new Date(
                                        el?.job_detail?.estimateStartDate
                                      ),
                                      new Date(el?.job_detail?.estimateEndDate)
                                    )
                                      }
                              </SecondaryBlackTypoGraphy>
                            </PrimaryBlackTypoGraphy>
                          ) : (
                            <Typography sx={{ color: "#4F4F4F" }}>
                              ASAP
                            </Typography>
                          )}
                        
                </Stack>

                {el?.requestType === "RFQ" && el?.status !== "PENDING" ? (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    marginTop="1.125rem"
                  >
                    <PrimaryBlackTypoGraphy>Status</PrimaryBlackTypoGraphy>
                    <Box flex="1">
                      {el?.status === "SENT" ? (
                        <RfqSentChip>Sent</RfqSentChip>
                      ) : el?.status === "DRAFT" ? (
                        <RfqDraftChip>draft</RfqDraftChip>
                      ) : el?.status === "COMPLETED" ? (
                        <RfqCompletedChip>Completed</RfqCompletedChip>
                      ) : el?.status === "CANCELLED" ? (
                        <RfqCancelledChip>Cancelled</RfqCancelledChip>
                      ) : (
                        <Typography>{el?.status}</Typography>
                      )}
                    </Box>
                  </Stack>
                ) : null}

{el?.status === "REQUESTED" && el?.isExternal === true  ? (
                            <StyledButton
                              sx={{
                                width: "7.76rem",
                                marginRight: "1rem",
                              }}
                              // onClick={() => handleAssign2(el)}
                              disableRipple
                            >
                              Assign
                              <img
                                style={{ marginLeft: "10%" }}
                                src={`${LocalImages.arrowDown}`}
                                alt="arrowDown"
                              />
                            </StyledButton>
                          ) : el?.status==="REQUESTED" ? (<StyledButton
                            sx={{
                              width: "7.76rem",
                              marginRight: "1rem",
                            }}
                            // onClick={() => handleAssign(el)}
                            disableRipple
                          >
                            Assign
                            <img
                              style={{ marginLeft: "10%" }}
                              src={`${LocalImages.arrowDown}`}
                              alt="arrowDown"
                            />
                          </StyledButton>) : el?.status === "PENDING" ? (
                            <PendingChip>Pending</PendingChip>
                          ) : el?.status === "COMPLETED" ? (
                            <CompletedChip>Completed</CompletedChip>
                          ) :el?.status === "ASSIGNED" ? (
                            <CompletedChip>Assigned</CompletedChip>) : el?.status === "IN_PROGRESS" ? (
                            <RfqSentChip>In Progress</RfqSentChip>
                          ) : (
                            ""
                          )}
              </Box>
            );
          })
        : null}
      {!search && !data?.length ? <NoRequests /> : null}
      {search && !data?.length ? <SearchNotFound /> : null}
    </>
  );
};

export default ViewAllMobile;
