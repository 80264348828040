import { Suspense } from "react";
import { Provider } from "react-redux";
import Store from "./store";
import RouterContainer from "./router/routerContainer";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import "./App.css";
import Notification from "./components/Notifications";

function App() {
  return (
    <div className="App">
      <Provider store={Store}>
        <Suspense fallback={""}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <RouterContainer />
          </ThemeProvider>
        </Suspense>
      </Provider>
    </div>
  );
}

export default App;
