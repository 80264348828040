import { memo, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Tooltip,
  tooltipClasses,
  styled,
  TooltipProps,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory, useParams } from "react-router-dom";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useStyles, useStylesModalResponsive } from "./buildQuoteModalCSS";
import ModalWrapper from "../../../components/modal/modalWrapper";
import LocalImages from "../../../Utils/localImages";
import { theme } from "../../../theme/theme";
import Utils from "../../../Utils";
import SPOwnMobile from "../../SpOwn/SpCreate/SpOwnMobile";

type BuildQuoteModalProps = {
  open: boolean;
  handleClose: () => void;
  heading: string;
  subHeading: string;
  id: string;
  setSpOwnModalOpen: any;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333",
  },
}));

function BuildQuoteModal({
  open,
  handleClose,
  heading,
  subHeading,
  setSpOwnModalOpen,
}: BuildQuoteModalProps) {
  const [mobileSpOwnOpen, setMobileSpOwnOpen] = useState(false);

  const classes = useStyles();
  const classesMobile = useStylesModalResponsive();
  const history = useHistory();
  const themeBreak = useTheme();
  const mobileView = useMediaQuery(themeBreak.breakpoints.down("md"));
  const { id } = useParams<{ id: string }>();

  const handleTemplateClick = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: { id: id, quoteSelection: "TEMPLATE" },
    });
  };

  const handleScratchClick = () => {
    if(localStorage.getItem("quoteSelection")==="TEMPLATE" || localStorage.getItem("quoteSelection")==="UPLOAD"  ){
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
        state: { id: id, quoteSelection: "TEMPLATE" },
      });
    }
    else{
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_SCRATCH + `/${id}`,
        state: { id: id, quoteSelection: "SCRATCH" },
      });
    }
    
  };

  const handleUploadClick = () => {
    handleClose();
    setSpOwnModalOpen(true);
    // history.push({
    //   pathname: Utils.Pathname.UPLOAD_OWN_QUOTE_TEMPLATE,
    //   state: { id: id, quoteSelection: "UPLOAD_OWN" },
    // });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {!mobileView ? (
          <ModalWrapper
            open={open}
            handleClose={handleClose}
            sx={{
              width: { xs: 300, sm: 450, md: 850 },
              borderRadius: (theme) => theme.spacing(0),
            }}
          >
            <Box className={classes.modalWrapperChild}>
              <Box onClick={handleClose} className={classes.handleCloseButton}>
                <ClearIcon />
              </Box>
              <Box sx={{ marginTop: 10 }}>
                <Typography
                  sx={{
                    color: "var(--modalHeadingColor)",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {heading}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--modalSubHeadingColor)",
                    fontFamily: "Inter",
                    fontSize: "21px",
                    fontWeight: 600,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {subHeading}
                </Typography>
              </Box>

              <Box className={classes.templateHeight}>
                <Box className={classes.gridMuiroot}>
                  <Grid container spacing={1}>
                    <Grid
                      sx={{
                        boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
                        "&:hover": {
                          transform: "scale(1.02) perspective(0px)",
                          boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
                        },
                      }}
                      item
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <Box
                        className={classes.templateClick}
                        onClick={handleTemplateClick}
                      >
                        <img
                          src={LocalImages.TemplateIcon}
                          height="70px"
                          width="60.45px"
                          alt=" img is not found"
                        />
                      </Box>
                      <Typography
                        sx={{
                          my: 3,
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 600,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Select a template
                      </Typography>
                      <Typography
                        sx={{
                          m: 3,
                          width: "75%",
                          textAlign: "center",
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Start from a simple quote template to list items.
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
                        "&:hover": {
                          transform: "scale(1.02) perspective(0px)",
                          boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
                        },
                      }}
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <Box
                        onClick={handleScratchClick}
                        className={classes.templateClick}
                      >
                        <img
                          src={LocalImages.ScratchIcon}
                          height="70px"
                          width="60.45px"
                          alt=" img is not found"
                        />
                      </Box>
                      <Typography
                        sx={{
                          my: 3,
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 600,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Start from scratch
                      </Typography>
                      <Typography
                        sx={{
                          m: 3,
                          width: "75%",
                          textAlign: "center",
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Choose the way you build out your quote.
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
                        "&:hover": {
                          transform: "scale(1.02) perspective(0px)",
                          boxShadow: "0 10px 6px var(--modalBoxShadowColor)",
                        },
                      }}
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <Box
                        className={classes.templateClick}
                        onClick={handleUploadClick}
                      >
                        <img
                          src={LocalImages.UploadIcon}
                          height="70px"
                          width="60.45px"
                          alt=" img is not found"
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            my: 3,
                            color: "var(--modalSubHeadingColor)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Upload your own
                        </Typography>
                        <Typography
                          sx={{
                            m: 3,
                            width: "83%",
                            textAlign: "center",
                            color: "var(--modalSubHeadingColor)",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Drag/drop your own document to send to the client.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </ModalWrapper>
        ) : (
          <ModalWrapper
            open={open}
            handleClose={handleClose}
            // sx={{
            //   width: "369px",
            //   borderRadius: (theme) => theme.spacing(0),
            // }}
          >
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
              }}
            >
              <Box
                sx={{
                  background: "#e5e5e5",
                  overflow: "auto",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    top: 15,
                    right: 20,
                  }}
                  // className={classesMobile.handleCloseButton}
                >
                  <ClearIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "4rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--modalHeadingColor)",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {heading}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "12px",
                      color: "var(--modalSubHeadingColor)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                  >
                    {subHeading}
                  </Typography>
                </Box>

                <Stack
                  sx={{
                    paddingTop: "4rem",
                    paddingX: "1.5rem",
                    "& > div": {
                      marginTop: "1rem",
                    },
                  }}
                  direction="column"
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      background: "#fff",
                      paddingX: ".6rem",
                    }}
                    onClick={handleTemplateClick}
                  >
                    <Box
                      sx={{
                        marginRight: "1rem",
                      }}
                    >
                      <img
                        src={LocalImages.TemplateIcon}
                        height="36px"
                        width="42px"
                        alt=" img not found"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "var(--outlineColor)",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Select a template
                      </Typography>
                      <Typography
                        sx={{
                          mt: "8px",
                          textAlign: "inherit",
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          display: "flex",
                        }}
                      >
                        Start from a simple quote template to list items.
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      background: "#fff",
                      paddingX: ".6rem",
                    }}
                    // onClick={handleTemplateClick}
                  >
                    <Box
                      sx={{
                        marginRight: "1rem",
                      }}
                    >
                      <img
                        src={LocalImages.ScratchIcon}
                        height="36px"
                        width="42px"
                        alt=" img not found"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "var(--outlineColor)",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Start from scratch
                      </Typography>
                      <Typography
                        sx={{
                          mt: "8px",
                          textAlign: "inherit",
                          color: "var(--modalSubHeadingColor)",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          display: "flex",
                        }}
                      >
                        Choose the way you build out your quote.
                      </Typography>
                    </Box>
                  </Box>

                  <StyledTooltip
                    open={mobileSpOwnOpen}
                    title={<SPOwnMobile />}
                    placement="bottom"
                  >
                    <Box
                      onClick={() => {
                        setMobileSpOwnOpen(!mobileSpOwnOpen);
                      }}
                      sx={{
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        background: "#fff",
                        paddingX: ".6rem",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "1rem",
                        }}
                      >
                        <img
                          src={LocalImages.UploadIcon}
                          height="36px"
                          width="42px"
                          alt=" img not found"
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "var(--outlineColor)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Upload your own
                        </Typography>
                        <Typography
                          sx={{
                            mt: "8px",
                            textAlign: "inherit",
                            color: "var(--modalSubHeadingColor)",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                            display: "flex",
                          }}
                        >
                          Drag/drop your own document to send to the client.
                        </Typography>
                      </Box>
                    </Box>
                  </StyledTooltip>
                </Stack>
              </Box>
            </Box>
          </ModalWrapper>
        )}
      </ThemeProvider>
    </>
  );
}

export default memo(BuildQuoteModal);

// import { memo } from "react";
// import ClearIcon from "@mui/icons-material/Clear";
// import { Typography, Box, Grid } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useHistory, useParams } from "react-router-dom";
// import { useTheme, ThemeProvider } from "@mui/material/styles";
// import { useStyles, useStylesModalResponsive } from "./buildQuoteModalCSS";
// import ModalWrapper from "../../../components/modal/modalWrapper";
// import LocalImages from "../../../Utils/localImages";
// import { theme } from "../../../theme/theme";
// import Utils from "../../../Utils";

// type BuildQuoteModalProps = {
//   open: boolean;
//   handleClose: () => void;
//   heading: string;
//   subHeading: string;
//   id: string;
// };

// function BuildQuoteModal({
//   open,
//   handleClose,
//   heading,
//   subHeading,
// }: BuildQuoteModalProps) {
//   const classes = useStyles();
//   const classesMobile = useStylesModalResponsive();
//   const history = useHistory();
//   const themeBreak = useTheme();
//   const mobileView = useMediaQuery(themeBreak.breakpoints.down("md"));
//   let { id } = useParams<{ id: string }>();

//   const handleTemplateClick = () => {
//     history.push({
//       pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
//       state: { id: id, quoteSelection: "TEMPLATE" },
//     });
//   };

//   const handleUploadClick = () => {
//     history.push({
//       pathname: Utils.Pathname.UPLOAD_OWN_QUOTE_TEMPLATE,
//       state: { id: id, quoteSelection: "UPLOAD_OWN" },
//     });
//   };

//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         {!mobileView ? (
//           <ModalWrapper
//             open={open}
//             handleClose={handleClose}
//             sx={{
//               width: { xs: 300, sm: 450, md: 850 },
//               borderRadius: (theme) => theme.spacing(0),
//             }}
//           >
//             <Box className={classes.modalWrapperChild}>
//               <Box onClick={handleClose} className={classes.handleCloseButton}>
//                 <ClearIcon />
//               </Box>
//               <Box sx={{ marginTop: 10 }}>
//                 <Typography
//                   sx={{
//                     color: "var(--modalHeadingColor)",
//                     fontFamily: "Inter",
//                     fontSize: "14px",
//                     fontWeight: 500,
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     flexWrap: "wrap",
//                   }}
//                 >
//                   {heading}
//                 </Typography>
//                 <Typography
//                   sx={{
//                     color: "var(--modalSubHeadingColor)",
//                     fontFamily: "Inter",
//                     fontSize: "21px",
//                     fontWeight: 600,
//                     display: "flex",
//                     flexWrap: "wrap",
//                   }}
//                 >
//                   {subHeading}
//                 </Typography>
//               </Box>

//               <Box className={classes.templateHeight}>
//                 <Box className={classes.gridMuiroot}>
//                   <Grid container spacing={1}>
//                     <Grid
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       item
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box
//                         className={classes.templateClick}
//                         onClick={handleTemplateClick}
//                       >
//                         <img
//                           src={LocalImages.TemplateIcon}
//                           height="70px"
//                           width="60.45px"
//                           alt=" img is not found"
//                         />
//                       </Box>
//                       <Typography
//                         sx={{
//                           my: 3,
//                           color: "var(--modalSubHeadingColor)",
//                           fontFamily: "Inter",
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           justifyContent: "center",
//                           alignItems: "center",
//                           display: "flex",
//                         }}
//                       >
//                         Select a template
//                       </Typography>
//                       <Typography
//                         sx={{
//                           m: 3,
//                           width: "75%",
//                           textAlign: "center",
//                           color: "var(--modalSubHeadingColor)",
//                           fontFamily: "Inter",
//                           fontSize: "12px",
//                           fontWeight: 400,
//                           justifyContent: "center",
//                           alignItems: "center",
//                           display: "flex",
//                         }}
//                       >
//                         Start from a simple quote template to list items.
//                       </Typography>
//                     </Grid>

//                     <Grid
//                       item
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box className={classes.templateClick}>
//                         <img
//                           src={LocalImages.ScratchIcon}
//                           height="70px"
//                           width="60.45px"
//                           alt=" img is not found"
//                         />
//                       </Box>
//                       <Typography
//                         sx={{
//                           my: 3,
//                           color: "var(--modalSubHeadingColor)",
//                           fontFamily: "Inter",
//                           fontSize: "16px",
//                           fontWeight: 600,
//                           justifyContent: "center",
//                           alignItems: "center",
//                           display: "flex",
//                         }}
//                       >
//                         Start from scratch
//                       </Typography>
//                       <Typography
//                         sx={{
//                           m: 3,
//                           width: "75%",
//                           textAlign: "center",
//                           color: "var(--modalSubHeadingColor)",
//                           fontFamily: "Inter",
//                           fontSize: "12px",
//                           fontWeight: 400,
//                           justifyContent: "center",
//                           alignItems: "center",
//                           display: "flex",
//                         }}
//                       >
//                         Choose the way you build out your quote.
//                       </Typography>
//                     </Grid>

//                     <Grid
//                       item
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 6px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box
//                         className={classes.templateClick}
//                         onClick={handleUploadClick}
//                       >
//                         <img
//                           src={LocalImages.UploadIcon}
//                           height="70px"
//                           width="60.45px"
//                           alt=" img is not found"
//                         />
//                       </Box>
//                       <Box>
//                         <Typography
//                           sx={{
//                             my: 3,
//                             color: "var(--modalSubHeadingColor)",
//                             fontFamily: "Inter",
//                             fontSize: "16px",
//                             fontWeight: 600,
//                             justifyContent: "center",
//                             alignItems: "center",
//                             display: "flex",
//                           }}
//                         >
//                           Upload your own
//                         </Typography>
//                         <Typography
//                           sx={{
//                             m: 3,
//                             width: "83%",
//                             textAlign: "center",
//                             color: "var(--modalSubHeadingColor)",
//                             fontFamily: "Inter",
//                             fontSize: "12px",
//                             fontWeight: 400,
//                             justifyContent: "center",
//                             alignItems: "center",
//                             display: "flex",
//                           }}
//                         >
//                           Drag/drop your own document to send to the client.
//                         </Typography>
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               </Box>
//             </Box>
//           </ModalWrapper>
//         ) : (
//           <ModalWrapper
//             open={open}
//             handleClose={handleClose}
//             sx={{
//               width: "369px",
//               borderRadius: (theme) => theme.spacing(0),
//             }}
//           >
//             <Box className={classesMobile.modalWrapperChild}>
//               <Box
//                 onClick={handleClose}
//                 className={classesMobile.handleCloseButton}
//               >
//                 <ClearIcon />
//               </Box>
//               <Box sx={{ marginTop: 10 }}>
//                 <Typography
//                   sx={{
//                     color: "var(--modalHeadingColor)",
//                     fontFamily: "Inter",
//                     fontSize: "14px",
//                     fontWeight: 500,
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     flexWrap: "wrap",
//                   }}
//                 >
//                   {heading}
//                 </Typography>
//                 <Typography
//                   sx={{
//                     color: "var(--modalSubHeadingColor)",
//                     fontFamily: "Inter",
//                     fontSize: "21px",
//                     fontWeight: 600,
//                     display: "flex",
//                     flexWrap: "wrap",
//                   }}
//                 >
//                   {subHeading}
//                 </Typography>
//               </Box>

//               <Box className={classesMobile.templateHeight}>
//                 <Box className={classesMobile.gridMuiroot}>
//                   <Grid container spacing={1}>
//                     <Grid
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       item
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box sx={{ display: "flex" }}>
//                         <Box
//                           className={classesMobile.templateClick}
//                           onClick={handleTemplateClick}
//                         >
//                           <img
//                             src={LocalImages.TemplateIcon}
//                             height="50px"
//                             width="75px"
//                             alt=" img is not found"
//                           />
//                         </Box>
//                         <Box sx={{ display: "block", ml: 2 }}>
//                           <Typography
//                             sx={{
//                               mt: 3,
//                               pt: 1,
//                               color: "var(--outlineColor)",
//                               fontFamily: "Inter",
//                               fontSize: "16px",
//                               fontWeight: 500,
//                             }}
//                           >
//                             Select a template
//                           </Typography>
//                           <Typography
//                             sx={{
//                               mt: 2,
//                               mb: 3,
//                               textAlign: "inherit",
//                               color: "var(--modalSubHeadingColor)",
//                               fontFamily: "Inter",
//                               fontSize: "12px",
//                               fontWeight: 400,
//                               display: "flex",
//                             }}
//                           >
//                             Start from a simple quote template to list items.
//                           </Typography>
//                         </Box>
//                       </Box>
//                     </Grid>

//                     <Grid
//                       item
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 10px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box sx={{ display: "flex" }}>
//                         <Box
//                           className={classesMobile.templateClick}
//                           onClick={handleTemplateClick}
//                         >
//                           <img
//                             src={LocalImages.ScratchIcon}
//                             height="54px"
//                             width="75px"
//                             alt=" img is not found"
//                           />
//                         </Box>
//                         <Box sx={{ display: "block", ml: 2 }}>
//                           <Typography
//                             sx={{
//                               mt: 3,
//                               pt: 1,
//                               color: "var(--outlineColor)",
//                               fontFamily: "Inter",
//                               fontSize: "16px",
//                               fontWeight: 500,
//                             }}
//                           >
//                             Start from scratch
//                           </Typography>
//                           <Typography
//                             sx={{
//                               mt: 2,
//                               mb: 3,
//                               textAlign: "inherit",
//                               color: "var(--modalSubHeadingColor)",
//                               fontFamily: "Inter",
//                               fontSize: "12px",
//                               fontWeight: 400,
//                               display: "flex",
//                             }}
//                           >
//                             Choose the way you build out your quote.
//                           </Typography>
//                         </Box>
//                       </Box>
//                     </Grid>

//                     <Grid
//                       item
//                       sx={{
//                         boxShadow: "0px 0px 20px rgb(79, 79, 79, 0.08)",
//                         "&:hover": {
//                           transform: "scale(1.02) perspective(0px)",
//                           boxShadow: "0 10px 6px var(--modalBoxShadowColor)",
//                         },
//                       }}
//                       xs={12}
//                       sm={4}
//                       md={4}
//                     >
//                       <Box sx={{ display: "flex" }}>
//                         <Box
//                           className={classesMobile.templateClick}
//                           onClick={handleUploadClick}
//                         >
//                           <img
//                             src={LocalImages.UploadIcon}
//                             height="54px"
//                             width="75px"
//                             alt=" img is not found"
//                           />
//                         </Box>
//                         <Box sx={{ display: "block", ml: 2 }}>
//                           <Typography
//                             sx={{
//                               mt: 3,
//                               pt: 1,
//                               color: "var(--outlineColor)",
//                               fontFamily: "Inter",
//                               fontSize: "16px",
//                               fontWeight: 500,
//                             }}
//                           >
//                             Upload your own
//                           </Typography>
//                           <Typography
//                             sx={{
//                               mt: 2,
//                               mb: 3,
//                               textAlign: "inherit",
//                               color: "var(--modalSubHeadingColor)",
//                               fontFamily: "Inter",
//                               fontSize: "12px",
//                               fontWeight: 400,
//                               display: "flex",
//                             }}
//                           >
//                             Drag/drop your own document to send to the client.
//                           </Typography>
//                         </Box>
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               </Box>
//             </Box>
//           </ModalWrapper>
//         )}
//       </ThemeProvider>
//     </>
//   );
// }

// export default memo(BuildQuoteModal);
