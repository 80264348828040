import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    titleChildren: {
      display: "flex",
      justifyContent: "space-between",
      gap: 3,
      height: "30px",
    },
    titleIcons: {
      backgroundColor: "var(--secondaryWhite)",
      borderRadius: "1px solid var(--modalBoxShadowColor)",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexWrap: "wrap",
    },
    customTable: {
      "& .css-2r9z59-MuiTableCell-root": {
        padding: "0px", // <-- arbitrary value
      },
      "& .tableCellClasses.root": {
        borderBottom: "1px solid var(--modalBoxShadowColor)",
      },
      "& .css-fdm9z9-MuiTableHead-root .MuiTableCell-root": {
        textTransform: "capitalize",
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.059,
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    gridMuirootValue: {
      flexGrow: 1,
      margin: 25,
      marginTop: 120,
    },
    gridMuiroot: {
      flexGrow: 1,
      margin: 25,
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    errors: {
      color: "red",
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      margin: 28,
      flexWrap: "wrap",
    },
    totalCal: {
      display: "flex",
      gap: 40,
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    totalAmount: {
      display: "flex",
      gap: 10,
      justifyContent: "space-between",
      margin: " 16px 0px 16px 16px",
    },
    buttonPositions: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "30px",
      "& > :not(style)": {
        width: "76vw",
        marginTop: "20px",
      },
      "@media (max-width: 600px)": {
        width: "86%",
      },
    },
    buttonAll: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    buttonsaveDraft: {
      display: "flex",
      gap: 20,
      flexWrap: "wrap",
    },
    calculation: {
      display: "block",
      mr: 4,
      minWidth: "30%",
      flexWrap: "wrap",
    },
  })
);

const mobileUseStyles = makeStyles(() =>
  createStyles({
    titleChildren: {
      display: "flex",
      justifyContent: "space-between",
      gap: 18,
      height: "30px",
    },
    titleIcons: {
      backgroundColor: "var(--secondaryWhite)",
      borderRadius: "1px solid var(--modalBoxShadowColor)",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexWrap: "wrap",
    },
    customTable: {
      "& .css-2r9z59-MuiTableCell-root": {
        padding: "0px", // <-- arbitrary value
      },
      "& .tableCellClasses.root": {
        borderBottom: "1px solid var(--modalBoxShadowColor)",
      },
      "& .css-fdm9z9-MuiTableHead-root .MuiTableCell-root": {
        textTransform: "capitalize",
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.059,
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    gridMuirootValue: {
      flexGrow: 1,
      margin: 25,
    },
    gridMuiroot: {
      flexGrow: 1,
      margin: 25,
    },
    textField: {
      backgroundColor: "white",
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    errors: {
      color: "red",
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 20,
      flexWrap: "wrap",
      backgroundColor: "var(--mobileBackgroundColor)",
    },
    totalCal: {
      display: "flex",
      gap: 40,
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    totalAmount: {
      display: "flex",
      gap: 10,
      justifyContent: "space-between",
      margin: " 16px 0px 16px 16px",
    },
    buttonPositions: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "30px",
      "& > :not(style)": {
        width: "67vw",
        "@media (max-width: 600px)": {
          width: "86%",
        },
      },
    },
    buttonAll: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    buttonsaveDraft: {
      display: "flex",
      gap: 20,
      flexWrap: "wrap",
    },
    calculation: {
      display: "block",
      marginRight: "12px",
      marginTop: 30,
      marginBottom: 12,
      minWidth: "30%",
      flexWrap: "wrap",
    },
    modalWrapperChild: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    handleCloseButton: {
      position: "absolute",
      top: 40,
      left: 35,
      cursor: "pointer",
    },
    cssLabel: {
      color: "#E0E0E0",
    },

    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `#E0E0E0 !important`,
      },
    },
    cssFocused: {
      borderColor: "#E0E0E0 !important",
    },

    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#E0E0E0 !important",
    },
  })
);

export { useStyles, mobileUseStyles };
