import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React from "react";
import TextEditor from "../TextEditor";
import CostsTable from "../CostsTable";
import {
  DragContainer,
  AddSecButton,
} from "../../../components/styledComponents";
import {
  getAddSectionButtonProps,
  getDraggableFieldProps,
  getIsDisabledValue,
  onDragEnd,
  setDraggableForms,
  makeDraggableFormFromInitialObj,
  getInitialForms,
} from "./helper";
import Utils from "../../../Utils";
import FilledButton from "../../../components/button/filledButton";
import { useHistory, useParams } from "react-router-dom";
import useRedux from "../../../hooks/useRedux";
import { scratchQuote } from "../actions";
import { makeScratchParameter } from "../helper";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

function DraggableField({ index, form }: any) {
  return (
    <Draggable draggableId={form.id} index={index}>
      {(provided) => {
        if (form.boxType)
          return (
            <CostsTable provided={provided} field={form.field} index={index} />
          );
        return (
          <TextEditor
            provided={provided}
            index={index}
            isNew={form.isNew}
            form={form.field}
          />
        );
      }}
    </Draggable>
  );
}

export default function DraggableWrapper({
  initialObj,
}: {
  [key: string]: any;
}) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { dispatch, reducers } = useRedux();
  const { draggableForms, errorsForms, clientErrors, clientDetails } =
    reducers.scratchTemplateReducer;
  const draggablFormLength = draggableForms?.length;

  console.log({draggableForms});

  React.useEffect(() => {
    if (initialObj.isDraft && draggablFormLength === 0) {
      const errorLength =
        initialObj.itemCosting?.length + initialObj.titleAndDescription?.length;
      dispatch({
        type: SCRATCH_TEMPLATE,
        payload: {
          draggableForms: makeDraggableFormFromInitialObj(initialObj),
          errorsForms: Array(errorLength).fill(true),
        },
      });
    } else if (draggablFormLength === 0) {
      dispatch({
        type: SCRATCH_TEMPLATE,
        payload: {
          draggableForms: getInitialForms(),
          errorsForms: Array(6).fill(false),
        },
      });
    }
  }, [draggablFormLength]);

  const isDisabled: boolean = getIsDisabledValue(errorsForms, clientErrors);

  console.log({draggableForms, errorsForms , clientErrors, clientDetails},"draggable")
  return (
    <DragContainer>
      <DragDropContext
        onDragEnd={(result: any) => {
          setDraggableForms(dispatch, onDragEnd(result, draggableForms));
        }}
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {draggableForms &&
                draggableForms.length > 0 &&
                draggableForms.map((form: any, index: any) => {
                  return (
                    <DraggableField {...getDraggableFieldProps(form, index)} />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddSecButton
        {...getAddSectionButtonProps(dispatch, draggableForms, errorsForms)}
      >
        {"Add New Section +"}
      </AddSecButton>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 24,
        }}
      >
        <div>
          <FilledButton
            width={126}
            height={46}
            background={"var(--secondaryWhite)"}
            color={"var(--oulineColor)"}
            border={"1px solid var(--modalBoxShadowColor)"}
            onClick={() => {
              console.log("VVVV");
              dispatch({
                type: SCRATCH_TEMPLATE,
                payload: {
                  draggableForms: [],
                  errorsForms: Array(6).fill(false),
                  clientErrors: false,
                  clientDetails: null,
                },
              });
              history.push({
                pathname:
                  Utils.Pathname.Dashboard, 
              });
            }}
          >
            {"Cancel"}
          </FilledButton>
        </div>
        <div
          style={{
            width: 300,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FilledButton
            width={147}
            height={46}
            background={"var(--secondaryWhite)"}
            color={"var(--oulineColor)"}
            disabled={!isDisabled}
            border={"1px solid var(--modalBoxShadowColor)"}
            onClick={() => {
              dispatch(
                scratchQuote(
                  makeScratchParameter(id, true, {
                    ...clientDetails,
                    draggableForms,
                    comments: "",
                  })
                )
              );
            }}
          >
            {"Save as draft"}
          </FilledButton>
          <FilledButton
            width={128}
            height={46}
            background={isDisabled ? "var(--primaryBlueColor)" : "#E0E0E0"}
            color={isDisabled ? "var(--white)" :"#000000"}
            border={"none"}
            disabled={!isDisabled}
            onClick={() => {
              dispatch(
                scratchQuote(
                  makeScratchParameter(id, true, {
                    ...clientDetails,
                    draggableForms,
                    comments: "",
                  }),
                  () => {
                    history.push({
                      pathname:
                        Utils.Pathname.BUILD_QUOTE_SCRATCH_PREVIEW + `/${id}`,
                    });
                  }
                )
              );
            }}
          >
            {"Submit"}
          </FilledButton>
        </div>
      </div>
    </DragContainer>
  );
}
