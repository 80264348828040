const StandardRatesReducer = (
  state = {
    standardRates: [],
  },
  action: any
) => {
  switch (action.type) {
    case "GET_STANDARD_RATES":
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

const getStandardRatesState = (state: any) => state.StandardRatesReducer;

export { StandardRatesReducer, getStandardRatesState };
