import ActionName from "../../../../Utils/actionName";

function getStartedStepsSoleTraderReducer(
  state: any = {
    isCompanyProfileComplete: false,
    isCreateRateCard: false,
    isRecevivedFirstReq: false,
    isShow: false,
  },
  action: any
) {
  switch (action.type) {
    case ActionName.GETSTARTED_STEPS_SOLETRADER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

function getStartedStepsCompanyReducer(
  state: any = {
    isCompanyProfileComplete: false,
    isCreateRateCard: false,
    isAddEmployee: false,
    isRecevivedFirstReq: false,
    isShow: false,
  },
  action: any
) {
  switch (action.type) {
    case ActionName.GETSTARTED_STEPS_COMPANY:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

const getStartedStepsSoleTraderState = (state: any) =>
  state?.getStartedStepsSoleTraderReducer;

const getStartedStepsCompanyState = (state: any) =>
  state?.getStartedStepsCompanyReducer;

export {
  getStartedStepsSoleTraderReducer,
  getStartedStepsCompanyReducer,
  getStartedStepsCompanyState,
  getStartedStepsSoleTraderState,
};
