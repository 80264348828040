import { Button, styled } from "@mui/material";

type StyledButtonTypes = {
  padding?: string;
  color?: string;
  background?: string;
  children?: any;
  border?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: any;
  type?: any;
};

export const StyledButton = ({
  children,
  fullWidth,
  disabled,
  onClick = (e: any) => {},
  type = "button",
  ...props
}: StyledButtonTypes) => {
  const StyledButton = styled(Button)({
    "&:hover": {
      ...props,
    },
    borderRadius: "0",
    textTransform: "initial",
    ...props,
  });

  return (
    <StyledButton
      fullWidth={Boolean(fullWidth)}
      onClick={onClick}
      disabled={Boolean(disabled)}
      type={type}
    >
      {children}
    </StyledButton>
  );
};
