import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const UnderDevlopmentInvite = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        background: "#f6f6f6",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <h1>Under Development</h1>
      <Button
        variant="contained"
        onClick={() => {
          history.push("/");
        }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default UnderDevlopmentInvite;
