import React from "react";
import { styled } from "@mui/material";

const StyledFilledButton = styled("button")<StyledFilledButtonProps>(
  ({ _theme, width, height, background, color, border }: any) => ({
    width,
    height,
    background,
    color,
    border,
    borderRadius: 0,
    outline: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  })
);

export default function FilledButton({
  children,
  disabled,
  onClick,
  ...rest 
}: FilledButtonProps & StyledFilledButtonProps) {
  return (
    <StyledFilledButton
      {...rest}
      disabled={disabled}
      onClick={onClick ? onClick : () => {}}
    >
      {children}
    </StyledFilledButton>
  );
}

type StyledFilledButtonProps = {
  width: string | number;
  height: string | number;
  background?: string;
  color?: string;
  border?: string;
};

type FilledButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
};
