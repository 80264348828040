import { Box, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type InputFieldProps = {
  getFieldProps?: any;
  id?: string;
  label: string;
  placeholder: string;
  errors?: any;
  touched?: any;
  disabled?: boolean;
  onChange?: any;
  inputProps?: any;
  value?: any;
  minDate?: any;
  maxDate?: any;
};

const CustomDatePicker = ({
  getFieldProps,
  id,
  label,
  placeholder,
  errors,
  touched,
  disabled,
  inputProps,
  value,
  onChange,
  ...rest
}: InputFieldProps) => {
  console.log(value,label,"Strt")
  return (
    <Box>
      <InputLabel
        sx={{
          color: "var(--clientColor)",
        }}
        htmlFor={id}
      >
        {label}
      </InputLabel>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          value={value || new Date()}
          onChange={onChange}
          disabled={Boolean(disabled)}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                marginTop: ".7rem",
                width: "150px",
                "& input": {
                  padding: "15px 3px",
                  background: "#fff",
                  "&:disabled": {
                    background: "#fff",
                  },
                },
                "& fieldset": {
                  borderRadius: 0,
                  border: "1px solid #bdbdbd !important",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default React.memo(CustomDatePicker);
