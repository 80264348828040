import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import RootReducer from "../reducer";
import { createLogger } from 'redux-logger';

const logger = createLogger();


// const enhancer = composeWithDevTools(applyMiddleware(thunk,logger));
const enhancer = composeWithDevTools(applyMiddleware(thunk));

const store: any = createStore(RootReducer, enhancer);

export default store;
