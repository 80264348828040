// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyA5Wfs2bobH13NYocI2212nHGvp5vczW_c",
  // authDomain: "infinitetrade-2c9e6.firebaseapp.com",
  // projectId: "infinitetrade-2c9e6",
  // storageBucket: "infinitetrade-2c9e6.appspot.com",
  // messagingSenderId: "536749581898",
  // appId: "1:536749581898:web:17af8d64461d1360f328b3",
  // measurementId: "G-XLYP76ZRDN",
  apiKey: "AIzaSyB3LvVZu4ROBd4YLfemx0ZaI-nuZE0fKTw",
  authDomain: "infinte-trades.firebaseapp.com",
  projectId: "infinte-trades",
  storageBucket: "infinte-trades.appspot.com",
  messagingSenderId: "557288765652",
  appId: "1:557288765652:web:2cb09e73ce5f0100de2341",
  measurementId: "G-ENJ3LL2938"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      // "BBhbRLbwbVEKR9X3ImDlazhMfAG4OXjbzmXgmG_xT7LIj2hHK6JwJJwIGY3yH5lTKHzZ3p_k9FJtjniSmoOK6zg",
      "BCVQJwxXv-LyIETC_kzPY06ee8MGtEcdPu4CvEbZVCbX6fWop55HlyG0B8ciYjZj-EGzxe7inkDoyGjc2-GWgag",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("fb", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const makeToken = () => {
  return new Promise((res, rej) => {
    getToken(messaging, {
      vapidKey:
      "BCVQJwxXv-LyIETC_kzPY06ee8MGtEcdPu4CvEbZVCbX6fWop55HlyG0B8ciYjZj-EGzxe7inkDoyGjc2-GWgag",
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken);
          localStorage.setItem("fb", currentToken);
          res(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          rej("deviceToken");
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
        rej("deviceToken");
      });
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
