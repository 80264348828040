import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledButton } from "../../../../../components/styledComponents/StyledButton";

export default function Filter({
  open,
  setOpen,
  CheckBoxData,
  data,
  setData,
}: any) {
  const handleClose = () => {
    setOpen(false);
  };

  const [values, setValues] = React.useState(data);

  const handleChange = (e: any) => {
    if (!e.target.checked) {
      setValues(values?.filter((el: any) => el !== e?.target?.name));
    } else {
      setValues([...values, e.target.name]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            background: "#fff",
          }}
        >
          <Stack
            padding={"20px 24px"}
            direction="row"
            justifyContent="space-between"
          >
            <Typography>Filter</Typography>
            <CloseIcon onClick={handleClose} />
          </Stack>
          <Divider />
          <Stack justifyContent={"space-between"} height="87vh">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1} direction="column">
                  {CheckBoxData?.length
                    ? CheckBoxData?.map((el: any, i: number) => {
                        return (
                          <FormControlLabel
                            key={el}
                            sx={!i ? { marginLeft: 0 } : {}}
                            control={
                              <Checkbox
                                checked={values?.includes(el)}
                                onChange={handleChange}
                                name={el}
                              />
                            }
                            label={el}
                          />
                        );
                      })
                    : null}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Stack
              direction="row"
              justifyContent={"center"}
              spacing={2}
              padding={"1.5rem"}
            >
              <StyledButton
                border="1px solid #e0e0e0"
                color="#4f4f4f"
                padding="15px 50px"
                onClick={() => {
                  setData([]);
                  setOpen(false);
                }}
                fullWidth
              >
                Cancel
              </StyledButton>
              <StyledButton
                background="#0da4ce"
                color="#fff"
                padding="15px 50px"
                onClick={() => {
                  setData(values);
                  handleClose();
                }}
                fullWidth
              >
                Apply
              </StyledButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
