import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Paper,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// @ts-ignore
import { Waypoint } from "react-waypoint";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Title from "../Title";
import Utils from "../../../Utils";
import { useStyles } from "./spPreviewCss";
import { theme } from "../../../theme/theme";
import ModalPreviewPDF from "./modalPreview";
import { ReducersModel } from "../../../model";
import { companyJobDetails } from "../spBuildsQuotes/action";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ButtonOutlined from "../../../components/button/buttonOutlined";
import ButtonContained from "../../../components/button/buttonContained";
import ButtonOutlinedBlack from "../../../components/button/buttonOutlinedBlack";

interface LocationState {
  pathname: string;
  id: string;
  quoteSelection: string;
  status: boolean;
}

const SpPreviews = () => {
  const classes = useStyles();
  const history = useHistory();
  const themeBreak = useTheme();
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(100);
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation<LocationState>();
  const [openSuccess, setOpenSuccess] = useState(false);
  const mobileView = useMediaQuery(themeBreak.breakpoints.down("sm"));
  const [scale, setScale] = useState(3);

  const handleCloseSuccess = useCallback(() => {
    setOpenSuccess(false);
  }, []);

  useEffect(() => {
    dispatch(companyJobDetails(location.state.id));
  }, [dispatch, location.state.id]);

  const { id } = useSelector(
    (state: ReducersModel) => state.selectTemplateModelReducer
  );

  const { JobDetailsData } = useSelector(
    (state: ReducersModel) => state.jobDetailsModelReducer
  );

  const SPBUILD_QUOTE_TEMPLATE = [
    {
      name: "Quotes",
      link: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
    },
    {
      name: JobDetailsData?.map((item: any) => item.quoteNumber),
    },
  ];

  const handleEditClick = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: {
        id: location.state.id,
        quoteSelection: location.state.quoteSelection,
      },
    });
  };

  const handleCancel = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: { id: location.state.id },
    });
  };

  const handleModalOpen = () => {
    if (mobileView) {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_FINAL_SUBMIT,
        state: { id: location.state.id },
      });
    } else {
      setOpenSuccess(true);
    }
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Title>
          <Box className={classes.breadcrumb}>
            <Breadcrumb metaData={SPBUILD_QUOTE_TEMPLATE} rfqId={id} />
          </Box>
        </Title>

        <TransformWrapper
          initialScale={1}
          initialPositionX={33}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut }: any) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center", 
                width: "76vw",
                ml: "11.7%",
                bgcolor: "#828282",
                "& > :not(style)": {
                  width: "76vw",
                  // marginBottom: 2,
                },
              }}
            >
              <Box className={classes.previewRow}>
                <Box className={classes.previewItem}>
                  <Typography variant="h5" color="info.contrastText">
                    Preview{" "}
                  </Typography>
                </Box>

                <Box className={classes.zoomContainer}>
                  <Typography sx={{ color: "#ffffff", fontSize: "16px" }}>
                    {pageNumber} / {pages}
                  </Typography>
                  <Box
                    sx={{
                      width: "1px",
                      height: "20px",
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <Typography
                    variant="h5"
                    color="info.contrastText"
                    onClick={() => {
                      if (zoom > 100) {
                        zoomOut();
                        setZoom(zoom - 25);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    variant="h5"
                    color="info.contrastText"
                    style={{
                      // border: "1px solid black",
                      padding: "2px 6px",
                      background: "rgba(0,0,0,0.2)",
                      fontSize: 16,
                    }}
                  >
                    {zoom}%
                  </Typography>
                  <Typography
                    variant="h5"
                    color="info.contrastText"
                    onClick={() => {
                      if (zoom < 225) {
                        zoomIn();
                        setZoom(zoom + 25);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    +
                  </Typography>
                </Box>
                {JobDetailsData?.map((item: any, index: any) => {
                  return (
                    <Box className={classes.buttonsTogether} key={index}>
                      {location.state.status ? null : (
                        <ButtonOutlinedBlack
                          icon={true}
                          editValue={true}
                          downloadValue={false}
                          onClick={handleEditClick}
                        >
                          Edit
                        </ButtonOutlinedBlack>
                      )}
                      {item?.downloadUrl ? (
                        <ButtonOutlinedBlack
                          icon={true}
                          editValue={true}
                          downloadValue={true}
                        >
                          (
                          <Link
                            href={item?.downloadUrl}
                            underline="none"
                            color="secondary.contrastText"
                          >
                            Download
                          </Link>
                          )
                        </ButtonOutlinedBlack>
                      ) : (
                        <ButtonOutlinedBlack
                          icon={true}
                          editValue={true}
                          downloadValue={true}
                        >
                          Download
                        </ButtonOutlinedBlack>
                      )}
                    </Box>
                  );
                })}
              </Box>

              <TransformComponent>
                <Box className={classes.root}>
                  <Paper elevation={0}>
                    {JobDetailsData?.map((item: any, index: number) => {
                      console.log(item?.downloadUrl, "url");
                      return (
                        <Box>
                          <Document
                            file={item?.downloadUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={(error:any) =>
                              console.log("Inside Error", error)
                            }
                            loading={
                              <Stack
                                height="50vh"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <CircularProgress color="inherit" />
                              </Stack>
                            }
                          >
                            {Array.apply(null, Array(pages))
                              .map((x, i) => i + 1)
                              .map((page, i) => (
                                <Waypoint
                                  topOffset="15%"
                                  // bottomOffset="49%"
                                  onEnter={() => {
                                    setPageNumber(i + 1);
                                  }}
                                  key={i}
                                >
                                  <Box marginTop=".7rem">
                                    <Page
                                      pageNumber={page}
                                      scale={scale}
                                      loading={""}
                                    />
                                  </Box>
                                </Waypoint>
                              ))}
                          </Document>
                        </Box>
                      );
                    })}
                  </Paper>
                </Box>
              </TransformComponent>
            </Box>
           )}
           </TransformWrapper> 
   

        <Box className={classes.buttonPositions}>
          {location.state.status ? (
            <Box className={classes.buttonAll}>
              <ButtonOutlined
                icon={false}
                editValue={false}
                onClick={handleCancel}
                disabled={true}
              >
                Cancel
              </ButtonOutlined>
              <Box className={classes.buttonsaveDraft}>
                <ButtonOutlined icon={false} editValue={false} disabled={true}>
                  Save as Draft
                </ButtonOutlined>

                <ButtonContained
                  icon={false}
                  onClick={handleModalOpen}
                  disabled={true}
                >
                  Submit
                </ButtonContained>
              </Box>
            </Box>
          ) : (
            <Box className={classes.buttonAll}>
              <ButtonOutlined
                icon={false}
                editValue={false}
                onClick={handleEditClick}
              >
                Cancel
              </ButtonOutlined>
              <Box className={classes.buttonsaveDraft}>
                <ButtonOutlined icon={false} editValue={false}>
                  Save as Draft
                </ButtonOutlined>

                <ButtonContained icon={false} onClick={handleModalOpen}>
                  Submit
                </ButtonContained>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
      <ModalPreviewPDF
        open={openSuccess}
        handleClose={handleCloseSuccess}
        heading="Submit Quote"
      />
    </>
  );
};

export default SpPreviews;
