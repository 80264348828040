
import { useStyles } from '../../selectTemplateCss';

function TextError(props:any) {
    const classes = useStyles();
    return (
        <div className={classes.errors}>
            {props.children}
        </div>
    )
}

export default TextError
