import Modal from "@mui/material/Modal";
import React from "react";

interface Props {
  isOpen: boolean;
  handleClose:
    | ((event: {}, reason: "disableBackdropClick" | "escapeKeyDown") => void)
    | undefined;
  modalStyle: string;
  children: JSX.Element;
}
const ModalBox: React.FC<Props> = ({
  isOpen,
  handleClose,
  modalStyle,
  children,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={(reason) => {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
        }
    }}
      className={modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {children}
    </Modal>
  );
};
export default ModalBox;
