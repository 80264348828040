import React from "react";
import { Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddNewLineButton = styled(Button)<AddNewLineButtonProps>(
  ({ _theme, $width }: any) => ({
    background: "#FAFAFA",
    // height: $height,
    width: $width,
    border: "1px solid #e0e0e0",
    borderRadius: 0,
    marginTop: 18,
    color: "#0DA4CE",
  })
);

export default function NewCostButton({
  children,
  onClick,
}: NewCostButtonProps & AddNewLineButtonProps) {
  return (
    <AddNewLineButton
      startIcon={<AddIcon />}
      variant={"outlined"}
      onClick={onClick}
    >
      {children}
    </AddNewLineButton>
  );
}

type NewCostButtonProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

type AddNewLineButtonProps = {
  $width?: string | number;
  $height?: string | number;
};
