const BuildQuoteOwnReducer = (
  state = {
    companyId: "",
    jobId: "",
    quoteSelection: "UPLOAD",
    isDraft: false,
    totalCost: 0,
    downloadUrl: "",
    client: "",
  },
  action: any
) => {
  switch (action?.type) {
    case "BQ_OWN_UPLOAD_DETAIL": 
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return state;
  }
};

export default BuildQuoteOwnReducer;

export const getBuildQuoteOwnState = (state: any) =>
  state?.BuildQuoteOwnReducer;
