import React from "react";
import { styled } from "@mui/system";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import LocalImages from "../../../../Utils/localImages";

const { messagephoto } = LocalImages;

const StyledContainer = styled(Box)({
  backgound: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40vh",
});

const StyledTypography = styled(Typography)({
  color: "#4f4f4f",
  fontSize: ".8rem",
  fontWeight: 400,
});

const NoRequests = () => {
  return (
    <StyledContainer>
      <Stack direction="column" alignItems="center">
        <Box
          width="3rem"
          height="3rem"
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <img src={messagephoto} alt="message" />
        </Box>

        <StyledTypography marginTop={"1rem"}>
          No request to review.
        </StyledTypography>
      </Stack>
    </StyledContainer>
  );
};

export default NoRequests;
