// @ts-ignore
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import React, { useState } from "react";
// @ts-ignore
import { Waypoint } from "react-waypoint";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  styled,
  Link,
} from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#333",
  "&:hover": {
    background: "#333",
  },
  color: "#fff",
  borderRadius: "0",
  textTransform: "initial",
  paddingY: "9px",
}));

const FinalSubmitPreview = ({ link, handleEdit }: any) => {
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPages(numPages);
    setPageNumber(1);
  }

  if (!link) {
    return <></>;
  }

  return (
    <Stack direction="row" justifyContent={"center"}>
      <Document
        file={link}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error: any) => console.log("Inside Error", error)}
        loading={
          <Stack height="50vh" justifyContent={"center"} alignItems="center">
            <CircularProgress color="inherit" />
          </Stack>
        }
      >
        <Page pageNumber={1} loading={""} scale={0.5} />
      </Document>
    </Stack>
  );
};
export default FinalSubmitPreview;
