import { useHistory, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import CommonFunction, { getFileType } from "../../../../Utils/commonFunctions";
import BuildButton from "../../../../components/button/buttonBuild";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useStylesBreakPoints } from "../spBuildsQuotesCss";
import LocalImages from "../../../../Utils/localImages";
import BuildQuoteModal from "../modalBuildQuote";
import { theme } from "../../../../theme/theme";
// import PaperBody from "../../PaperBody";
import PreciseRating from "../rating";
import Utils from "../../../../Utils";
import EllipsisToolTip from "ellipsis-tooltip-react-chan";
import SpOwnModal from "../../../SpOwn/SpCreate/SpOwnModal";

type AWSURL = string;

const awsUrl: AWSURL = "https://app-development.s3.amazonaws.com/";

const MobileResponsive = ({ data }: any) => {
  const breakpoint = useStylesBreakPoints();

  let { id } = useParams<{ id: string }>();

  const history = useHistory();

  const isDraftRoute = (item: any) => {
    if (item?.quoteSelection === "UPLOAD") {
      history.push(Utils.Pathname.buildQuoteOwnPreview, {
        jobId: item?._id,
        quoteSelection: "UPLOAD",
      });
    }

    if (item?.quoteSelection === "TEMPLATE") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
        state: { id: item?._id, quoteSelection: "TEMPLATE" },
      });
    }
  };

  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [spOwnModalOpen, setSpOwnModalOpen] = useState<boolean>(false);

  const handleCloseSuccess = useCallback(() => {
    setOpenSuccess(false);
  }, []);

  const JobDetailsData = data;

  const setImageIconOrUrl = (url: string): string => {
    const API_URL = Utils.Constants.API_URL;
    if (getFileType(url) === "doc") {
      return LocalImages.DocIcon;
    }
    if (getFileType(url) === "pdf") {
      return LocalImages.PdfIcon;
    }
    if (getFileType(url) === "xlsx") {
      return LocalImages.ExcelIcon;
    }
    if (getFileType(url) === "jpeg" || getFileType(url) === "jpg") {
      return LocalImages.JpegIcon;
    }
    if (getFileType(url) === "png") {
      return LocalImages.PngIcon;
    }
    return API_URL + url; //by default if you have to set
  };

  const convertToKB = (num: any) => {
    if (typeof num === "number") {
      return `${parseFloat(`${num / 1024}`).toFixed(2)} KB`;
    } else {
      return "0 KB";
    }
  };

  const navigateToBQPreview = (item: any) => {
    if (item?.quoteSelection === "UPLOAD") {
      history.push(Utils.Pathname.buildQuoteOwnPreview, {
        jobId: item?._id || "",
        quoteSelection: "UPLOAD",
      });
    }
    if (item?.quoteSelection === "TEMPLATE") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
        state: {
          id: item?._id,
          quoteSelection: "TEMPLATE",
          status: true,
        },
      });
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {JobDetailsData.length > 0 &&
          JobDetailsData?.map((item: any, idx: number) => {
            return (
              <Box key={idx}>
                <Box key={idx}>
                  <>
                    <Box className={breakpoint.leftdescription}>
                      <Box sx={{ padding: "20px 24px" }}>
                        <Box
                          sx={{
                            padding: "0px 0px 8px 0px ",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h5" color="text.secondary">
                            Request Title
                          </Typography>
                          <Box>
                            <img src={LocalImages.dotIcon} alt="dotIcon" />
                          </Box>
                        </Box>

                        <Typography
                          sx={{
                            marginTop: "12px",
                            lineHeight:"15x"
                          }}
                          variant="h6"
                          color="text.secondary"
                        >
                          {CommonFunction.capitalizeFirstLetter(item.requestType.replaceAll("_", " "))} • Posted{" "}
                          {CommonFunction.getNotificationTimeStatus(
                            item?.createdAt
                          )}{" "}
                          by {item?.job_detail?.clientDetail?.businessName}
                        </Typography>
                      </Box>
                      <Box sx={{ padding: "0px 24px 24px 24px" }}>
                        {/* {item?.isDraft ? (
                          <BuildButton
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              isDraftRoute(item);
                              setOpenSuccess(true);
                            }}
                          >
                            <Typography
                              variant="h3"
                              sx={{ color: "var(--white)" }}
                            >
                              Continue Quote
                            </Typography>
                          </BuildButton>
                        ) : (
                          <BuildButton
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenSuccess(true)}
                          >
                            <Typography
                              variant="h3"
                              sx={{ color: "var(--white)" }}
                            >
                              Build Quote
                            </Typography>
                          </BuildButton>
                        )} */}

                        {item?.isDraft ? (
                          <BuildButton
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              isDraftRoute(item);
                              setOpenSuccess(true);
                            }}
                          >
                            <Typography
                              variant="h3"
                              sx={{ color: "var(--white)" }}
                            >
                              Continue Quote
                            </Typography>
                          </BuildButton>
                        ) : item?.status === "SENT" ||
                          item?.status === "COMPLETED" ? (
                          <BuildButton
                            fullWidth
                            variant="contained"
                            onClick={() => navigateToBQPreview(item)}
                          >
                            <Typography
                              variant="h3"
                              sx={{ color: "var(--white)" }}
                            >
                              View quote
                            </Typography>
                          </BuildButton>
                        ) :(item?.requestType === "EMERGENCY_CALLOUT" ||
                        item?.requestType === "PROJECT_SUPPORT") &&
                      item?.status === "REQUESTED" ? (
                      <BuildButton
                        fullWidth
                        variant="contained"
                        // onClick={() => handleAssign(item)}
                      >
                        <Typography
                          variant="h3"
                          sx={{ color: "var(--white)" }}
                        >
                          Assign To...
                        </Typography>
                      </BuildButton>
                    ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                      item?.job_detail?.timeFrame[0]?.recurringShift ===
                        false &&
                      item?.status === "REQUESTED" ? (
                      <BuildButton
                        fullWidth
                        variant="contained"
                        // onClick={() => handleAssign(item)}
                      >
                        <Typography
                          variant="h3"
                          sx={{ color: "var(--white)" }}
                        >
                          Assign To...
                        </Typography>
                      </BuildButton>
                    ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                      item?.job_detail?.timeFrame[0]?.recurringShift ===
                        true &&
                      item?.status === "REQUESTED" ? (
                      <BuildButton
                        fullWidth
                        variant="contained"
                        // onClick={() => handleAssign(item)}
                      >
                        <Typography
                          variant="h3"
                          sx={{ color: "var(--white)" }}
                        >
                          Assign To...
                        </Typography>
                      </BuildButton>
                    ) : (item?.requestType === "EMERGENCY_CALLOUT" ||
                        item?.requestType === "PROJECT_SUPPORT" ||
                        item?.requestType === "MAINTENANCE_SERVICE") &&
                      (item?.status === "COMPLETED" ||
                        item?.status === "PENDING" ||
                        item?.status === "IN_PROGRESS") ? (
                      <BuildButton
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: "#a1d5f0" }}
                      >
                        <Typography
                          variant="h3"
                          sx={{ color: "#0DA4CE" }}
                        >
                          Assigned
                        </Typography>
                      </BuildButton>
                    ):  (
                          <BuildButton
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenSuccess(true)}
                          >
                            <Typography
                              variant="h3"
                              sx={{ color: "var(--white)" }}
                            >
                              Build Quote
                            </Typography>
                          </BuildButton>
                        )}

                        <Box
                          sx={{
                            marginTop: "1.5rem",
                          }}
                          className={breakpoint.messageMilk}
                        >
                          <Typography
                            variant="h6"
                            color="text.secondary"
                            className="rfqRequestType"
                          >
                            Message{" "}
                            {item?.job_detail?.clientDetail?.businessName}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider
                        sx={{
                          border: "1px solid #e0e0e0",
                        }}
                      />

                      <Box sx={{ padding: "24px 0px 8px 24px " }}>
                        <Typography variant="h5" color="text.secondary">
                          Details
                        </Typography>
                      </Box>

                      <Box sx={{ padding: "0px 0px 0px 24px " }}>
                        <Typography variant="h6" color="text.secondary">
                          {item?.job_detail?.projectDescription}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Divider
                          sx={{
                            border: "1px solid #e0e0e0",
                          }}
                        />
                      </Box>
                      <Box sx={{ padding: "24px" }}>
                        <Typography variant="h5" color="text.secondary">
                          Attachments
                        </Typography>
                        <Box>
                          {item?.job_detail?.mediaFile.length > 0 ? (
                            item?.job_detail?.mediaFile.map(
                              (img: any, index: any) => {
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      border: "1px solid grey",
                                      display: "flex",
                                      marginTop: 2,
                                      marginBottom: "10px",
                                      width: "182px",
                                      padding: "8px 18px",
                                      justifyContent: "space-between",
                                    }}
                                    // className={breakpoint.boxContainerFile}
                                  >
                                    <Box
                                      className={breakpoint.boxInnerContainer}
                                    >
                                      <img
                                        src={setImageIconOrUrl(img?.url || "")}
                                        alt="img not found"
                                        height="30px"
                                        width="25px"
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        flexDirection: "column",
                                        flex: "1",
                                        paddingX: "17px",
                                      }}
                                    >
                                      {item?.job_detail?.mediaFile.length >
                                      0 ? (
                                        <>
                                          <Button
                                            sx={{
                                              padding: "0px",
                                            }}
                                          >
                                            <Link
                                              href={img?.url}
                                              underline="none"
                                              target="_blank"
                                              color="secondary"
                                              sx={{ fontSize: "14px" }}
                                            >
                                              <EllipsisToolTip
                                                style={{ width: "100px" }}
                                              >
                                                {decodeURI(img?.url).replace(
                                                  awsUrl,
                                                  ""
                                                )}
                                              </EllipsisToolTip>
                                            </Link>
                                          </Button>
                                          <Typography
                                            fontSize={10}
                                            color={"#4f4f4f"}
                                          >
                                            {convertToKB(img?.size)}
                                          </Typography>
                                        </>
                                      ) : (
                                        <Typography>No Image</Typography>
                                      )}
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <ArrowDropDownIcon />
                                    </Box>
                                  </Box>
                                );
                              }
                            )
                          ) : (
                            <Typography variant="h3">
                              No File Attached
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Divider
                          sx={{
                            border: "1px solid #e0e0e0",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ padding: "24px", backgroundColor: "#E5E5E5" }}>
                      <Typography variant="h5" color="text.secondary">
                        About the client
                      </Typography>

                      <Box className={breakpoint.ratingBox}>
                        {item?.job_detail?.clientDetail
                          ?.businessProfilePicture ? (
                          <img
                            src={`${item?.job_detail?.clientDetail?.businessProfilePicture}`}
                            alt="clock img not found"
                            width="65px"
                            height="65px"
                            loading="lazy"
                          />
                        ) : (
                          <img
                            src={`${LocalImages.AvatarIcon}`}
                            alt="clock img not found"
                            width="65px"
                            height="65px"
                            loading="lazy"
                          />
                        )}
                        <Box sx={{ ml: 2 }}>
                          <PreciseRating
                            // rating={4}
                            nameProduct={
                              item?.job_detail?.clientDetail?.businessName
                            }
                            address={`${item?.job_detail?.clientDetail?.location?.address}`}
                          />
                        </Box>
                      </Box>
                      <Box className={breakpoint.rating}> </Box>
                      <Box
                        sx={{
                          marginTop: "1.6rem",
                        }}
                        className={breakpoint.lastDescription}
                      >
                        <Typography variant="h6" color="text.secondary">
                          {item?.job_detail?.clientDetail?.businessTagline !==
                          ""
                            ? `${item?.job_detail?.clientDetail?.businessTagline}`
                            : `No business tagline added`}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </Box>
              </Box>
            );
          })}
      </ThemeProvider>
      <BuildQuoteModal
        open={openSuccess}
        handleClose={handleCloseSuccess}
        heading="BUILD A QUOTE"
        subHeading="How would you like to get started?"
        id={id}
        setSpOwnModalOpen={setSpOwnModalOpen}
      />
      <SpOwnModal open={spOwnModalOpen} setOpen={setSpOwnModalOpen} />
    </>
  );
};

export default MobileResponsive;
