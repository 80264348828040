import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  Stack,
  TextareaAutosize,
  styled,
  TextField,
  InputLabel,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SpQuoteOwnFinalSubmit } from "../actions";
import { getBuildQuoteOwnState } from "../reducer";
import { Form, Formik } from "formik";
import InputField from "../../../components/inputfield/FormikInputField/InputField";
import DatePickerField from "../../../components/inputfield/DatePickerField/DatePickerField";
import moment from "moment";
import localImages from "../../../Utils/localImages";
import FinalSubmitPreview from "./FinalSubmitPreview";
import { StyledButton } from "../../../components/styledComponents/StyledButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Schema from "../../../schema/index";

const { FinalSubmitBuildQuoteOwnSchema } = Schema;

const { edit } = localImages;

type SpOwnModalTypes = {
  open: boolean;
  setOpen: any;
  editModalOpen: any;
};

const FieldContainer = styled(Box)(({ theme }) => ({
  padding: "1.5rem",
  marginTop: "1.5rem",
  border: "1px solid #e0e0e0",
  background: "#fff",
  height: "180px",
}));

export default function FinalSubmitModalMobile({
  open,
  setOpen,
  editModalOpen,
}: SpOwnModalTypes) {
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const bqOwnState = useSelector(getBuildQuoteOwnState);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          position: "relative",
        }}
        className="disableScrollbar"
      >
        <Stack
          sx={{
            flexDirection: "row",
          }}
          justifyContent="space-between"
          alignItems="center"
          paddingY="19px"
          paddingX="25px"
          boxShadow={"0px 1px 4px rgba(0, 0, 0, 0.1)"}
        >
          <IconButton onClick={handleClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography fontSize="1.2rem" color="#4f4f4f" fontWeight={500}>
            Build Quote
          </Typography>
          <Box></Box>
        </Stack>

        <Formik
          initialValues={{
            client: "",
            quoteValidDate: "",
            downloadUrl: "",
            comments: "",
            totalCost: 0,
          }}
          onSubmit={(values) => {
            let data = {
              companyId: bqOwnState?.companyId,
              reqJobId: bqOwnState?.jobId,
              quoteSelection: "UPLOAD",
              isDraft: false,
              totalCost: values?.totalCost,
              downloadUrl: values?.downloadUrl,
              comments: values?.comments,
              quoteValidDate: moment(values?.quoteValidDate).format(
                "YYYY-MM-DD"
              ),
            };

            dispatch(SpQuoteOwnFinalSubmit(data, history));
          }}
          validationSchema={FinalSubmitBuildQuoteOwnSchema()}
        >
          {({
            values,
            setValues,
            getFieldProps,
            errors,
            touched,
            setFieldValue,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (bqOwnState) {
                setValues({
                  ...values,
                  downloadUrl: bqOwnState?.downloadUrl,
                  client: bqOwnState?.client,
                });
              }
            }, [bqOwnState]);

            return (
              <Form>
                <Box
                  sx={{
                    padding: "1.5rem 1.87rem",
                    background: "#e5e5e5",
                  }}
                >
                  <Typography
                    fontSize="1.2rem"
                    color="#4f4f4f"
                    fontWeight={500}
                  >
                    Submit Quote
                  </Typography>
                  <Stack marginTop="2rem" direction="row" spacing={2}>
                    <InputField
                      disabled
                      label="Client"
                      placeholder="Client Name"
                      type={"text"}
                      getFieldProps={getFieldProps}
                      id="client"
                      fullWidth
                    />
                  </Stack>

                  <Stack marginTop="2rem" direction="row" spacing={2}>
                    <DatePickerField
                      label="Valid Until"
                      placeholder="Select"
                      minDate={moment().add(1, "days").toDate()}
                      maxDate={moment().add(1, "years").toDate()}
                      name={"quoteValidDate"}
                      fullWidth
                    />
                  </Stack>

                  <label
                    style={{
                      display: "block",
                      marginTop: "1rem",
                      marginBottom: ".5rem",
                      color: errors?.comments ? "#EB5757" : "#424546",
                    }}
                    htmlFor="comment"
                  >
                    Add comments
                  </label>
                  <TextareaAutosize
                    id="comment"
                    className="textarea_field"
                    style={{
                      minWidth: "calc(100%)",
                      maxWidth: "calc(100%)",
                      marginTop: ".5rem",
                      minHeight: "65px",
                      maxHeight: "65px",
                      padding: "12px 1rem",
                      borderColor: errors?.comments ? "#EB5757" : "#e0e0e0",
                    }}
                    placeholder="Send a message with a quote"
                    {...getFieldProps("comments")}
                  />
                  <FieldContainer
                    overflow={"hidden"}
                    position="relative"
                    marginTop=".5rem"
                  >
                    {/* <IconButton
                      sx={{
                        background: "#fff",
                        border: "1px solid #e0e0e0",
                        padding: ".8rem",
                        position: "absolute",
                        right: 10,
                        top: 10,
                        zIndex: 1000000,
                      }}
                      onClick={() => {
                        editModalOpen(true);
                        setOpen(false);
                      }}
                    >
                      <img src={edit} alt="pen" />
                    </IconButton> */}

                    <FinalSubmitPreview link={bqOwnState?.downloadUrl} />
                  </FieldContainer>

                  <Box marginTop="1rem">
                    <InputLabel
                      sx={{
                        color:
                          touched?.totalCost && errors?.totalCost
                            ? "#EB5757"
                            : "var(--clientColor)",
                      }}
                      htmlFor={"Total"}
                    >
                      Total Cost (AUD)
                    </InputLabel>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: ".7rem",
                        "& input": {
                          padding: ".9rem .7rem",
                          background: "#fff",
                          "&:disabled": {
                            background: "#fff",
                          },
                        },
                        "& fieldset": {
                          borderRadius: 0,
                          border:
                            touched?.totalCost && errors?.totalCost
                              ? "1px solid #EB5757 !important"
                              : "1px solid #bdbdbd !important",
                        },
                      }}
                      placeholder={"Please enter the total cost of the quote."}
                      type={"number"}
                      {...getFieldProps("totalCost")}
                      onChange={(e) => {
                        let str: any = `${e.target.value}`;

                        if (str.includes(".")) {
                          let arr = str.split(".");
                          if (arr[1].length === 3) {
                            str = `${arr[0]}.${arr[1][0]}${arr[1][2]}`;
                          }
                        }

                        setFieldValue("totalCost", Number(str));
                      }}
                      inputProps={{
                        min: 0,
                        step: "any",
                      }}
                      value={!values?.totalCost ? "" : values?.totalCost}
                    />
                  </Box>

                  <Stack direction="column" spacing={2} marginY={"1.5rem"}>
                    <StyledButton
                      background="#0da4ce"
                      color="#fff"
                      padding="15px 50px"
                      type={"submit"}
                    >
                      Submit
                    </StyledButton>
                    <StyledButton
                      border="1px solid #e0e0e0"
                      color="#4f4f4f"
                      padding="15px 50px"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </StyledButton>
                  </Stack>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
}
