import { Typography } from "@mui/material";
import React , {useEffect}from "react";
import LocalImages from "../../../Utils/localImages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TitleField, TextContainer, SVGDragContainer } from "../styles";
import { Formik, Form, FormikProps } from "formik";
import Utils from "../../../Utils";
import Schema from "../../../schema";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModel } from "../../../model";
import FilledButton from "../../../components/button/filledButton";

import {
  getInitialValues,
  getQuillProps,
  getRemoveButtonProps,
  getDoneButtonProps,
  finalSubmit,
  getFormikProps,
  setFormError,
} from "./helper";

const { DragIcon } = LocalImages;

const TextEditorForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  setFieldTouched,
  provided,
  isNew,
  index,
  isActive,
  setIsActive,
}: any) => {
  const dispatch = useDispatch();
  // const { dispatch, reducers } = useRedux();
  const { errorsForms, draggableForms } = useSelector(
    (state: ReducersModel) => state.scratchTemplateReducer
  );
  const { JobDetailsData } = useSelector(
    (state: ReducersModel) => state.jobDetailsModelReducer
  );

  console.log("Hello", values);
  return (
    <Form>
      <SVGDragContainer
        onClick={(e: any) => e.stopPropagation()}
        {...provided.dragHandleProps}
      >
        {isActive ? "" : <img src={DragIcon} alt="drag" /> }
        
      </SVGDragContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: 368 }}>
          <TitleField
            name={"title"}
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!isNew}
            placeholder={"Title"}
          />
          {Boolean(touched.title && errors.title) && (
            <Typography variant={"caption"} color={"error"}>
              {errors.title}
            </Typography>
          )}
        </div>
        {isActive && (
          <div
            style={{
              display: "flex",
              width: 161,
              justifyContent: "space-between",
            }}
          >
            <FilledButton 
            {...getRemoveButtonProps(errorsForms, draggableForms, dispatch, index)} 
            >
              {"Remove"}
            </FilledButton>
            <FilledButton {...getDoneButtonProps()}>{"Done"}</FilledButton>
          </div>
        )}
      </div>

      <div
        onClick={() => {
          setIsActive(true);
          setFormError(dispatch, errorsForms, index);
          
        }}
      >
        <ReactQuill
          {...getQuillProps(
            "description",
            isActive,
            setFieldValue,
            setFieldTouched,
            values.description
          )}
        />
      </div>
    </Form>
  );
};

const TextEditor = ({ provided, isNew, form, index }: any) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = React.useState(false);
  const { draggableForms, errorsForms } = useSelector(
    (state: ReducersModel) => state.scratchTemplateReducer
  );

  return (
    <TextContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      isActive={isActive}
      cursor="pointer"
    >
      <Formik
        initialValues={getInitialValues(true, form)}
        validationSchema={Schema.ScratchDraggableSchema1}
        onSubmit={(values: any) => {
          console.log("MMM", values);
          finalSubmit(index, values, draggableForms, errorsForms, dispatch);
          setIsActive(false);
        }}
      >
        {(formikProps: FormikProps<any>) => {
          return (
            <TextEditorForm
              {...getFormikProps(
                formikProps,
                provided,
                isNew,
                index,
                isActive,
                setIsActive
              )}
            />
          );
        }}
      </Formik>
    </TextContainer>
  );
};

export default React.memo(TextEditor);
