import React from "react";
import { styled, Typography } from "@mui/material";

const Input = styled("input")(({ _theme }: any) => ({
  width: "100%",
  outline: "none",
  paddingLeft: 12,
  fontSize: 16,
  border: "none",
}));

const InputContainer = styled("div")(({ _theme }: any) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

function CostInputField({
  name,
  readOnly,
  onChange,
  onBlur,
  value,
  error,
  touched,
}: CostInputFieldProps) {
  console.log("XXX", { name, value, error, touched });
  return (
    <InputContainer>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      {Boolean(touched && error) && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </InputContainer>
  );
}

type CostInputFieldProps = {
  name: string;
  readOnly?: boolean;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (evt: any) => void;
  error?: string;
  touched?: boolean;
  value: string;
};

export default React.memo(CostInputField);
