/* Theme Colors */

const themePrimary = "#0DA4CE";
const themeSecondary = "#097390";

/* Light Colors */

const backgroundPrimary = "#FAFAFA";
const backgroundSecondary = "#E0E0E0";
const backgroundDefault = "#FFFFFF";

/* Dark Colors */

const darkPrimary = "#000000";
const darkSecondary = "#828282";

const COLORS = {
  themePrimary,
  themeSecondary,
  backgroundPrimary,
  backgroundSecondary,
  darkPrimary,
  darkSecondary,
  backgroundDefault
};

export default COLORS;
