import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumb: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    previewRow: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      backgroundColor: "var(--outlineColor)",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    previewItem: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginLeft: "1.5%",
      height: "54px",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      "& > :not(style)": {
        width: "71.5vw",
        minHeight: "80vh",
        border: "1px solid var(--modalBoxShadowColor)",
        borderRadius: "unset",
      },
    },
    body: {
      minHeight: "70vh",
      maxWidth: "800px",
      margin: "0 auto",
    },
    buttonsTogether: {
      display: "flex",
      gap: 10,
      flexWrap: "wrap",
      marginRight: "1.5%",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },

    logo: {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px",
    },
    logoModal: {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px",
    },
    clientBody: {
      display: "flex",
      justifyContent: "space-between",
      gap: 20,
      flexWrap: "wrap",
    },
    clientBodyModal: {
      display: "flex",
      justifyContent: "space-between",
      gap: 20,
      flexWrap: "wrap",
    },
    textBody: {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px",
      flexWrap: "wrap",
    },
    textBodyModal: {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px",
      flexWrap: "wrap",
    },
    singleTextBody: {
      display: "block",

      padding: "8px",
      flexWrap: "wrap",
    },
    singleTextBodyModal: {
      display: "block",
      padding: "4px",
      flexWrap: "wrap",
    },
    customTable: {
      minWidth: 700,
      "& .css-3xi8hh-MuiTableCell-root": {
        padding: "0px", // <-- arbitrary value
      },
    },
    divider: {
      width: "80%",
      margin: "0 auto",
    },
    buttonPositions: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      "& > :not(style)": {
        width: "76vw",
        marginTop: "20px",
      },
    },
    buttonAll: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      width: "76vw",
    },
    buttonsaveDraft: {
      display: "flex",
      gap: 30,
      flexWrap: "wrap",
    },
    modalWrapperChild: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "1%",
    },
    handleCloseButton: {
      position: "absolute",
      top: 20,
      right: 10,
      cursor: "pointer",
    },
    gridMuiroot: {
      flexGrow: 1,
      "& .css-130jjir-MuiInputBase-root-MuiOutlinedInput-root": {
        borderRadius: "unset",
      },
    },
    modalButton: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 20,
      marginTop: "10px",
      marginBottom: "10px",
      "@media (max-width: 600px)": {
        display: "block",
        width: "100%",
      },
    },
    zoomContainer: {
      width: 200,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    textField: {
      backgroundColor: "white",
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
  })
);

export { useStyles };
