import { useEffect, useState } from "react";
// import MultiInput from "../../../../components/inputfield/MultiInput";

import { useStyles as UseStyles } from "../../../../dashboard/settings/team/style";
import Utils from "../../../../../Utils";
import LocalImages from "../../../../../Utils/localImages";
import SearchIcon from "@mui/icons-material/Search";
// import { deleteTeamMember } from "./action";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Typography,
  TableContainer,
  TableRow,
  TableBody,
} from "@mui/material";
import {
  getEmployeeJobListReducerState,
  assignJobsReduserState,
  assignRequestTypeState,
} from "../../reducer";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material";
import { useClassStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { getEmployeeJobs } from "../../action";
import { AutoCompleteProps, EmployeeType } from "./prototypes";
import { bookJobExternal } from "../../action";
import moment from "moment-timezone";
import ActionName from "../../../../../Utils/actionName";

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  fontSize: ".875rem",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
  fontWeight: 400,
  fontSize: ".75rem",
});
const { circle } = LocalImages;

type AssignEmployeeListProps = {
  handleClose: () => void;
  modalContainerStyle: string;
  data?: any;
  data2?: any;
  bookingData?: any;
  userId?: any;
  table?: any;
  index?: any;
  tradeIndex?: number;
  dayIndex?:number;
};

function AssignEmployeeList({
  handleClose,
  modalContainerStyle,
  data,
  data2,
  bookingData,
  table,
  index,
  dayIndex,
  tradeIndex,
}: AssignEmployeeListProps) {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();

  const userList: any = [];
  const i:any=dayIndex;
  const t:any=tradeIndex;

  const { EmployeeList } = useSelector(getEmployeeJobListReducerState);
  const { userId, invitedEmployee, invitedEmployee2, tableDataMs } =
    useSelector(assignJobsReduserState);

  const { tradeList, nonRecurringList, recurringList, sunday, monday, tuesday, wednesday, thursday, friday, saturday } = useSelector(assignRequestTypeState);
  const tradeType:string=data;
  const [searchValue, setSearchValue] = useState<string>("");
  const value: any = [];
  const searchList: any = [];
  EmployeeList &&
    EmployeeList.map((val: any) => {
      searchList.push({
        empId: val?._id,
        title: val.name,
        userTrade: val.userTrades[0],
        profile: val?.profilePicture,
      });
    });

  useEffect(() => {
    
    if (bookingData?.dateTime) {
      dispatch(
        getEmployeeJobs(
          `?companyId=${localStorage.getItem(
            "user_id"
          )}&tradeType=${data}&isTeamUp=${2}&dateTime=${bookingData?.dateTime}`
        )
      );
    } else if (bookingData?.date) {
      dispatch(
        getEmployeeJobs(
          `?companyId=${localStorage.getItem(
            "user_id"
          )}&tradeType=${data}&isTeamUp=${2}&dateTime=${bookingData?.date}`
        )
      );
    } else {
      dispatch(
        getEmployeeJobs(
          `?companyId=${localStorage.getItem("user_id")}&isTeamUp=${2}&tradeType=${data}`
        )
      );
    }
  }, []);

  userId &&
    userId.map((val: any) => {
      userList.push(val?.empId);
    });

  const handleChange = (data: EmployeeType[]) => {
    value.push(data);
    const uniqueMap = new Map(
      value.map((val: EmployeeType) => [val.empId, val])
    );
    const uniqueVal: any = Array.from(uniqueMap.values());
    dispatch({
      type: ActionName.ASSIGN_REQUEST_TYPE,
      payload: {
        tradeList: tradeList?.map((item: any, index: number) =>
          index === tradeIndex ? { ...item, employee: data } : item
        ),
        nonRecurringList: nonRecurringList?.map((item: any, index: number) =>
          index === tradeIndex ? { ...item, employee: data } : item
        ),
        // recurringList: recurringList[i]?.map((item: any, index: number) =>
        //   index === tradeIndex ? {...item, employee: data } : item
        // ),
      },
    });

    if(dayIndex==0){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          sunday: sunday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }
    else if(dayIndex==1){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          monday: monday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }

    else if(dayIndex==2){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          tuesday: tuesday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }

    else if(dayIndex==3){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          wednesday: wednesday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }
    else if(dayIndex==4){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          thursday: thursday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }
    else if(dayIndex==5){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          friday: friday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }
    else if(dayIndex==6){
      dispatch({
        type: ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          saturday: saturday?.map((item: any, index: number) =>
            index === tradeIndex ? {...item, employee: data } : item
          )
        },
      });
    }

    invitedEmployee.push({
      userId: uniqueVal[0]?.empId,
      trade: tradeType,
      dateTime: moment(new Date(bookingData?.date)).format("YYYY-MM-DD"),
      shiftType: bookingData?.shift,
      // isExternal: true
    });
    invitedEmployee2.push({
      userId: uniqueVal[0]?.empId,
      trade: tradeType,
      // isExternal: false
    });

    dispatch(
      bookJobExternal(
        uniqueVal[0]?.empId,
        data2?._id,
        data2?.teamCompanyJobId,
        data2?.teamId,
        data2?.teamJobId,
        bookingData?.shift ? bookingData?.shift : "DAY",
        bookingData?.dateTime
          ? bookingData?.dateTime
          : bookingData?.date
          ? bookingData?.date
          : moment(new Date()).format("yyyy/MM/DD"),
          tradeType
      )
    );
    
    handleClose();
  };
  console.log(data2,"Flist")
  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (searchValue) {
      {
        /* eslint-disable */
        // dispatch(
        //   getEmployeeJobs(
        //     `?companyId=${localStorage.getItem(
        //       "user_id"
        //     )}&tradeType=${data}&isTeamUp=${2}&searchKey=${searchValue}&dateTime=${
        //       bookingData?.dateTime
        //         ? bookingData?.dateTime
        //         : bookingData?.date 
        //     }`
        //   )
        // );
        if (bookingData?.dateTime) {
          dispatch(
            getEmployeeJobs(
              `?companyId=${localStorage.getItem(
                "user_id"
              )}&tradeType=${data}&isTeamUp=${2}&searchKey=${searchValue}&dateTime=${bookingData?.dateTime}`
            )
          );
        } else if (bookingData?.date) {
          dispatch(
            getEmployeeJobs(
              `?companyId=${localStorage.getItem(
                "user_id"
              )}&tradeType=${data}&isTeamUp=${2}&searchKey=${searchValue}&dateTime=${bookingData?.date}`
            )
          );
        } else {
          dispatch(
            getEmployeeJobs(
              `?companyId=${localStorage.getItem("user_id")}&isTeamUp=${2}&searchKey=${searchValue}&tradeType=${data}`
            )
          );
        }
      }
    } else {
      getEmployeeJobs(
        `?companyId=${localStorage.getItem("user_id")}&isTeamUp=${2}&tradeType=${data}`
      );
    }
  };
  
  return (
    <>
      {searchList?.length > 0 ? (
        <div
          className={modalContainerStyle}
          style={{
            backgroundColor: "#fffff",
            position: "absolute",
            top: "38%",
            left: "53.5%",
          }}
        >
          <Box
            sx={{
              padding: "0 10px 0 10px",
              backgroundColor: "white",
              width: "350px",
              height: "400px",
            }}
          >
            <Box sx={{ marginLeft: "300px" }}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer", marginTop:"9px" }}
                color="disabled"
              />
            </Box>
            <TextField
              onChange={handleSearch}
              sx={{
                marginTop: "5% !important",
                marginLeft: "1%",
                width: "98%",
              }}
              placeholder="Search names"
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <Typography sx={{ marginLeft: "3%", marginTop: "2%" }}>
              Suggested Tradies
            </Typography>
            <Box sx={{ height: "250px", overflow: "auto" }}>
              {searchList?.map((i: any) => {
                return (
                  <>
                    <TableContainer
                      sx={{
                        borderRadius: "0px",
                        marginTop: "5%",
                        "&:hover": {
                          background: "#c0e8f2 !important",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow
                          sx={{
                            marginLeft: "5%",
                            width: "100% !important",
                            height: "50px",
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                              textTransform: "capitalize !important",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "300px",
                              display: "flex",
                              marginLeft: "20px",
                              paddingTop: "10px",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              handleChange(i);
                            }}
                          >
                            <img
                              loading="lazy"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                              }}
                              src={i?.profile ? i?.profile : circle}
                              srcSet={i?.profile}
                              alt=""
                            />
                            <Typography sx={{ marginLeft: "5px" }}>
                              {i?.title}
                            </Typography>
                          </Box>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </>
                );
              })}
            </Box>
          </Box>
        </div>
      ) : (
        <div
          className={modalContainerStyle}
          style={{
            minHeight: "500px",
            backgroundColor: "#fffff",
            position: "absolute",
            top: "38%",
            left: "54%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              height: "326px",
              width: "326px",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{ cursor: "pointer", marginLeft: "280px" }}
              color="disabled"
            />
            <TextField
              onChange={handleSearch}
              sx={{ marginTop: "5% !important" }}
              placeholder="Search names"
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <Box sx={{ marginTop: "25% !important" }}>
              {searchValue ? (
                <Typography>No result Found</Typography>
              ) : (
                <>
                  <Box>
                    <img src={LocalImages.noEmployee} alt={"l"} />
                  </Box>
                  <Typography>
                    No employees to show. Invite your team to assign jobs.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}

const TeamModal = {
  AssignEmployeeList,
};

export default TeamModal;
