import {
    Box,
    Typography,
    useTheme,
    useMediaQuery,
    IconButton,
    styled,
    Button,
    Stack,
    TextareaAutosize,
  } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { useStyles } from "../../../../LandingPage/style";
  import CloseIcon from "@mui/icons-material/Close";
  import { useDispatch } from "react-redux";
  import { StyledInputField } from "../../../../../components/styledComponents/auth/signIn";
  import { Form, Formik } from "formik";
  import { EnquiryForm } from "../../../../LandingPage/actions";
  import Schema from "../../../../../schema";
  
  type PropTypes = {
    ModalOpen: boolean;
    handleLogout?: any;
    setModalOpen?: any;
  };
  
  interface MyThemeComponentProps {
    bgColor?: string;
    theme?: any;
  }
  
  const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "bgColor",
  })<MyThemeComponentProps>(({ bgColor, theme }) => ({
    background: bgColor || "#000",
    borderRadius: "0",
    padding: ".9rem 3rem",
    color: "#fff",
    height: "40px",
    textTransform: "initial",
    "&:hover": {
      background: bgColor || "#000",
      // color: "#fff",
    },
    [theme.breakpoints.down("md")]: {
      padding: ".9rem 2.6rem",
    },
  }));
  const EnquireModal = ({ ModalOpen, setModalOpen }: PropTypes) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down(700));
  
    const handleClose = () => {
      setModalOpen(false);
    };
    return (
      <Modal
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.2)" } }}
        open={ModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ background: "transparent" }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            companyName: localStorage.getItem("user_name"),
            contactNumber: "",
            enquiry: "",
          }}
          validationSchema={Schema.EnquiryFormSchema()}
          onSubmit={(values: any) => {
            // console.log("values", values);
            dispatch(EnquiryForm(values));
            setModalOpen(false);
          }}
        >
          {({
            isValid,
            dirty,
            values,
            getFieldProps,
            setFieldValue,
            errors,
            touched,
            setTouched,
            setFieldTouched,
          }) => {
            return (
              <Form>
                <Box
                  sx={(theme) => ({
                    marginTop: "11%",
                    width: "607px",
                    minheight: "462px",
                    maxHeight: "2000px",
                    backgroundColor: "#fff",
                    position: "relative",
                    padding: "1.6rem 1.5rem",
                    outline: "none",
                    [theme.breakpoints.down("md")]: {
                      zIndex: 1,
                      width: "20rem",
                      margin: "0 1rem",
                      padding: "1.6rem 1rem",
                      overflow: "auto",
                      height: "600",
                    },
                  })}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      padding: "0",
                      top: "1.25rem",
                      right: "1.25rem",
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "18px",
                        marginBottom: "1rem",
                        fontWeight: "500",
                      }}
                      id="modal-modal-title"
                    >
                      Enquiry Form
                    </Typography>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing={{ xs: 2, md: 3 }}
                    >
                      <Box>
                        <Typography className={classes.subTitle}>
                          Full Name
                        </Typography>
  
                        <div className={classes.inputField}>
                          <StyledInputField
                            sx={{
                              width: "267px",
  
                              [theme.breakpoints.down(1200)]: {
                                width: "100%",
                                background: "#E5E5E5",
                              },
                            }}
                            placeholder="Name"
                            name="fullName"
                            type={"text"}
                          />
                        </div>
                      </Box>
                      <Box>
                        <Typography className={classes.subTitle}>
                          Email
                        </Typography>
  
                        <div className={classes.inputField}>
                          <StyledInputField
                            
                            placeholder="Email"
                            name="email"
                            type={"text"}
                          />
                        </div>
                      </Box>
                    </Stack>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing={{ xs: 2, md: 3 }}
                    >
                      <Box>
                        <Typography className={classes.subTitle}>
                          Service Provider Name
                        </Typography>
  
                        <div className={classes.inputField}>
                          <StyledInputField
                            sx={{
                              width: "267px",
                              "&:disabled": {
                                backgroundColor: "#E0E0E0",
                                color: "#828282",
                              },
                              [theme.breakpoints.down(1200)]: {
                                width: "100%",
                                background: "#E5E5E5",
                              },
                            }}
                            placeholder="Company Name"
                            name="companyName"
                            type={"text"}
                            disabled
                          />
                        </div>
                      </Box>
                      <Box>
                        <Typography className={classes.subTitle}>
                          Contact Number
                        </Typography>
  
                        <div className={classes.inputField}>
                          <StyledInputField
                            placeholder="Number"
                            name="contactNumber"
                            type={"text"}
                          />
                        </div>
                      </Box>
                    </Stack>
                    <Box>
                      <Typography className={classes.subTitle}>
                        Enquiry
                      </Typography>
  
                      <Box>
                        <TextareaAutosize
                          placeholder="How can we help?"
                          className="company_details_textarea"
                          style={{ minHeight: "80px", minWidth:"560px" }}
                          {...getFieldProps("enquiry")}
                          name="enquiry"
                          // onChange={(e) => {
                          //   if (e.target.value.length > 100) {
                          //     return;
                          //   }
                          //   if (values.enquiry.length === 100) {
                          //     if (e.target.value.length < 100) {
                          //       setFieldValue("enquiry", e.target.value);
                          //     }
                          //     return;
                          //   }
                          //   setFieldValue("enquiry", e.target.value);
                          // }}
                        />
                        <div
                          style={{
                            color: "#d32f2f",
                            marginTop: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors && touched.enquiry && errors.enquiry}
                        </div>
                      </Box>
                      {/* <div style={{ color: "red", marginTop: "5px" }}>
                        {errors && touched.enquiry && errors.enquiry}
                      </div> */}
                      {/* <Typography
                        marginTop=".75rem"
                        sx={{ color: "#828282", fontFamily: 400 }}
                      >
                        {100 - values.enquiry.length} Characters Left.
                      </Typography> */}
                    </Box>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "4%",
                      [theme.breakpoints.down("md")]: {
                        justifyContent: "center",
                      },
                    })}
                  >
                    <StyledButton
                      sx={{
                        color: "#000",
                        border: "1px solid #e0e0e0",
                        marginRight: "1rem",
                      }}
                      onClick={handleClose}
                      bgColor="#fff"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      bgColor="#0DA4CE"
                      type="submit"
                      sx={{
                        "&:disabled": {
                          background: "#e0e0e0",
                        },
                      }}
                      disabled={!isValid || !dirty}
                    >
                      Send
                    </StyledButton>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  };
  
  export default EnquireModal;
  