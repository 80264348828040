import { Box } from "@mui/material";
import { styled } from "@mui/system";

const RfqAcceptedChip = styled(Box)({
  borderRadius: "16px",
  background: "#ecfdf3",
  padding: "2px 10px",
  color: "#027A48",
  fontWeight: 500,
  display: "inline-block",
});

const RfqCompletedChip = styled(Box)({
  borderRadius: "16px",
  background: "#ecfdf3",
  padding: "2px 10px",
  color: "#027A48",
  fontWeight: 500,
  display: "inline-block",
});

const RfqSentChip = styled(Box)({
  borderRadius: "16px",
  background: "#F0F9FF",
  padding: "2px 10px",
  color: "#026AA2",
  fontWeight: 500,
  display: "inline-block",
});
const RfqCancelledChip = styled(Box)({
  borderRadius: "16px",
  background: "#FEF3F2",
  padding: "2px 10px",
  color: "red",
  fontWeight: 500,
  display: "inline-block",
});

const RfqDraftChip = styled(Box)({
  borderRadius: "16px",
  background: "#F8F9FC",
  padding: "2px 10px",
  color: "#363F72",
  fontWeight: 500,
  display: "inline-block",
});

const PendingChip = styled(Box)({
  borderRadius: "16px",
  background: "#FFFAEB",
  padding: "2px 10px",
  color: "#B54708",
  fontWeight: 500,
  display: "inline-block",
});

const CompletedChip = styled(Box)({
  borderRadius: "16px",
  background: "#ECFDF3",
  padding: "2px 10px",
  color: "#027A48",
  fontWeight: 500,
  display: "inline-block",
});

export {
  RfqDraftChip,
  CompletedChip,
  PendingChip,
  RfqSentChip,
  RfqAcceptedChip,
  RfqCancelledChip,
  RfqCompletedChip,
};
