const ActionName = {
  LOADING: "LOADING",
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SIGNUP: "SIGNUP",
  PROFILECOMPLETE: "PROFILECOMPLETE",
  PROFILESELECTOR: "PROFILESELECTOR",
  DROPDOWN: "DROPDOWN",
  EMPLOYEE_TEAM_PERMISSION: "EMPLOYEE_TEAM_PERMISSION",
  ADMIN_TEAM_PERMISSION: "ADMIN_TEAM_PERMISSION",
  EMPLOYEE_LIST: "EMPLOYEE_LIST",
  EDIT_MYPROFILE: "EDIT_MYPROFILE",
  EDIT_PERMISSION: "EDIT_PERMISSION",
  UPLOAD_CSV: "UPLOAD_CSV",
  SELECT_TEMPLATE_MODEL: "SELECT_TEMPLATE_MODEL",
  JOB_DETAILS: "JOB_DETAILS",
  GETSTARTED_MODAL_OPEN: "GETSTARTED_MODAL_OPEN",
  GETSTARTED_MODAL_CLOSE: "GETSTARTED_MODAL_CLOSE",
  GETSTARTED_TOOLTIP_OPEN: "GETSTARTED_TOOLTIP_OPEN",
  GETSTARTED_TOOLTIP_CLOSE: "GETSTARTED_TOOLTIP_CLOSE",
  GETSTARTED_STEPS_SOLETRADER: "GETSTARTED_STEPS_SOLETRADER",
  GETSTARTED_STEPS_COMPANY: "GETSTARTED_STEPS_COMPANY",
  GET_RFQLIST: "GET_RFQLIST",
  GET_MAINTAINANCELIST: "GET_MAINTAINANCELIST",
  GET_ALLJOBS_LIST: "GET_ALLJOBS_LIST",
  SWITCH_SEARCH_RESULT: "SWITCH_SEARCH_RESULT",
  // Scratch Template ActionNames
  SCRATCH_TEMPLATE: "SCRATCH_TEMPLATE",
  GET_EMPLOYEE_LIST_DASHBOARD: "GET_EMPLOYEE_LIST_DASHBOARD",
  ASSIGN_EMPLOYEE: "ASSIGN_EMPLOYEE",
  GET_COMMENTS: "GET_COMMENTS",
  POST_COMMENTS: "POST_COMMENTS",
  ASSIGN_JOB: "ASSIGN_JOB",
  JOB_COUNTER: "JOB_COUNTER",
  SCHEDULE_BOOKING: "SCHEDULE_BOOKING",
  SAVED_COMPANY_LISTING: "SAVED_COMPANY_LISTING",
  SERVICE_PROVIDER_LIST: "SERVICE_PROVIDER_LIST",
  ROLES: "ROLES",
  ASSIGN_REQUEST_TYPE: "ASSIGN_REQUEST_TYPE",
  FINANCIAL_ONBOARDING_LIST: "FINANCIAL_ONBOARDING_LIST",
  INVITE: "INVITE",
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  PROFILE_DETAILS_DATA: "PROFILE_DETAILS_DATA",
  FINANCIAL_ONBOARDING_DOWNLOAD: "FINANCIAL_ONBOARDING_DOWNLOAD",
  // written by Sarita
  RATE_DETAILS: "RATE_DETAILS",
  SERVICE_PROVIDER_SERACH: "SERVICE_PROVIDER_SERACH",
  SERVICE_PROVIDER_SEARCH_RESET: "SERVICE_PROVIDER_SEARCH_RESET",
  OTHER_JOB_DETAIL: "OTHER_JOB_DETAIL",
  JOB_ID: "JOB_ID",
  TAB_VALUE: "TAB_VALUE",
  BOOKED_DETAILS: "BOOKED_DETAILS",
  EXTERNAL_TRADE_DETAILS: "EXTERNAL_TRADE_DETAILS",
  GET_EXTERNAL:"GET_EXTERNAL"
};

export default ActionName;
