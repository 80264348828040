import { Box, Paper } from '@mui/material';
import { useStyles } from '.././spBuildsQuotes/spBuildsQuotesCss';

const PaperBody = ({ children }: any) => {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.root}>
        <Paper elevation={0}>
          <Box className={classes.body}>{children}</Box>
        </Paper>
      </Box>
    </div>
  );
};

export default PaperBody;
