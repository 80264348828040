import { Button, IconButton, styled, Box } from "@mui/material";

export const StyledIconButton = styled(IconButton)(({ _theme }: any) => {
  return {
    width: 35,
    height: 35,
    borderRadius: "0",
    border: "1px solid #e0e0e0",
    background: "#fafafa",
  };
});

export const AddSecButton = styled(Button)(({ _theme }: any) => ({
  padding: "5px 4px",
  border: "1px solid #e0e0e0",
  marginTop:"2%",
  width:"100%",
  background: "#fff",
  "&:hover": {
    background: "#fff",
  },
  color: "#4f4f4f",
  borderRadius: "0",
  fontSize:"16px",
  textTransform: "initial",
}));

export const FieldContainer = styled(Box)(({ theme }: any) => ({
  padding: "2rem 1.5rem",
  marginTop: "1.5rem",
  border: "1px solid #e0e0e0",
  background: "#fff",
  [theme.breakpoints.down(1024)]:{
    background:"#E5E5E5",
    border: "none",
  }
}));

export const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1rem 10.5rem",
  [theme.breakpoints.down("lg")]: {
    padding: "1.5rem 5.5rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "1.5rem 1.5rem",
  },
}));

export const DragContainer = styled(Box)(({ theme }) => ({
  marginTop: "1.5rem",
  padding: "2rem 2.3rem",
  background: "var(--bqscratchCon)",
  border: "1px solid var(--modalBoxShadowColor)",
}));
