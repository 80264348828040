import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LocalImages from "../../Utils/localImages";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              height: "74px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "0px 20px",
              position: "fixed",
              zIndex: "10",
            }}
          >
            <img
              style={{ width: "152px", height: "46px" }}
              src={LocalImages.Logo}
              alt="Logo"
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "530px",
              }}
            >
              <Typography sx={{ color: "#4f4f4f" }}>
                <a
                  style={{ textDecoration: "none", color: "#4f4f4f" }}
                  href="https://infinitetradesstgitlandingweb.appskeeper.in/#about"
                >
                  ABOUT
                </a>
              </Typography>
              <Typography sx={{ color: "#4f4f4f" }}>
                <a
                  style={{ textDecoration: "none", color: "#4f4f4f" }}
                  href="https://infinitetradesstgitlandingweb.appskeeper.in/#why"
                >
                  WHY CHOOSE US
                </a>
              </Typography>
              <Typography sx={{ color: "#4f4f4f" }}>
                <a
                  style={{ textDecoration: "none", color: "#4f4f4f" }}
                  href="https://infinitetradesstgitlandingweb.appskeeper.in/#how"
                >
                  HOW IT WORKS
                </a>
              </Typography>
              <Typography sx={{ color: "#4f4f4f" }}>
                <a
                  style={{ textDecoration: "none", color: "#4f4f4f" }}
                  href="https://infinitetradesstgitlandingweb.appskeeper.in/#contact"
                >
                  CONTACT
                </a>
              </Typography>
            </Box>

            <Box
              sx={{
                width: "170px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href="https://infinitetradesstgweb.appskeeper.in/selectoptionlogin"
              >
                <Button
                  sx={{
                    color: "#424546",
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  LOG IN
                </Button>
              </a>

              <a
                style={{ textDecoration: "none" }}
                href=" https://infinitetradesstgweb.appskeeper.in/selectoption"
              >
                <Button
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    backgroundColor: "#0da4ce",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  SIGN UP
                </Button>
              </a>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#ffffff",
              width: "100%",
              height: "68px",
              alignItems: "center",
              padding: "0 15px",
              position: "fixed",
              zIndex: "10",
            }}
          >
            <div
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img
                style={{ width: "35px", height: "28px" }}
                src={LocalImages.Ham}
                alt="Menu"
              />
            </div>

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="https://infinitetradesqaitlandingweb.appskeeper.in/#about"
                  >
                    ABOUT
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="https://infinitetradesqaitlandingweb.appskeeper.in/#why"
                  >
                    WHY CHOOSE US
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="https://infinitetradesqaitlandingweb.appskeeper.in/#how"
                  >
                    HOW IT WORKS
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="https://infinitetradesqaitlandingweb.appskeeper.in/#contact"
                  >
                    CONTACT
                  </a>
                </Typography>
              </MenuItem>
            </Menu>
            <Box
              sx={{
                width: "170px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "2%",
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href="https://infinitetradesstgweb.appskeeper.in/selectoption"
              >
                <Button
                  sx={{
                    color: "#424546",
                    fontSize: "14px",
                    height: "42px",
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  LOG IN
                </Button>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://infinitetradesstgweb.appskeeper.in/selectoption"
              >
                <Button
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    height: "42px",
                    backgroundColor: "#0da4ce",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  SIGN UP
                </Button>
              </a>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Header;
