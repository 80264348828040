import ActionName from "../../../Utils/actionName";

export const RfqJobsReducer = (
  state = {
    RfqList: [],
    total: 0,
    totalPage: 1,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GET_RFQLIST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const JobsCounterReducer = (
  state = {
    counterList: [],
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.JOB_COUNTER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const EmployeeJobListReducer = (
  state = {
    EmployeeJobList: [],
    total: 0,
    totalPage: 1,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GET_EMPLOYEE_LIST_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const AssignJobReducer = (
  state = {
    tableDataMs: [],
    userId: [],
    invitedEmployee: [],
    invitedEmployee2: [],
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.ASSIGN_JOB:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const MaintainanceJobsReducer = (
  state = {
    MaintainanceList: [],
    totalM: 0,
    totalPageM: 1,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GET_MAINTAINANCELIST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const ExternalJobsReducer = (
  state = {
    ExternalList: [],
    totalM: 0,
    totalPageM: 1,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GET_EXTERNAL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const AllJobsReducer = (
  state = {
    AllJobsList: [],
    total: 0,
    totalPage: 1,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GET_ALLJOBS_LIST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const AssignRequestTypeReducer = (
  state = {
    tradeTypeC:[],
    tradeList: [],
    monday:[],
    tuesday:[],
    wednesday:[],
    thursday:[],
    friday:[],
    saturday:[],
    sunday:[],
    recurringList: [],
    nonRecurringList: [],
  },
  action: any
) => {
  if (action.type === ActionName.ASSIGN_REQUEST_TYPE) {
    return { ...state, ...action.payload };
  }
  return { ...state };
};


export const getRQFJobsReducerState = (state: any) => state.RfqJobsReducer;
export const getEmployeeJobListReducerState = (state: any) =>
  state.EmployeeJobListReducer;
export const getMaintainanceJobsReducerState = (state: any) =>
  state.MaintainanceJobsReducer;
  export const getExternalReducerState = (state: any) =>
  state.ExternalJobsReducer;

export const getAllJobsReducerState = (state: any) => state.AllJobsReducer;
export const assignJobsReduserState = (state: any) => state.AssignJobReducer;
export const jobsCounterReducerState = (state: any) => state.JobsCounterReducer;
export const assignRequestTypeState = (state: any) => state.AssignRequestTypeReducer;