import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    modalWrapperChild: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    handleCloseButton: {
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
    },
    gridMuiroot: {
      flexGrow: 1,
      margin: 25,
    },
    templateHeight: {
      display: "flex",
      minHeight: "330px",
    },
    templateClick: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      marginTop: 40,
    },
  })
);

const useStylesModalResponsive = makeStyles(() =>
  createStyles({
    modalWrapperChild: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    handleCloseButton: {
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
    },
    gridMuiroot: {
      flexGrow: 1,
      margin: 25,
    },
    templateHeight: {
      display: "flex",
      minHeight: "330px",
    },
    templateClick: {
      display: "flex",
      cursor: "pointer",
      marginTop: 40,
    },
  })
);
export { useStyles, useStylesModalResponsive };
