import { useRadioGroup } from "@mui/material";
import moment from "moment-timezone";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../../reducer/globalReducer";
import Utils from "../../../Utils";
import ActionName from "../../../Utils/actionName";

export const getRfqJobs = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.jobs,
    `${query}`,
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        dispatch({
          type: "GET_RFQLIST",
          payload: {
            RfqList: data?.data || [],
            total: data?.total || 0,
            totalPage: data?.totalPage || 1,
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const getEmployeeJobs = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.emoloyeeListDashboard,
    `${query}`,
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        dispatch({
          type: "GET_EMPLOYEE_LIST_DASHBOARD",
          payload: {
            EmployeeList: data?.data || [],
            total: data?.total || 0,
            totalPage: data?.totalPage || 1,
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const jobsCounter = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.jobsCounter,
    `${query}`,
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        console.log(data, "JobCOunter");
        dispatch({
          type: "JOB_COUNTER",
          payload: {
            counterList: data?.data || [],
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const getMaintainance = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.jobs,
    `${query}`,
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        dispatch({
          type: "GET_MAINTAINANCELIST",
          payload: {
            MaintainanceList: data?.data || [],
            total: data?.total || 0,
            totalPage: data?.totalPage || 1,
          },
        });
      }
      localStorage.setItem("reload", "1");
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const getExternal = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.externalTradesList,
    `${query}`,
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        dispatch({
          type: "GET_EXTERNAL",
          payload: {
            ExternalList: data?.data || [],
            total: data?.total || 0,
            totalPage: data?.totalPage || 1,
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      dispatch(globalLoaderEnd());
    }
  );
};

export const getAllJobs = (query: any) => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }
  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.jobs,
    `${query}`,
    (res: any) => {
      const { data } = res;
      if (data.statusCode === Utils.Constants.api_success_code.success) {
        dispatch({
          type: ActionName.GET_ALLJOBS_LIST,
          payload: {
            AllJobsList: data?.data || [],
            total: data?.total || 0,
            totalPage: data?.totalPage || 1,
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      console.log(data, error);
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const assignJob = (
  userList: [],
  inviteUserList: [],
  id: string,
  jId: string
) => {

  let uniqueUserList = Array.from(new Set(userList));
  let uniqueInvitedUserList = Array.from(new Set(inviteUserList));
  return (dispatch: any) => {
    const dataToSend = {
      isTeamUp: localStorage.getItem("isTeamUp")==="true" ? true : false,
      reqJobId: id,
      jobId: jId,
      companyId: localStorage.getItem("user_id"),
      userList : uniqueUserList,
      inviteUserList,
    };
    Utils.API.putApiCall(
      Utils.endpoint.assignJobMs,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 201) {
          Utils.showAlert(1, data.message);
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const assignJobEx = (
  userList: [],
  inviteUserList: [],
  id: string,
  jId: string
) => {
  return (dispatch: any) => {
    const dataToSend = {
      // isTeamUp: true,
      companyJobId: id,
      jobId: jId,
      companyId: localStorage.getItem("user_id"),
      userList,
      inviteUserList,
    };
    Utils.API.putApiCall(
      Utils.endpoint.externalTradesAssign,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 201) {
          Utils.showAlert(1, data.message);
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const bookJob = (userId: string, id: string, jId: string, shift:string, date:string, trade:string) => {
  return (dispatch: any) => {
    const dataToSend = {
      companyJobId: id,
      jobId: jId,
      companyId: localStorage.getItem("user_id"),
      userId,
      shiftType: shift,
      dateTime: date,
      parentIndex: 0,
      childIndex: 1,
      trade:trade
    };
    Utils.API.postApiCall(
      Utils.endpoint.book,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        // if (data.statusCode === 200) {
        //   // Utils.showAlert(1, data.message);
        //   console.log("Success");
        // } else {
        //   Utils.showAlert(2, data.message);
        // }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const bookJobExternal = (userId: string, id: string,teamCompanyJobId:string, teamId:string, teamJobId:string, shift:string, date:string, trade:string) => {
  return (dispatch: any) => {
    const dataToSend = {
      companyJobId: id,
      companyId: localStorage.getItem("user_id"),
      teamCompanyJobId: teamCompanyJobId,
      teamId:teamId,
      teamJobId:teamJobId,
      userId,
      shiftType: shift,
      dateTime: date,
      parentIndex: 0,
      childIndex: 1,
      trade:trade
    };
    Utils.API.postApiCall(
      Utils.endpoint.externalTradeBooking,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        // if (data.statusCode === 200) {
        //   // Utils.showAlert(1, data.message);
        //   console.log("Success");
        // } else {
        //   Utils.showAlert(2, data.message);
        // }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const assignJobMs = (
  invitedEmployee: [],
  userList: [],
  id: string,
  jId: string,
  requestType: string,
) => {
  return (dispatch: any) => {
    const dataToSend = {
      reqJobId: id,
      jobId: jId,
      companyId: localStorage.getItem("user_id"),
      userList,
      requestType,
      inviteUserList: invitedEmployee,
      isTeamUp:false
    };
    Utils.API.putApiCall(
      Utils.endpoint.assignJobMs,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 201) {
          Utils.showAlert(1, data.message);
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const assignJobMsEx = (
  invitedEmployee: [],
  userList: [],
  id: string,
  jId: string,
  requestType: string
) => {
  return (dispatch: any) => {
    const dataToSend = {
      companyJobId: id,
      jobId: jId,
      companyId: localStorage.getItem("user_id"),
      userList,
      requestType,
      inviteUserList: invitedEmployee,
      // isTeamUp:false
    };
    Utils.API.putApiCall(
      Utils.endpoint.externalTradesAssign,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 201) {
          
          Utils.showAlert(1, data.message);
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const deleteBooking = (companyJobId: string) => {
  return (dispatch: any) => {
    const dataToSend = {
      companyJobId,
      companyId: localStorage.getItem("user_id"),
    };
    Utils.API.postApiCall(
      Utils.endpoint.bookingDelete,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const externalDeleteBooking = (companyJobId: string) => {
  return (dispatch: any) => {
    const dataToSend = {
      companyJobId,
      companyId: localStorage.getItem("user_id"),
    };
    Utils.API.postApiCall(
      Utils.endpoint.externalBookingDelete,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          window.location.reload();
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

