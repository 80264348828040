const DiscountClientListReducer = (
  state = {
    clientList: [],
    clientDetail: null,
  },
  action: any
) => {
  switch (action.type) {
    case "CLIENT_LIST_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    case "CLIENT_DETAIL_SUCCESS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const getStandardRatesState = (state: any) => state.StandardRatesReducer;

const getDiscountClientListState = (state: any) =>
  state.DiscountClientListReducer;

export {
  getStandardRatesState,
  DiscountClientListReducer,
  getDiscountClientListState,
};
