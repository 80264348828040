import { Box, Paper } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      "& > :not(style)": {
        width: "100%",
        minHeight: "80vh",
        borderRadius: "unset",
        backgroundColor: "var(--mobileBackgroundColor)",
      },
    },
    body: {
      minHeight: "70vh",
    },
  })
);

const PaperBodyMobile = ({ children }: any) => {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.root}>
        <Paper elevation={0}>
          <Box className={classes.body}>{children}</Box>
        </Paper>
      </Box>
    </div>
  );
};

export default PaperBodyMobile;
