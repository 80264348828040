import {
  Box,
  inputAdornmentClasses,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useField } from "formik";

type InputFieldProps = {
  name: string;
  label: string;
  placeholder: string;
  errors?: any;
  disabled?: boolean;
  onChange?: any;
  minDate?: any;
  maxDate?: any;
  fullWidth?: boolean;
};

const DatePickerField = ({
  name,
  label,
  placeholder,
  errors,
  disabled,
  fullWidth,
  ...rest
}: InputFieldProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
  });

  const handleChange = (newValue: Date | null) => {
    setTouched(true);
    setValue(moment(newValue).toISOString());
  };

  return (
    <Box width="100%">
      <InputLabel
        sx={{
          color: touched && error ? "#EB5757" : "var(--clientColor)",
        }}
        htmlFor={name}
      >
        {label}
      </InputLabel>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={handleChange}
          disabled={Boolean(disabled)}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                marginTop: ".7rem",
                background: "#fff",
                "& input": {
                  padding: ".9rem .7rem",
                  background: "#fff",
                  "&:disabled": {
                    background: "#fff",
                  },
                },
                "& fieldset": {
                  borderRadius: 0,
                  border:
                    touched && error
                      ? "1px solid #EB5757 !important"
                      : "1px solid #bdbdbd !important",
                },
                // [`& .${inputAdornmentClasses.root}`]: {
                //   background: "#fff",
                // },
              }}
              fullWidth={Boolean(fullWidth)}
            />
          )}
        />
        {/* <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerField;
