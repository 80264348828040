import { FormikErrors, FormikProps, FormikTouched } from "formik";
import Utils from "../../../Utils";
import COLORS from "../../../Utils/color";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

export const getInitialValues = (isEdit: boolean, obj: any) => ({
  title: isEdit ? obj.title : "", 
  description: isEdit ? obj.description : "",
});

export const checkErrors = (
  errors: FormikErrors<{ [key: string]: any }>,
  touched: FormikTouched<{ [key: string]: any }>
) => {
  if (Object.keys(errors).length === 0 && Object.keys(touched).length > 0)
    return 1;
  return 0;
};

export const finalSubmit = (
  index: number,
  values: { [key: string]: string },
  draggableForms: { [key: string]: any }[],
  errorsForms: boolean[],
  dispatch: Function
) => {
  let localDraggableForms = [...draggableForms];
  let localErrorsForms = [...errorsForms];
  localDraggableForms[index].field = { ...values };
  localErrorsForms[index] = true;
  dispatch({
    type: SCRATCH_TEMPLATE,
    payload: {
      draggableForms: localDraggableForms,
      errorsForms: localErrorsForms,
    },
  });
};

export const setFormError = (
  dispatch: Function,
  errors: boolean[],
  index: number
) => {
  let localErrorsForms = [...errors];
  localErrorsForms[index] = false;
  dispatch({
    type: SCRATCH_TEMPLATE,
    payload: {
      errorsForms: localErrorsForms,
    },
  });
};

/* GET PROPS */

export const getQuillProps = (
  name: string,
  isActive: boolean,
  setFieldValue: Function,
  setFieldTouched: Function,
  values: string
) => {
  return {
    className: `quillClass ${!isActive ? " remove_toolbar" : ""}`,
    placeholder: "(Placeholder Text)",
    onChange: (htmlString: string) => setFieldValue(name, htmlString),
    onBlur: () => setFieldTouched(name, true, true),
    onFocus: () => setFieldTouched("description", true, false),
    value: values,
    theme: "snow",
    disabled: !isActive,
  };
};

export const getRemoveButtonProps = (errorsForms:boolean[] , draggableForms: any, dispatch:Function, index:number) => {
  return {
    width: 78,
    height: 35,
    background: COLORS.backgroundPrimary,
    color: COLORS.darkSecondary,
    border: `1px solid ${COLORS.backgroundSecondary}`,
    onClick:() => {
      let localItems = [...draggableForms];
      let localError = [...errorsForms];
      localError.splice(index,1);
      localItems.splice(index, 1);
      console.log("draggable", localItems)
      dispatch({
        type: SCRATCH_TEMPLATE, 
        payload: {
          draggableForms: [...localItems], 
          errorsForms: [...localError],
        },
      });
    }
  };
};

export const getDoneButtonProps = () => {
  return {
    width: 66,
    height: 35,
    background: COLORS.themeSecondary,
    color: COLORS.backgroundDefault,
    border: "none",
    type: "submit",
  };
};

export const getFormikProps = (
  props: FormikProps<any>,
  provided: any,
  isNew: boolean,
  index: number,
  isActive: boolean,
  setIsActive: Function
) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = props;
  return {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
    provided,
    isNew,
    index,
    isActive,
    setIsActive,
  };
};
