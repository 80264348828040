import { Typography, Stack } from '@mui/material';

export default function PreciseRating({ nameProduct, address }: any) {
  return (
    <Stack spacing={1}>
      <Typography variant="overline">
        {nameProduct.length < 20
          ? nameProduct
          : `${nameProduct.slice(0, 20)} ...`}
      </Typography>
      <Typography variant="h6">{address}</Typography>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '@media (max-width: 600px)': {
            display: 'flex',
            justifyContent: 'unset',
          },
        }}
      >
        <Rating
          readOnly
        />
      </Box> */}
    </Stack>
  );
}
