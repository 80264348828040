import React from "react";
import { useField } from "formik";
import {
  Box,
  TextField,
  TextFieldProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

type CustomTextFieldProps = TextFieldProps & {
  signUpPasswordField?: boolean;
  onChange?: any;
};

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`&.${tooltipClasses.popper}`]: {
    width: "auto !important",
    maxWidth: "auto !important",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.3rem",
    border: "1px solid #dadde9",
    // width:"200px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      background: "#fff",
      border: "1px solid #dadde9",
    },
  },
  [theme.breakpoints.up("md")]: {
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        background: "#fff",
        border: "1px solid #dadde9",
      },
      // transform: "translate(25px, 0px) !important",
    },
  },
}));

const InputField = ({
  name,
  signUpPasswordField,
  ...rest
}: CustomTextFieldProps) => {
  const [field, meta] = useField(name as string);
  const theme = useTheme();

  // console.log(name,meta);

  const attributes: TextFieldProps = {
    ...rest,
    ...field,
    fullWidth: true,
    variant: "outlined",
    value: field.value || "",

    // autoComplete: "off"
  };

  if (meta && meta.touched && meta.error) {
    attributes.error = true;
    attributes.helperText = meta.error;
  }

  if (signUpPasswordField) {
    return (
      <CustomToolTip
        // open={true}
        title={
          <Box>
            <Typography
              gutterBottom
              sx={{
                color: "#333333",
                fontSize: "12px",
                fontWeight: "500",
                marginBottom: "2px",
              }}
            >
              Passwords must have:
            </Typography>
            <ul style={{ padding: "1rem" }}>
              <li>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  At least 8 characters
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  1 number
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  1 lowercase letter
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  1 uppercase letter
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  1 special character
                </span>
              </li>
            </ul>
          </Box>
        }
        // arrow
        sx={{
          width: "100px",
        }}
        placement="top-end"
      >
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                boxShadow: "-2px 0px 23px -2px rgba(0,0,0,0.33)",
                border: "1px solid #000000",
              },
            },
            [`& fieldset`]: {
              borderRadius: 0,
            },

            "& input": {
              height: "15px",
              [theme.breakpoints.down(1024)]: {
                height: "25px",
              },

              "&::placeholder": {},
            },
          }}
          {...attributes}
        />
      </CustomToolTip>
    );
  }

  return (
    <div>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              boxShadow: "-2px 0px 23px -2px rgba(0,0,0,0.33)",
              border: "1px solid #000000",
              // -webkit-box-shadow: -2px 0px 23px -2px rgba(0,0,0,0.33);
              // -moz-box-shadow: -2px 0px 23px -2px rgba(0,0,0,0.33);
            },
          },
          [`& fieldset`]: {
            borderRadius: 0,
          },

          "& input": {
            height: "15px",
            [theme.breakpoints.down(1024)]: {
              height: "25px",
            },

            "&::placeholder": {},
          },
        }}
        {...attributes}
      />
    </div>
  );
};

export default InputField;
