import React from "react";
import { useEffect } from "react";
import ModalBox from "../../../../../components/modal/modalBox";
import AssignModal from "./assignEmployeeList";
import {
  useTheme,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  styled,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../settings/team/style";
import Utils from "../../../../../Utils";
import LocalImages from "../../../../../Utils/localImages";
import { capitalizeFirstLetter } from "../../../../../Utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getEmployeeJobListReducerState,
  assignJobsReduserState,
  assignRequestTypeState,
} from "../../reducer";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material";
import { useClassStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { getEmployeeJobs } from "../../action";
import NormalButton from "../../../../../components/button/NormalButton";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { assignJobMs, deleteBooking } from "../../action";
import moment from "moment-timezone";
import { prototype } from "events";

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  fontSize: ".875rem",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
  fontWeight: 400,
  fontSize: ".75rem",
});
const { circle } = LocalImages;

type AssignEmployeeProps = {
  handleClose: () => void;
  modalContainerStyle: string;
  data?: any;
  userId?: any;
};

type ModalHeaderProps = {
  handleClose: () => void;
  data?: any;
};

const ModalHeader = ({ handleClose, data }: ModalHeaderProps) => {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();

  const remove = () => {
    dispatch(deleteBooking(data._id));
    // history?.push(Utils.Pathname.ViewAllRequest);
    handleClose();
    // window.location.reload();
  };

  return (
    <>
      <div className={classes.modalHeader}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "16px",
            color: "#4F4F4F",
          }}
        >
          Assign Job
        </Typography>

        <CloseIcon onClick={remove} sx={{ cursor: "pointer" }}></CloseIcon>
      </div>
    </>
  );
};

const MyChip: React.FC<ChipProps> = (props) => {
  const classes = useClassStyles();
  return <Chip sx={{ borderRadius: "0px" }} {...props} component={"div"} />;
};

function AssignEmployeeMSR({
  handleClose,
  modalContainerStyle,
  data,
}: AssignEmployeeProps) {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();
  const history = useHistory();
  const userList: any = [];
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [jobData, setJobData] = React.useState<any>(null);
  const [jobData2, setJobData2] = React.useState<any>(null);
  const [bookData, setBookData] = React.useState<any>(null);
  const [indexData, setIndexData] = React.useState<any>(null);
  const [tradeIndex, setTradeIndex] = React.useState<number>(0);
  const [dayIndex, setDayIndex] = React.useState<number>(0);
  const { EmployeeList } = useSelector(getEmployeeJobListReducerState);
  const { userId, invitedEmployee } = useSelector(assignJobsReduserState);
  const { recurringList,sunday, monday, tuesday, wednesday, thursday, friday, saturday } = useSelector(assignRequestTypeState);
  const searchList: any = [];
  

  EmployeeList &&
    EmployeeList.map((val: any) => {
      searchList.push({
        empId: val?._id,
        title: val.name,
        userTrade: val.userTrades[0],
        profile: val?.profilePicture,
      });
    });

  useEffect(() => {
    const tradeTypeData =
      data?.job_detail?.tradeType.length > 0 &&
      data?.job_detail?.tradeType.map((el: any) => {
        return el?.type;
      });
    const tradeTypeString = `&tradeType=${tradeTypeData
      ?.map((i: any) => {
        return i;
      })
      .join("&tradeType=")}`;
  }, []);

    invitedEmployee &&
    invitedEmployee?.map((item: any, index: number) => {
      userList.push(item?.userId);
    });
    
  const assignJobs = () => {
    dispatch(
      assignJobMs(
        invitedEmployee,
        userList,
        data?._id,
        data?.jobId,
        data?.requestType
      )
    );
    // console.log(userList, data._id, data.jobId, invitedEmployee, "DatatoSend")
    handleClose();
    // window.location.reload();
  };
  const underDev = () => {
    history.push(Utils.Pathname.NAVBAR.AsCompany.roster);
  };

  console.log(recurringList, "Data");
  return (
    <>
      {
        <div
          className={modalContainerStyle}
          style={{
            minHeight: "500px",
            minWidth: "536px",
            overflow: "auto",
          }}
        >
          <ModalHeader handleClose={handleClose} data={data} />
                  <>
                  
                  {monday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Monday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {monday?.length > 0
                                  ? monday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(1);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {tuesday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Tuesday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tuesday?.length > 0
                                  ? tuesday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(2);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {wednesday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Wednesday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {wednesday?.length > 0
                                  ? wednesday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(3);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {thursday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Thursday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {thursday?.length > 0
                                  ? thursday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(4);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {friday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Friday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {friday?.length > 0
                                  ? friday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(5);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {saturday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Saturday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {saturday?.length > 0
                                  ? saturday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                              {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(6);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                      {sunday?.length?
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            borderLeft: "30px solid white",
                            borderRight: "30px solid white",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          Sunday
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer sx={{ maxHeight: "350px" }}>
                            <Table
                              sx={{
                                width: 480,

                                marginLeft: "30px",
                                [`& .${tableCellClasses.root}`]: {
                                  border: "1px solid #E0E0E0",
                                  padding: "16px 3px",
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Trade Needed
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4f4f4f",
                                      }}
                                    >
                                      Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                      }}
                                    >
                                      Employee
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sunday?.length > 0
                                  ? sunday?.map((el: any, i: number) => {
                                    console.log(el?.employee)
                                      return (
                                        <>
                                          <TableRow
                                            key={`row-${i}`}
                                            sx={{
                                              "&:hover": {
                                                background: "#c0e8f2",
                                              },
                                            }}
                                          >
                                            <Tooltip title={el?.tradeType?.replaceAll("_", " ")}>
                                              <TableCell>
                                                {capitalizeFirstLetter(
                                                  el?.tradeType?.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ).slice(0, 11)}
                                              </TableCell>
                                            </Tooltip>

                                            <TableCell>
                                              {moment(
                                                el?.date
                                              ).subtract(6,"hours").format("DD/MM/YYYY")}
                                            </TableCell>

                                            <TableCell>
                                            {`${el?.time.substr(0, 2)} ${el?.time.substr(6, 5)}${el?.time.substr(5, 3)}`}
                                            </TableCell>
                                            <TableCell
                                              onClick={() => {
                                                setJobData(el?.tradeType);
                                                setJobData2(data);
                                                setBookData(el);
                                                setDayIndex(0);
                                                // setIndexData(index);
                                                setTradeIndex(i);
                                                setModalOpen1(true);
                                              }}
                                            >
                                              {el?.employee== null ? (
                                                <ArrowDropDownIcon
                                                  sx={{ marginLeft: "82%" }}
                                                />
                                              ) :(
                                                <Box
                                                  sx={{
                                                    width: "60%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                      borderRadius: "50px",
                                                    }}
                                                    src={
                                                      el?.employee?.profile
                                                        ? el?.employee
                                                            ?.profile
                                                        : circle
                                                    }
                                                    srcSet={
                                                      el.employee?.profile
                                                    }
                                                    alt=""
                                                  />
                                                  <Tooltip
                                                    title={el?.employee?.title}
                                                  >
                                                    <Typography
                                                      sx={{ mt: "3px" }}
                                                    >
                                                      {el?.employee?.title.slice(
                                                        0,
                                                        8
                                                      )}
                                                    </Typography>
                                                  </Tooltip>
                                                </Box>
                                              ) }
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>:""}
                  
                  </>

          <div
            style={{
              width: "100%",
              display: "flex",
              // backgroundColor: "#fff",
              marginBottom:"2%",
              flexDirection: "row",
              paddingLeft: "5%",
              paddingRight: "5%",
              [theme.breakpoints.down(600)]: {
                backgroundColor:"#E5E5E5",
              },
            }}
          >
            <NormalButton
              sx={{
                width: "49%",
                height: "48px",
                // float: "right",
                mt: "2%",
                mr: "1%",
                ml: "1%",
                backgroundColor: "#FFFFFF",
                color: "#0DA4CE",
                border: "1px solid #E0E0E0",
                borderRadius: "0px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
                [theme.breakpoints.down(600)]: {
                  marginRight: "-30px",
                },
              }}
              onClick={underDev}
            >
              <img
                src={LocalImages.addIcon}
                style={{ marginRight: "5px" }}
                alt="add"
              />
              Team Up
            </NormalButton>
              <NormalButton
                sx={{
                  width: "50%",
                  height: "48px",
                  float: "right",
                  mt: "2%",
                  backgroundColor: "#0DA4CE",
                  color: "#fff",
                  borderRadius: "0px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#0DA4CE",
                  },
                }}
                onClick={assignJobs}
              >
                Assign
              </NormalButton>
            
              {/* <NormalButton
                disabled
                sx={{
                  width: "50%",
                  height: "48px",
                  float: "right",
                  mt: "2%",
                  backgroundColor: "#0DA4CE",
                  color: "#fff",
                  borderRadius: "0px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#0DA4CE",
                  },
                  "&:disabled": {
                    backgroundColor: "#E5E5E5",
                  },
                }}
                onClick={assignJobs}
              >
                Assign
              </NormalButton> */}

            <ModalBox
              isOpen={modalOpen1}
              handleClose={() => setModalOpen1(false)}
              modalStyle={"deletemodalStyle"}
              children={
                <AssignModal.AssignEmployeeList
                  data={jobData}
                  data2={jobData2}
                  bookingData={bookData}
                  tradeIndex={tradeIndex}
                  dayIndex={dayIndex}
                  // table={tableDataMs}
                  index={indexData}
                  handleClose={() => setModalOpen1(false)}
                  modalContainerStyle={classes.deletemodalStyle}
                ></AssignModal.AssignEmployeeList>
              }
            />
          </div>
        </div>
      }
    </>
  );
}

const TeamModal = {
  AssignEmployeeMSR,
};

export default TeamModal;
