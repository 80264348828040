import { ScratchTemplateModel } from "../../model";
import { ActionProps } from "./prototypes";
import Utils from "../../Utils";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

const scratchTemplateReducer = (
  state: ScratchTemplateModel = new ScratchTemplateModel(),
  action: ActionProps
) => {
  switch (action.type) {
    case SCRATCH_TEMPLATE:
      return {
        ...state, 
        ...action.payload,
      };
    default:
      return { ...state };
  }
};


export default scratchTemplateReducer;