import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModel } from "../model";

const useRedux = () => {
  const dispatch = useDispatch();
  const reducers: ReducersModel = useSelector((state: ReducersModel) => state);
  return { dispatch, reducers };
};

export default useRedux;
