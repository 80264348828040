import { JobDetailsModel, CommentsModel } from '../../../model';
import Utils from '../../../Utils';

export const jobDetailsModelReducer = (
  state: JobDetailsModel = new JobDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.JOB_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const commentsModelReducer = (
  state: CommentsModel = new CommentsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_COMMENTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
