import React, { useState } from "react";
import { useEffect } from "react";
import ModalBox from "../../../../../components/modal/modalBox";
import AssignModal from "./assignEmployeeList";
// import MultiInput from "../../../../components/inputfield/MultiInput";
import {
  useTheme,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useStyles } from "../../../settings/team/style";
import Utils from "../../../../../Utils";
import LocalImages from "../../../../../Utils/localImages";
// import { deleteTeamMember } from "./action";
import { capitalizeFirstLetter } from "../../../../../Utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getEmployeeJobListReducerState,
  assignJobsReduserState,
  assignRequestTypeState,
} from "../../reducer";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material";
import { useClassStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { getEmployeeJobs } from "../../action";
import NormalButton from "../../../../../components/button/NormalButton";
import { assignJobMs, deleteBooking } from "../../action";
import { getDatesInRange } from "../../../../../Utils/commonFunctions";
import moment from "moment-timezone";
import { ReducersModel } from "../../../../../model";
import { getExternalTradeDetails } from "../../../../sp/spBuildsQuotes/action";

const { circle } = LocalImages;

type AssignEmployeeProps = {
  handleClose: () => void;
  modalContainerStyle: string;
  data?: any;
  userId?: any;
};

type ModalHeaderProps = {
  handleClose: () => void;
  data?: any;
};

const ModalHeader = ({ handleClose, data }: ModalHeaderProps) => {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();
  const [count,setCount]=useState(0);
  const { nonRecurringListMs } = useSelector(assignJobsReduserState);
  const remove = () => {
    dispatch(deleteBooking(data._id));
    // history?.push(Utils.Pathname.ViewAllRequest);
    handleClose();
    // window.location.reload();
  };

  console.log("dfiufiofusiofsif", data);

  return (
    <>
      <div className={classes.modalHeader}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "16px",
            color: "#4F4F4F",
          }}
        >
          Assign Job
        </Typography>

        <CloseIcon onClick={remove} sx={{ cursor: "pointer" }}></CloseIcon>
      </div>
    </>
  );
};

function AssignEmployeeMS({
  handleClose,
  modalContainerStyle,
  data,
}: AssignEmployeeProps) {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const dispatch = useDispatch();
  const history = useHistory();
  const userList: any = [];
  const [count,setCount]= React.useState<number>(0);
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [jobData, setJobData] = React.useState<any>(null);
  const [jobData2, setJobData2] = React.useState<any>(null);
  const [bookData, setBookData] = React.useState<any>(null);
  const [indexData, setIndexData] = React.useState<any>(null);
  const { EmployeeList } = useSelector(getEmployeeJobListReducerState);
  const [tradeIndex, setTradeIndex] = React.useState<number>(0);
  const { userId, invitedEmployee, nonRecurringListMs } = useSelector(
    assignJobsReduserState
  );

  const { nonRecurringList } = useSelector(assignRequestTypeState);
  const { externalTradeDetails } = useSelector(
    (state: ReducersModel) => state.externalTradeDetailsReducer
  );

  useEffect(() => {
    const tradeTypeData =
      data?.job_detail?.tradeType.length > 0 &&
      data?.job_detail?.tradeType.map((el: any) => {
        return el?.type;
      });
    const tradeTypeString = `&tradeType=${tradeTypeData
      ?.map((i: any) => {
        return i;
      })
      .join("&tradeType=")}`;

    // dispatch(
    //   getEmployeeJobs(
    //     `?companyId=${localStorage.getItem("user_id")}${
    //       tradeTypeString.length > 0 ? tradeTypeString : ""
    //     }`
    //   )
    // );
    localStorage.setItem("_id", data?._id);
    localStorage.setItem("jobId", data?.jobId);
    localStorage.setItem("isTeamUp",data?.isTeamUp);
  }, []);

  useEffect(() => {
    
    // setJobId(JobDetailsData[0]?.jobId);
    dispatch(getExternalTradeDetails(localStorage.getItem("jobId"),localStorage.getItem("_id")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  externalTradeDetails &&
  externalTradeDetails?.map((value: any) => {
    if (value?.userDetail[0]) {
      invitedEmployee.push({
        userId: value?.userDetail[0]?._id,
        trade: value?.trade,
        isExternal: value?.isExternal,
        dateTime:value?.dateTime,
        shiftType: value?.shiftType
      });
    }
  });

  invitedEmployee &&
    invitedEmployee?.map((item: any, index: number) => {
      userList.push(item?.userId);
    });



  const assignJobs = () => {
    dispatch(
      assignJobMs(
        invitedEmployee,
        userList,
        data?._id,
        data?.jobId,
        data?.requestType,
        
      )
    );
    handleClose();
  };
  const MsRoute = () => {
    setCount(1);
    history.push(Utils.Pathname.MaintenanceServices, data) 
  };
  console.log(nonRecurringList,"nonRecurringList")
  return (
    <div
      className={modalContainerStyle}
      style={{
        minHeight: "500px",
        minWidth: !isMobile ? "536px" : "400px",
        
      }}
    >
      <ModalHeader handleClose={handleClose} data={data} />
      <TableContainer sx={{ maxHeight: "350px" }}>
        <Table
          sx={{
            width: 480,

            marginLeft: "30px",
            [`& .${tableCellClasses.root}`]: {
              border: "1px solid #E0E0E0",
              padding: "16px 3px",
            },
            
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4f4f4f",
                  }}
                >
                  Trade Needed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4f4f4f",
                  }}
                >
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4f4f4f",
                  }}
                >
                  Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4F4F4F",
                  }}
                >
                  Employee
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nonRecurringList?.length > 0
              ? nonRecurringList.map((item: any, index: number) => {
                  return (
                    <>
                      <TableRow
                        key={`row-${index}`}
                        sx={{
                          "&:hover": {
                            background: "#c0e8f2",
                          },
                        }}
                      >
                        <Tooltip title={item?.tradeType?.replaceAll("_", " ")}>
                          <TableCell>
                            {capitalizeFirstLetter(
                              item?.tradeType?.replaceAll("_", " ")
                            ).slice(0, 17)}
                          </TableCell>
                        </Tooltip>

                        <TableCell>
                          {moment(new Date(item?.dateTime)).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell>
                          {`${item?.time?.substr(0, 2)} ${item?.time?.substr(6, 5)}${item?.time?.substr(14)}`}
                        </TableCell>
                        {!data?.isTeamUp === true ? (
                        <TableCell
                          onClick={() => {
                            setJobData(item?.tradeType);
                            setJobData2(data);
                            setBookData(item);
                            setIndexData(index);
                            setTradeIndex(index);
                            setModalOpen1(true);
                            data?.allDateArray[0]?.date?.shift()
                            console.log(data, "AllDateArray")
                          }}
                        >
                          {item.employee ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={
                                    item.employee?.profile
                                      ? item.employee?.profile
                                      : circle
                                  }
                                  srcSet={item.employee?.profile}
                                  alt=""
                                />

                                <Tooltip title={item.employee?.title}>
                                  <Typography sx={{ ml:"18px" , mt: "3px", width: "100%" }}>
                                    {`${item.employee?.title.slice(
                                          0,
                                          10
                                        )}...`}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ) : (
                              <>
                                <ArrowDropDownIcon sx={{ marginLeft: "80%" }} />
                              </>
                            )}
                        </TableCell>):(
                          <TableCell
                          // onClick={() => {
                          //   setJobData(item.trade);
                          //   setJobData2(data);
                          //   setModalOpen1(true);
                          //   setTradeIndex(index);
                          // }}
                          >
                            {externalTradeDetails[index]?.userDetail[0] ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  style={{
                                    width: "35px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={
                                    item.employee?.profile
                                      ? item.employee?.profile
                                      : circle
                                  }
                                  srcSet={item.employee?.profile}
                                  alt=""
                                />

                                <Tooltip
                                  title={
                                    externalTradeDetails[index]?.userDetail[0]
                                      ?.name
                                  }
                                >
                                  <Typography sx={{ mt: "3px", width: "100%" }}>
                                    {externalTradeDetails[index]?.userDetail[0]
                                      ?.name.length > 5
                                      ? externalTradeDetails[index]
                                          ?.userDetail[0]?.name
                                      : `${externalTradeDetails[
                                          index
                                        ]?.userDetail[0]?.name.slice(0, 5)}...`}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ) : (
                              <>
                                <ArrowDropDownIcon sx={{ marginLeft: "80%" }} />
                              </>
                            )}
                          </TableCell>
                        )}
                        
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "5%",
          paddingRight: "5%",
          [theme.breakpoints.down(600)]: {
            backgroundColor: "#E5E5E5",
          },
        }}
      >
        {invitedEmployee?.length === nonRecurringList?.length ||
        invitedEmployee?.length > nonRecurringList?.length || data?.isTeamUp === true ?

        <NormalButton
          disabled
          sx={{
            width: "49%",
            height: "48px",
            // float: "right",
            mt: "2%",
            mr: "1%",
            ml: "1%",
            backgroundColor: "#FFFFFF",
            color: "#0DA4CE",
            border: "1px solid #E0E0E0",
            borderRadius: "0px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            [theme.breakpoints.down(600)]: {
              marginRight: "-30px",
            },
          }}
          onClick={() => history.push(Utils.Pathname.MaintenanceServices, data)}
        >
          <img
            src={LocalImages.addIcon}
            style={{ marginRight: "8px" }}
            alt="add"
          />
          Team Up
        </NormalButton> : <NormalButton
          sx={{
            width: "49%",
            height: "48px",
            padding:"0px 35px",
            // float: "right",
            mt: "2%",
            mr: "1%",
            ml: "1%",
            backgroundColor: "#FFFFFF",
            color: "#0DA4CE",
            border: "1px solid #E0E0E0",
            borderRadius: "0px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            [theme.breakpoints.down(600)]: {
              marginRight: "-30px",
            },
          }}
          onClick={() => MsRoute()}
        >
          <img
            src={LocalImages.addIcon}
            style={{ marginRight: "8px" }}
            alt="add"
          />
          Team Up
        </NormalButton>}
        {invitedEmployee?.length === nonRecurringList?.length ||
        invitedEmployee?.length > nonRecurringList?.length || externalTradeDetails?.length>0 ? (
          <NormalButton
            sx={{
              width: "50%",
              height: "48px",
              float: "right",
              mt: "2%",
              backgroundColor: "#0DA4CE",
              color: "#fff",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0DA4CE",
              },
            }}
            onClick={assignJobs}
          >
            Assign
          </NormalButton>
        ) : (
          <NormalButton
            disabled
            sx={{
              width: "50%",
              height: "48px",
              float: "right",
              mt: "2%",
              backgroundColor: "#0DA4CE",
              color: "#fff",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0DA4CE",
              },
              "&:disabled": {
                backgroundColor: "#E5E5E5",
              },
            }}
            onClick={assignJobs}
          >
            Assign
          </NormalButton>
        )}
        <ModalBox
          isOpen={modalOpen1}
          handleClose={() => setModalOpen1(false)}
          modalStyle={"deletemodalStyle"}
          children={
            <AssignModal.AssignEmployeeList
              tradeIndex={tradeIndex}
              data={jobData}
              data2={jobData2}
              bookingData={bookData}
              table={nonRecurringListMs}
              index={indexData}
              handleClose={() => setModalOpen1(false)}
              modalContainerStyle={classes.deletemodalStyle}
            ></AssignModal.AssignEmployeeList>
          }
        />
      </div>
    </div>
  );
}

const TeamModal = {
  AssignEmployeeMS,
};

export default TeamModal;
