import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import DateTimePicker from "../../../components/DataTimePicker";
import TextfieldWrapper from "../../../components/Textfield";
import { companyJobDetails } from "../spBuildsQuotes/action";
import MobileResponsiveQuote from "./mobileResponsive";
import { SubmitSelectTemplate } from "./action";
import { useStyles } from "./selectTemplateCss";
import { ReducersModel } from "../../../model";
import { theme } from "../../../theme/theme";
import PaperBody from "../PaperBody";
import Schema from "../../../schema";
import DragTable from "./DragTable";
import Utils from "../../../Utils";
import Title from "../Title";

interface LocationState {
  pathname: string;
  id: string;
  quoteSelection: string;
  history: any;
}
export interface INITIAL_FORM_FORMIK_VALUE {
  quoteSelection: string;
  date: string;
  validUntil: string;
  quoteNumber: string;
  title: string;
  summary: string;
  dragData: {
    description: string;
    quantity: string;
    unitPrice: string;
    discount: string;
    gst: string;
    amountAud: string;
  }[];
  isDraft: boolean;
  notes: string;
  id: string;
  comments: string;
}

const INITIAL_FORM_STATE = {
  quoteSelection: "",
  date: moment().format("YYYY-MM-DD"),
  validUntil: "",
  quoteNumber: "",
  title: "",
  summary: "",
  dragData: [
    {
      description: "",
      quantity: "",
      unitPrice: "",
      discount: "0",
      gst: "10",
      amountAud: "",
    },
  ],
  isDraft: false,
  notes: "Thank You !",
  id: "",
  comments: "",
};

const SelectTemplate = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const themeBreak = useTheme();
  const mobileView = useMediaQuery(themeBreak.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [downloadIcon, setDownloadIcon] = useState<boolean>(false);

  const [initialValue, setInitialValue] = useState<INITIAL_FORM_FORMIK_VALUE>(
    INITIAL_FORM_STATE
  );

  useEffect(() => {
    dispatch(companyJobDetails(location.state.id));
  }, [dispatch, location.state.id]);

  const {
    JobDetailsData,
    itemCosting,
    quoteValidDate,
    title,
    summary,
    draft,
    notes,
    comments,
    _id,
    quoteNumber,
    downloadUrl,
  } = useSelector((state: ReducersModel) => state.jobDetailsModelReducer);
  

  useEffect(() => {
    if (quoteValidDate || summary || title || itemCosting) {
      setInitialValue({
        quoteSelection: location.state.quoteSelection,
        date: moment().format("YYYY-MM-DD"),
        validUntil: moment(quoteValidDate).subtract(1,"days").format("YYYY-MM-DD"),
        quoteNumber: quoteNumber,
        title: title,
        summary: summary,
        dragData: itemCosting,
        isDraft: draft,
        notes: notes,
        id: _id,
        comments: comments,
      });
    }
  }, [
    comments,
    draft,
    _id,
    itemCosting,
    notes,
    quoteNumber,
    quoteValidDate,
    summary,
    title,
    location.state.quoteSelection,
  ]);

  useEffect(() => {
    if (quoteValidDate || summary || title || itemCosting) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.SELECT_TEMPLATE_MODEL,
      payload: {
        data: initialValue,
        id: location.state.id,
      },
    });
  }, [location.state.id, dispatch, initialValue]);

  const handleSaveDraft = (values: INITIAL_FORM_FORMIK_VALUE) => {
    setDownloadIcon(true);
    const newAllValues = { ...values, isDraft: true };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        location.state.id,
        location.state.quoteSelection,
        history
      )
    );
  };

  const handleClickPreview = () => {
    setDownloadIcon(true);
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
      state: { id: location.state.id },
    });
  };

  const handleCancelButton = () => {
    setInitialValue(INITIAL_FORM_STATE);
  };

  const handleSubmit = (value: INITIAL_FORM_FORMIK_VALUE) => {
    setDownloadIcon(true);
    const newAllValues = { ...value, isDraft: true };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        location.state.id,
        location.state.quoteSelection,
        history
      )
    );
  };
console.log(JobDetailsData[0]?.isDraft, "isDraft")
  return (
    <Fragment>
      {!mobileView ? (
        <ThemeProvider theme={theme}>
          <Formik
            initialValues={{
              ...initialValue,
            }}
            validationSchema={Schema.SelectTemplateSchema}
            onSubmit={(values: INITIAL_FORM_FORMIK_VALUE) => {
              handleSubmit(values);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, isValid, dirty }) => (
              <Form>
                {JobDetailsData.length > 0 &&
                  JobDetailsData.map((item: any) => {
                    return (
                      <Title>
                        <Typography variant="h4" color="text.secondary">
                          Build Quote
                        </Typography>
                        <Box className={classes.titleChildren}>
                          {item.isDraft || downloadIcon ? (
                            <Box className={classes.titleIcons}>
                              <Button onClick={handleClickPreview}>
                                <VisibilityIcon
                                  sx={{
                                    color: "var(--primaryBlueColor)",
                                    marginTop: "-6px",
                                  }}
                                />
                              </Button>
                            </Box>
                          ) : (
                            <Box className={classes.titleIcons}>
                              <Button>
                                <VisibilityIcon
                                  sx={{
                                    color: "var(--modalHeadingColor)",
                                    marginTop: "-6px",
                                  }}
                                />
                              </Button>
                            </Box>
                          )}
                          <Box className={classes.titleIcons}>
                            {item.isDraft || downloadIcon || downloadIcon ? (
                              <Link
                                href={downloadUrl}
                                underline="none"
                                color="secondary.contrastText"
                              >
                                <Button>
                                  <FileDownloadIcon
                                    sx={{
                                      color: "var(--primaryBlueColor)",
                                      marginTop: "-6px",
                                    }}
                                  />
                                </Button>
                              </Link>
                            ) : (
                              <Button>
                                <FileDownloadIcon
                                  sx={{
                                    color: "var(--modalHeadingColor)",
                                    marginTop: "-6px",
                                  }}
                                />
                              </Button>
                            )}
                          </Box>
                          <Button
                          type="submit"
                            sx={{
                              size: "small",
                              borderRadius: "0px",
                              color: "var(--white)",
                            }}
                            variant="contained"
                            onClick={() => handleSaveDraft(values)}
                          >
                            Save as Draft
                          </Button>
                        </Box>
                      </Title>
                    );
                  })}

                <PaperBody>
                  <Box className={classes.gridMuiroot}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={3.5}>
                        <Typography sx={{ padding: "3px 0px" }} variant="h5">
                          Quote for
                        </Typography>

                        <TextfieldWrapper
                          name="quoteSelection"
                          className={classes.textField}
                          size="small"
                          value={
                            JobDetailsData.length > 0 &&
                            JobDetailsData.map(
                              (item: any, idx: number) =>
                                item?.job_detail?.clientDetail?.businessName 
                            )
                          }
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={2.5}></Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5">Date</Typography>
                        <DateTimePicker
                          name="date"
                          size="small"
                          className={classes.textField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5">Valid Until</Typography>
                        {values.date && (
                          <DateTimePicker
                            inputProps={{
                              min: moment(new Date(values.date))
                                .format("YYYY-MM-DD"),
                              max:moment().add(1, "years").toDate(),
                                
                              placeholder: "Please Select",
                            }}
                            name="validUntil"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5">Quote Number</Typography>
                        <TextfieldWrapper
                          name="quoteNumber"
                          size="small"
                          inputProps={{ maxLength: 10 }}
                          className={classes.textField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Typography sx={{ padding: "10px 0" }} variant="h5">
                          Title & Summary of Project
                        </Typography>
                        <TextfieldWrapper
                          name="title"
                          size="small"
                          placeholder="Title of Project"
                          className={classes.textField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={7}></Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextfieldWrapper
                          name="summary"
                          size="small"
                          placeholder="Summary of what needs to be done"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <FieldArray
                    name="dragData"
                    render={(arrayHelpers: any) => {
                      return (
                        <DragTable
                          data={INITIAL_FORM_STATE.dragData}
                          arrayHelper={arrayHelpers}
                          value={values}
                          setFieldValue={setFieldValue}
                        />
                      );
                    }}
                  />
                  <Box className={classes.gridMuirootValue}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography variant="h5">Notes</Typography>
                        <TextfieldWrapper
                          name="notes"
                          fullWidth
                          size="small"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </PaperBody>
                <Box className={classes.buttonPositions}>
                  <Box className={classes.buttonAll}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        borderRadius: "unset",
                        "&:hover": {
                          backgroundColor: "var(--buttonHover)",
                          color: "var(--white)",
                        },
                      }}
                      type="reset"
                      onClick={handleCancelButton}
                    >
                      Cancel
                    </Button>
                    <Box className={classes.buttonsaveDraft}>
                      <Button
                       type="submit"
                        variant="outlined"
                        color="secondary"
                        sx={{
                          borderRadius: "unset",
                          "&:hover": {
                            backgroundColor: "var(--buttonHover)",
                            color: "var(--white)",
                          },
                        }}
                        onClick={() => handleSaveDraft(values)}
                      >
                        Save as Draft
                      </Button>
                      {JobDetailsData[0]?.isDraft ?<Button
                        type="submit"
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                          borderRadius: "unset",
                          "&:hover": {
                            backgroundColor: "var(--buttonHover)",
                            color: "var(--white)",
                          },
                        }}
                        // disabled={!(isValid && dirty) }
                      >
                        Submit
                      </Button> : <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          color: "var(--white)",
                          borderRadius: "unset",
                          "&:hover": {
                            backgroundColor: "var(--buttonHover)",
                            color: "var(--white)",
                          },
                        }}
                        disabled={!(isValid && dirty) }
                      >
                        Submit
                      </Button>}
                      
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </ThemeProvider>
      ) : (
        <MobileResponsiveQuote />
      )}
    </Fragment>
  );
};

export default SelectTemplate;
