import Utils from "../../../../Utils";

  
  export const FinancialOnboardingReducer = (
    state={
        FinancialData:[],
    },
    action: any
  ) => {
    switch (action.type) {
      case Utils.ActionName.FINANCIAL_ONBOARDING_LIST:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  export const FinancialOnboardingDownloadReducer = (
    state={
        FinancialDownloadData:[],
    },
    action: any
  ) => {
    switch (action.type) {
      case Utils.ActionName.FINANCIAL_ONBOARDING_DOWNLOAD:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  export const getFinancialOnboardingReducerState = (state: any) =>
  state.FinancialOnboardingReducer;

  export const getFinancialOnboardingDownloadReducerState = (state: any) =>
  state.FinancialOnboardingDownloadReducer;