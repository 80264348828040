import {
  globalLoaderEnd,
  globalLoaderStart,
  ImageUploadLoaderEnd,
  ImageUploadLoaderIncrement,
  ImageUploadLoaderStart,
} from "../../../../reducer/globalReducer";
import Utils from "../../../../Utils";
import { uploadFile } from "../../../../Utils/AwsFileUpload";
import { getSkills } from "../../../signup/actions";

const getBusinessProfile = () => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: false,
    });
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.currentUser,
    "",
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        if (data.data.userType === "COMPANYSOLETRADER") {
          localStorage.setItem(
            "user_name",
            data?.data?.companySoleTraderDetail?.businessName
          );
          localStorage.setItem("name", data?.data?.name);
          dispatch({
            type: Utils.ActionName.PROFILE_DETAILS_DATA,
            payload: {
              profileDetailsData: data?.data,
            },
          });
          dispatch({
            type: "GET_CURRENT_BUSINESS_PROFILE",
            payload: {
              allActivities: data?.data?.allActivities || "",
              businessName:
                data?.data?.companySoleTraderDetail?.businessName || "",
              abnNumber: data?.data?.companySoleTraderDetail?.abnNumber || "",
              companyJobNotificationSetting:
                data?.data?.companyJobNotificationSetting || [],
              companyTeamNotificationSetting:
                data?.data?.companyTeamNotificationSetting || [],
              availableTrades:
                data?.data?.companySoleTraderDetail?.skills || [],
              industryExp:
                data?.data?.companySoleTraderDetail?.industryExp || [],
              businessProfilePicture:
                data?.data?.companySoleTraderDetail?.businessProfilePicture ||
                "",
              location: data?.data?.location || null,
              businessTagline:
                data?.data?.companySoleTraderDetail?.businessTagline || "",
              additionalInfo:
                data?.data?.companySoleTraderDetail?.additionalInfo || "",
              images: data?.data?.companySoleTraderDetail?.images || [],
              services: data?.data?.companySoleTraderDetail?.services || "",
            },
          });

          dispatch({
            type: "UPDATE_IMAGE_PENDING",
            payload: {
              uploadedImages: data?.data?.companySoleTraderDetail?.images || [],
            },
          });
        } else {
          if (localStorage.getItem("roles")) {
            localStorage.setItem(
              "user_name",
              data?.data?.companyDetail?.companyDetail?.businessName
            );
            localStorage.setItem("name", data?.data?.name);

            dispatch({
              type: "GET_CURRENT_BUSINESS_PROFILE",
              payload: {
                allActivities: data?.data?.allActivities || "",
                businessName:
                  data?.data?.companyDetail?.companyDetail?.businessName || "",
                abnNumber:
                  data?.data?.companyDetail?.companyDetail?.abnNumber || "",
                companyJobNotificationSetting:
                  data?.data?.companyJobNotificationSetting || [],
                companyTeamNotificationSetting:
                  data?.data?.companyTeamNotificationSetting || [],
                availableTrades:
                  data?.data?.companyDetail?.companyDetail?.availableTrades ||
                  [],
                industryExp:
                  data?.data?.companyDetail?.companyDetail?.industryExp || [],
                businessTagline:
                  data?.data?.companyDetail?.companyDetail?.businessTagline ||
                  "",
                businessProfilePicture:
                  data?.data?.companyDetail?.companyDetail
                    ?.businessProfilePicture || "",
                additionalInfo:
                  data?.data?.companyDetail?.companyDetail?.additionalInfo ||
                  "",
                location: data?.data?.companyDetail?.location || null,
                images: data?.data?.companyDetail?.companyDetail?.images || [],
                services:
                  data?.data?.companyDetail?.companyDetail?.services || "",
              },
            });
            dispatch({
              type: "UPDATE_IMAGE_PENDING",
              payload: {
                uploadedImages:
                  data?.data?.companyDetail?.companyDetail?.images || [],
              },
            });
          } else {
            localStorage.setItem(
              "user_name",
              data?.data?.companyDetail?.businessName
            );
            localStorage.setItem("name", data?.data?.name);
            dispatch({
              type: Utils.ActionName.PROFILE_DETAILS_DATA,
              payload: {
                profileDetailsData: data?.data,
              },
            });
            dispatch({
              type: "GET_CURRENT_BUSINESS_PROFILE",
              payload: {
                allActivities: data?.data?.allActivities || "",
                businessName: data?.data?.companyDetail?.businessName || "",
                abnNumber: data?.data?.companyDetail?.abnNumber || "",
                companyJobNotificationSetting:
                  data?.data?.companyJobNotificationSetting || [],
                companyTeamNotificationSetting:
                  data?.data?.companyTeamNotificationSetting || [],
                availableTrades:
                  data?.data?.companyDetail?.availableTrades || [],
                industryExp: data?.data?.companyDetail?.industryExp || [],
                businessTagline:
                  data?.data?.companyDetail?.businessTagline || "",
                businessProfilePicture:
                  data?.data?.companyDetail?.businessProfilePicture || "",
                additionalInfo: data?.data?.companyDetail?.additionalInfo || "",
                location: data?.data?.location || null,
                images: data?.data?.companyDetail?.images || [],
                services: data?.data?.companyDetail?.services || "",
              },
            });
            dispatch({
              type: "UPDATE_IMAGE_PENDING",
              payload: {
                uploadedImages: data?.data?.companyDetail?.images || [],
              },
            });
          }
        }
        dispatch(globalLoaderEnd());
      }
    },
    (error: any) => {
      let { data } = error;
      console.log(data, error);
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

const editBusinessProfile =
  (businessProfileData: any) => async (dispatch: any) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    if ("businessProfilePicture" in businessProfileData) {
      try {
        const imageData: any = await uploadFile(
          businessProfileData["businessProfilePicture"]
        );
        businessProfileData["businessProfilePicture"] = imageData.Location;
      } catch (error) {
        console.log(error);
        Utils.showAlert(2, "Error Occured while uploading image");
        return;
      }
    }

    if ("images" in businessProfileData) {
      let imagesUrl = [];
      let count = 0;
      dispatch(
        ImageUploadLoaderStart({
          current: count,
          total: businessProfileData["images"]?.length,
          loading: true,
        })
      );

      for (let el of businessProfileData["images"]) {
        try {
          // dispatch(globalLoaderStart());
          const imageData: any = await uploadFile(el.file);
          dispatch(globalLoaderEnd());
          // Utils.showAlert(1, `${el.file.name} is uploaded Successfully`);
          count++;
          dispatch(ImageUploadLoaderIncrement({ current: count }));

          imagesUrl.push(imageData.Location);
        } catch (error) {
          dispatch(ImageUploadLoaderEnd());
          console.log(error);
          Utils.showAlert(2, "Error Occured while uploading image");
          throw new Error("Error Occured while uploading image");
        }
      }
      dispatch(ImageUploadLoaderEnd());
      delete businessProfileData["images"];
      businessProfileData["addImages"] = imagesUrl;
    }

    dispatch(globalLoaderStart());

    Utils.API.putApiCall(
      Utils.endpoint.editBusinessProfile,
      {
        ...businessProfileData,
        companyId: localStorage.getItem("user_id"),
      },
      (res: any) => {
        const { data } = res;
        console.log(data);
        Utils.showAlert(1, data?.message);
        dispatch(getBusinessProfile());
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error);
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export const deleteImageFromBusinessProfile =
  (formData: any) => async (dispatch: any) => {
    // return;

    dispatch(globalLoaderStart());
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    let imagedata = {
      deleteImageIds: [formData.deleteImage],
      companyId: localStorage.getItem("user_id"),
    };

    delete formData["deleteImages"];
    delete formData["deleteImage"];

    dispatch({
      type: "UPDATE_IMAGE_PENDING",
      payload: { ...formData },
    });

    Utils.API.putApiCall(
      Utils.endpoint.editBusinessProfile,
      imagedata,
      (res: any) => {
        const { data } = res;
        Utils.showAlert(1, "Image deleted Successfully");
        Utils.API.getApiCall(
          Utils.endpoint.currentUser,
          "",
          (res: any) => {
            const { data } = res;
            if (data.statusCode === Utils.Constants.api_success_code.success) {
              if (data.data.userType === "COMPANYSOLETRADER") {
                dispatch({
                  type: "UPDATE_IMAGE_SUCCESS",
                  payload: {
                    uploadedImages:
                      data?.data?.companySoleTraderDetail?.images || [],
                  },
                });
              } else {
                dispatch({
                  type: "UPDATE_IMAGE_SUCCESS",
                  payload: {
                    uploadedImages: data?.data?.companyDetail?.images || [],
                  },
                });
              }
            }

            dispatch(globalLoaderEnd());
          },
          (err: any) => {
            dispatch(globalLoaderEnd());
            console.log(err);
          }
        );
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error);
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

// export const updateImageFromBusinessProfile =
//   (formData: any) => async (dispatch: any) => {
//     console.log("formDataformData", formData);
//     if (!navigator.onLine) {
//       //check if user is online or not
//       Utils.showAlert(2, "Please check your internet connection!");
//       dispatch({
//         type: Utils.ActionName.LOADING,
//         payload: false,
//       });
//       return;
//     }

//     dispatch(globalLoaderStart());

//     try {
//       const imageData: any = await uploadFile(formData?.addImages);
//       formData["addImages"] = imageData?.Location;
//     } catch (error) {
//       dispatch(globalLoaderEnd());
//       console.log(error);
//       Utils.showAlert(2, "Error Occured while uploading image");
//       throw new Error("Error Occred while uploading image");
//     }

//     const dataToSend = {
//       // addImageDescription:{
//       //   imageId:
//       // },
//       addImages: [formData?.addImages],
//       deleteImageIds: [formData?.deleteImage],
//       companyId: localStorage.getItem("user_id"),
//     };
//     delete formData["addImages"];
//     delete formData["deleteImage"];

//     dispatch({
//       type: "UPDATE_IMAGE_PENDING",
//       payload: { ...formData },
//     });

//     Utils.API.putApiCall(
//       Utils.endpoint.editBusinessProfile,
//       dataToSend,
//       (res: any) => {
//         const { data } = res;
//         console.log(data);
//         Utils.showAlert(1, "Image updated Successfully");
//         Utils.API.getApiCall(
//           Utils.endpoint.currentUser,
//           "",
//           (res: any) => {
//             const { data } = res;
//             if (data.statusCode === Utils.Constants.api_success_code.success) {
//               if (data.data.userType === "COMPANYSOLETRADER") {
//                 dispatch({
//                   type: "UPDATE_IMAGE_SUCCESS",
//                   payload: {
//                     uploadedImages:
//                       data?.data?.companySoleTraderDetail?.images || [],
//                   },
//                 });
//               } else {
//                 dispatch({
//                   type: "UPDATE_IMAGE_SUCCESS",
//                   payload: {
//                     uploadedImages: data?.data?.companyDetail?.images || [],
//                   },
//                 });
//               }
//             }
//             dispatch(globalLoaderEnd());
//           },
//           (err: any) => {
//             dispatch(globalLoaderEnd());
//             console.log(err);
//           }
//         );
//       },
//       (error: any) => {
//         let { data } = error;
//         console.log(data, error);
//         Utils.showAlert(2, data.message);
//         dispatch(globalLoaderEnd());
//       }
//     );
//   };

export const updateImageFromBusinessProfile =
  (formData: any, imagepreview?: any) => async (dispatch: any) => {
    console.log(
      "formDataformData",
      formData,
      formData?.addImageDescription?.image,
      imagepreview
    );
    if (!navigator.onLine) {
      // Check if user is online or not
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    dispatch(globalLoaderStart());

    try {
      const imageDescription = formData?.addImageDescription?.image;
      const imageData: any =
        imageDescription &&
        imageDescription instanceof File &&
        (await uploadFile(formData?.addImageDescription?.image));
      const description = formData?.addImageDescription?.description;
      // const imageDescription = formData?.addImageDescription?.image;

      // if (imageDescription && imageDescription instanceof File) {
      //   const imageData: any = await uploadFile(imageDescription);

      //   // Rest of your logic using imageData
      // } else {
      //   // Handle the case when imageDescription is not a File object
      // }
      const addImageDescription = {
        imageId: formData?.addImageDescription?.imageId,
        image: imageData?.Location || imagepreview,
        ...(description !== "" && { description }),
        // description: formData?.addImageDescription?.description, // Update with the actual description
      };

      formData["addImageDescription"] = addImageDescription;
    } catch (error) {
      dispatch(globalLoaderEnd());
      console.log(error);
      Utils.showAlert(2, "Error Occurred while uploading image");
      throw new Error("Error Occurred while uploading image");
    }

    const dataToSend = {
      // addImageDescription: {
      //   imageId: formData?.addImageDescription?.imageId,
      //   ...(description !== "" && { description }),
      // },
      addImageDescription: formData?.addImageDescription,
      // deleteImageIds: [formData?.deleteImage],
      companyId: localStorage.getItem("user_id"),
    };
    delete formData["addImageDescription"];
    delete formData["deleteImage"];

    dispatch({
      type: "UPDATE_IMAGE_PENDING",
      payload: { ...formData },
    });

    Utils.API.putApiCall(
      Utils.endpoint.editBusinessProfile,
      dataToSend,
      (res: any) => {
        const { data } = res;
        console.log(data);
        Utils.showAlert(1, "Image updated Successfully");
        Utils.API.getApiCall(
          Utils.endpoint.currentUser,
          "",
          (res: any) => {
            const { data } = res;
            if (data.statusCode === Utils.Constants.api_success_code.success) {
              if (data.data.userType === "COMPANYSOLETRADER") {
                dispatch({
                  type: "UPDATE_IMAGE_SUCCESS",
                  payload: {
                    uploadedImages:
                      data?.data?.companySoleTraderDetail?.images || [],
                  },
                });
              } else {
                dispatch({
                  type: "UPDATE_IMAGE_SUCCESS",
                  payload: {
                    uploadedImages: data?.data?.companyDetail?.images || [],
                  },
                });
              }
            }
            dispatch(globalLoaderEnd());
          },
          (err: any) => {
            dispatch(globalLoaderEnd());
            console.log(err);
          }
        );
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error);
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export { getSkills, getBusinessProfile, editBusinessProfile };
