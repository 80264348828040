import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../../../reducer/globalReducer";
import Utils from "../../../../Utils";



export const NotificationSetting = (values: any) => async (dispatch: any) => {
  const dataToSend = {
    companyId: localStorage.getItem("user_id"),
    companyJobNotificationSetting: {
      isJobScheduled: values.isJobScheduled,
      isQuoteAccepted: values.isQuoteAccepted,
      isQuoteViewed: values.isQuoteViewed,
      isNewRequest: values.isNewRequest,
    },
    companyTeamNotificationSetting: {
      isRolePermissionAssigned: values.isRolePermissionAssigned,
      isEmployeeProfileComplete: values.isEmployeeProfileComplete,
      isEmpJobnoteadd: values.isEmpJobnoteadd,
    },
    allActivities: values.allActivities,
  };

  dispatch(globalLoaderStart());
  Utils.API.putApiCall(
    Utils.endpoint.notificationSetting,
    dataToSend,
    (res: any) => {
      const { data } = res;
      if (data.statusCode === Utils.Constants.api_success_code.success) {
        Utils.showAlert(1, data.message);
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      console.dir(error);

      let { data } = error;
      Utils.showAlert(2, data.message || "An Unexpected Error Occured");
      dispatch(globalLoaderEnd());
    }
  );
};

export const NotificationSettingdefaultTrue = () => async () => {
  const dataToSend = {
    companyId: localStorage.getItem("user_id"),
    companyJobNotificationSetting: {
      isJobScheduled: true,
      isQuoteAccepted: true,
      isQuoteViewed: true,
      isNewRequest: true,
    },
    companyTeamNotificationSetting: {
      isRolePermissionAssigned: true,
      isEmployeeProfileComplete: true,
      isEmpJobnoteadd: true,
    },
    allActivities: true,
  };

  // dispatch(globalLoaderStart());
  Utils.API.putApiCall(
    Utils.endpoint.notificationSetting,
    dataToSend,
    (res: any) => {
      const { data } = res;
      if (data.statusCode === Utils.Constants.api_success_code.success) {
        // Utils.showAlert(1, data.message);
      }
      // dispatch(globalLoaderEnd());
    },
    (error: any) => {
      console.dir(error);

      let { data } = error;
      Utils.showAlert(2, data.message || "An Unexpected Error Occured");
      // dispatch(globalLoaderEnd());
    }
  );
};
