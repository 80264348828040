import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../reducer/globalReducer";
import Utils from "../../Utils";

export const getGuestUserCompanyListings = () => async (dispatch: any) => {};

export const CompanyClientListings = (query: any) => async (dispatch: any) => {
  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endpoint.companyListing,
    query,
    (responseData: any) => {
      const { data } = responseData;
      if (data.statusCode === 200) {
        console.log(data);
        dispatch({
          type: "COMPANY_LIST_SUCCESS",
          payload: {
            pages: data?.totalPage,
            total: data?.total,
            list: data?.data,
          },
        });
        dispatch(globalLoaderEnd());
      } else {
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const saveJobCompanyListing =
  (obj: any, setState?: any) => async (dispatch: any) => {
    dispatch(globalLoaderStart());

    let savedObj = {
      ...obj,
      userId: localStorage?.getItem("user_id"),
    };

    Utils.API.postApiCall(
      Utils.endpoint.saveCompany,
      savedObj,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          Utils.showAlert(1, data.message);
          dispatch(SavedCompanyListing(1, 100, ""));
          setState((prev: any) => prev + 1);
        } else {
          Utils.showAlert(2, data.message);
          dispatch(globalLoaderEnd());
        }
      },
      (error: any) => {
        let { data } = error;
        // Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export const SavedCompanyListing = (
  pageNo: number,
  limit: 100,
  search: string
) => {
  return (dispatch: any) => {
    const queryString = `?userId=${localStorage.getItem("user_id")}&${
      !search ? "" : `&searchKey=${search}`
    }&pageNo=${pageNo}
    &limit=${limit}`;

    dispatch(globalLoaderStart());
    Utils.API.getApiCall(
      Utils.endpoint.savedCompanyListing,
      queryString,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.SAVED_COMPANY_LISTING,
            payload: {
              savedCompanyList: data.data,
            },
          });
        } else {
          Utils.showAlert(2, data.message);
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };
};



export const EnquiryForm = (obj: any) => async (dispatch: any) => {
  let savedObj = {
    ...obj,
    userId: localStorage?.getItem("clientId"),
    userType: "COMPANY",
  };
  dispatch(globalLoaderStart());
  Utils.API.postApiCall(
    Utils.endpoint.sendEnquiry,
    savedObj,
    (responseData: any) => {
      const { data } = responseData;
      if (data.statusCode === 200) {
        Utils.showAlert(1, data.message);
      } else {
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};


