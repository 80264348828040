import Utils from "../../../Utils";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

const DRAGGABLE_FIELDS: any[] = [
  {
    id: "box-1",
    boxType: 0,
    isNew: false,
    field: { title: "Brief Overview", description: "" },
  },
  {
    id: "box-2",
    boxType: 0,
    isNew: false,
    field: { title: "Scope of Work", description: "" },
  },
  {
    id: "box-3",
    boxType: 0,
    isNew: false,
    field: { title: "Proposed Action Plan", description: "" },
  },
  {
    id: "box-4",
    boxType: 0,
    isNew: false,
    field: { title: "Inclusions", description: "" },
  },
  {
    id: "box-5",
    boxType: 0,
    isNew: false,
    field: { title: "Exclusions", description: "" },
  },
  {
    id: "box-6",
    boxType: 1,
    isNew: false,
    field: {
      title: "Costs",
      costItems: [
        {
          description: "",
          quantity: "",
          unitPrice: "",
          gst: "10",
          discount: "0",
        },
      ],
    },
  },
];

export const getInitialForms = () => {
  return DRAGGABLE_FIELDS.map((form: any) => {
    if (form.boxType === 0) {
      return {
        ...form,
        field: { title: form.field.title, description: "" },
      };
    }
    return {
      ...form,
      field: {
        title: form.field.title,
        costItems: [
          {
            description: "",
            quantity: "",
            unitPrice: "",
            gst: "10",
            discount: "0",
          },
        ],
      },
    };
  });
};

const reorder = (
  list: { [key: string]: any }[],
  startIndex: any,
  endIndex: any
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log(list, result, startIndex, endIndex);
  return result;
};

export const onDragEnd = (result: any, fields: any) => {
  console.log(result);

  if (!result.destination) {
    return;
  }

  return reorder(fields, result.source.index, result.destination.index);
};

export const setDraggableForms = (
  dispatch: Function,
  forms: { [key: string]: any }[] | undefined
) => {
  dispatch({
    type: SCRATCH_TEMPLATE,
    payload: {
      draggableForms: forms,
    },
  });
};

export const getIsDisabledValue = (
  errorsForms: boolean[],
  clientErrors: boolean
) => {
  return errorsForms.every((bv: boolean) => true && bv) && clientErrors;
};

// GET PROPS

export const getDraggableFieldProps = (
  form: { [key: string]: any },
  index: number
) => {
  return {
    key: form.id,
    index: index,
    form: form,
  };
};

export const getAddSectionButtonProps = (
  dispatch: Function,
  draggableForms: { [key: string]: any }[],
  errorsForms: boolean[]
) => {
  return {
    onClick: () => {
      setDraggableForms(dispatch, [
        ...draggableForms,
        {
          id: `box-${draggableForms.length + 1}`,
          boxType: 0,
          isNew: true,
          field: { title: "", description: "" },
        },
      ]);
      dispatch({
        type: SCRATCH_TEMPLATE,
        payload: {
          errorsForms: [...errorsForms, false],
        },
      });
    },
  };
};

export const makeDraggableFormFromInitialObj = (obj: {
  [key: string]: any;
}) => {

  const costForm = obj.itemCosting.map((item: { [key: string]: string }) => {
    const { description, quantity, gst, discount, unitPrice } = item;
    return {
      description,
      quantity: quantity.toString(),
      gst: gst.toString(),
      discount: discount.toString(),
      unitPrice: unitPrice.toString(),
    };
  });
  const editorForm = obj.titleAndDescription?.map(
    (form: { [key: string]: string }, index: number) => {
      if(form.title.toLowerCase() === "costs"){
        return {
          id: `box-${index + 1}`,
          boxType: 1,
          isNew: false,
          field: {
            title: "Costs",
            costItems: costForm,
          },
        };
      }
      return {
        id: `box-${index + 1}`,
        boxType: 0,
        isNew: index > 4,
        field: { ...form },
      };
    }
  );
  return editorForm;
  // editorForm?.pop();
  // return [
  //   ...editorForm,
  //   {
  //     id: `box-${editorForm.length + 1}`,
  //     boxType: 1,
  //     isNew: false,
  //     field: {
  //       title: "Costs",
  //       costItems: costForm,
  //     },
  //   },
  // ];
};
