import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LocalImages from "../../Utils/localImages";
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ theme }: any) => ({
    minHeight: "100vh",
    // width: "99vw",
    flexDirection: "column",
    marginTop: "7rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "6rem",
    },
  }),

  navAndBodyContainer: () => ({
    // width:"100%",
  }),
  headerContainer: () => ({
    // width:"100%",
  }),
  bodyContainer: () => ({
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 69px)",
    paddingTop: "68px",
  }),
}));
interface Props {
  children?: any;
  history?: any;
}

// const BoxContainer = styled(Box)(({ theme }) => ({
//   padding: "2rem 10.5rem 2rem 10.5rem",
//   [theme.breakpoints.down("md")]: {
//     padding: "2rem 1.5rem 2rem 1.5rem",
//   },
// }));

const MainContainer = ({ children, history }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      {localStorage.getItem("profile_complete") === "true" ||
      localStorage.getItem("profile_complete") === "false" ? (
        <>
          <Navbar />
          <div className={classes.mainContainer}>{children}</div>
        </>
      ) : (
        <div className={classes.mainContainer}>{children}</div>
      )}
    </>
  );
};

export default MainContainer;
