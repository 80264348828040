import { uploadFile } from "../../../Utils/AwsFileUpload";
import Utils from "../../../Utils";

import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../../reducer/globalReducer";

export const UploadSpQuoteAsDraft =
  (buildQuoteData: any, history: any) => async (dispatch: any) => {
    let pdfFile = buildQuoteData?.pdf;

    dispatch(globalLoaderStart());

    let data: any = { ...buildQuoteData };

    let companyId = localStorage.getItem("user_id");

    try {
      const imageURL: any = await uploadFile(pdfFile);

      data["downloadUrl"] = imageURL?.Location;

      dispatch(globalLoaderEnd());

      delete data?.pdf;
    } catch (error) {
      Utils.showAlert(2, "Error Occured while uploading image.");
      return;
    }

    data = {
      ...data,
      companyId,
      quoteSelection: "UPLOAD",
      isDraft: true,
      totalCost: 0,
    };

    Utils.API.putApiCall(
      Utils.endpoint.buildQuoteOwn,
      data,
      (res: any) => {
        const resData = res;
        // if (data.statusCode === Utils.Constants.api_success_code.success) {
        //   Utils.showAlert(1, data.message || "Profile Updated Successfully");
        // }

        const data = resData?.data?.data;

        // dispatch({
        //   type: "BQ_OWN_UPLOAD_SUCCESS",
        //   payload: {
        //     companyId: data?.companyDetail?.companyId || "",
        //     jobId: data?.jobId || "",
        //     quoteSelection: "UPLOAD",
        //     isDraft: data?.isDraft || false,
        //     totalCost: data?.totalCost || 0,
        //     downloadUrl: data?.downloadUrl || "",
        //   },
        // });

        history?.push(Utils.Pathname.buildQuoteOwnPreview, {
          jobId: data?._id || "",
          quoteSelection: "UPLOAD",
        });

        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        console.dir(error);

        let { data } = error;
        Utils.showAlert(2, data.message || "An Unexpected Error Occured");
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: false,
        // });
        dispatch(globalLoaderEnd());
      }
    );
  };

export const SpQuoteOwnFinalSubmit =
  (buildQuoteData: any, history: any) => async (dispatch: any) => {
    let pdfFile = buildQuoteData?.pdf;

    dispatch(globalLoaderStart());

    let data: any = { ...buildQuoteData };

    let companyId = localStorage.getItem("user_id");

    data = {
      ...data,
      companyId,
      quoteSelection: "UPLOAD",
      isDraft: false,
    };

    Utils.API.putApiCall(
      Utils.endpoint.buildQuoteOwn,
      data,
      (res: any) => {
        const resData = res;
        // if (data.statusCode === Utils.Constants.api_success_code.success) {
        //   Utils.showAlert(1, data.message || "Profile Updated Successfully");
        // }

        const data = resData?.data?.data;

        // dispatch({
        //   type: "BQ_OWN_UPLOAD_SUCCESS",
        //   payload: {
        //     companyId: data?.companyDetail?.companyId || "",
        //     jobId: data?.jobId || "",
        //     quoteSelection: "UPLOAD",
        //     isDraft: data?.isDraft || false,
        //     totalCost: data?.totalCost || 0,
        //     downloadUrl: data?.downloadUrl || "",
        //   },
        // });

        history?.push(Utils.Pathname.ViewAllRequest);
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        console.dir(error);

        let { data } = error;
        Utils.showAlert(2, data.message || "An Unexpected Error Occured");
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: false,
        // });
        dispatch(globalLoaderEnd());
      }
    );
  };

export const EditUploadSpQuoteAsDraft =
  (buildQuoteData: any, closeModal: any) => async (dispatch: any) => {
    console.log(buildQuoteData, closeModal, "edit upload data");

    let pdfFile = buildQuoteData?.pdf;

    dispatch(globalLoaderStart());

    let data: any = { ...buildQuoteData };

    let companyId = localStorage.getItem("user_id");

    try {
      const imageURL: any = await uploadFile(pdfFile);

      data["downloadUrl"] = imageURL?.Location;

      dispatch(globalLoaderEnd());

      delete data?.pdf;
    } catch (error) {
      Utils.showAlert(2, "Error Occured while uploading image.");
      return;
    }

    data = {
      ...data,
      companyId,
      quoteSelection: "UPLOAD",
      isDraft: true,
      totalCost: 0,
    };

    Utils.API.putApiCall(
      Utils.endpoint.buildQuoteOwn,
      data,
      (res: any) => {
        const resData = res;

        const data = resData?.data?.data;
        closeModal(false);
        dispatch(GetBqOwnDetails(data?._id));
      },
      (error: any) => {
        console.dir(error);

        let { data } = error;
        Utils.showAlert(2, data.message || "An Unexpected Error Occured");
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: false,
        // });
        dispatch(globalLoaderEnd());
      }
    );
  };

export const GetBqOwnDetails = (jobId: any) => async (dispatch: any) => {
  dispatch(globalLoaderStart());

  let companyId = localStorage.getItem("user_id");

  Utils.API.getApiCall(
    Utils.endpoint.jobDetails,
    `?reqJobId=${jobId}&companyId=${companyId}`,
    (res: any) => {
      const resData = res;
      // if (data.statusCode === Utils.Constants.api_success_code.success) {
      //   Utils.showAlert(1, data.message || "Profile Updated Successfully");
      // }
      const data = resData?.data?.data?.[0];

      dispatch(globalLoaderEnd());

      dispatch({
        type: "BQ_OWN_UPLOAD_DETAIL",
        payload: {
          companyId: data?.companyDetail?.companyId || "",
          jobId: data?._id || "",
          quoteSelection: data?.quoteSelection || "UPLOAD",
          isDraft: data?.isDraft || false,
          totalCost: data?.totalCost || 0,
          downloadUrl: data?.downloadUrl || "",
          client: data?.job_detail?.clientDetail?.businessName || "",
        },
      });
    },
    (error: any) => {
      console.dir(error);

      let { data } = error;
      Utils.showAlert(2, data.message || "An Unexpected Error Occured");
      // dispatch({
      //   type: Utils.ActionName.LOADING,
      //   payload: false,
      // });
      dispatch(globalLoaderEnd());
    }
  );
};
