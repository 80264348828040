import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { onMessageListener, requestForToken } from "../Utils/firebase";

const Notification = () => {
  const location = useLocation();

  if (!localStorage.getItem("fb")) {
    requestForToken();
  }
  useEffect(() => {
    requestForToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Notification;
