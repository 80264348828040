import { Suspense } from "react";
import LoginContainer from "../../hoc/loginContainer/index";
import { Route } from "react-router-dom";
import Utils from "../../Utils";
import { Redirect } from "react-router-dom";
import GlobalLoader from "../../components/globalLoader";
interface Props {
  path: string;
  component: any;
  exact?: boolean;
  testing?: boolean;
}

const PublicRoute = ({ component: Component, testing, ...rest }: Props) => {
  if (testing) {
    return (
      <>
        <GlobalLoader />
        <Route
          {...rest}
          render={(props) => (
            <Suspense fallback={""}>{<Component {...props} />}</Suspense>
          )}
        />
      </>
    );
  }

  return (
    <>
      <GlobalLoader />
      <Route
        {...rest}
        render={(props) => (
          <Suspense fallback={""}>
            {localStorage.getItem("profile_complete") === "true" ? (
              <Redirect to={Utils.Pathname.Dashboard} />
            ) : (
              <LoginContainer {...props}>
                <Component {...props} />
              </LoginContainer>
            )}
          </Suspense>
        )}
      />
    </>
  );
};
export default PublicRoute;
