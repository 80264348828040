import moment from "moment-timezone";
import dayjs from "dayjs";
// import * as dayjs from "dayjs";
import * as Yup from "yup";
import Utils from "../Utils";
const phoneRegExp = /^\d{10}$/;
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const num = /^[0-9]*$/;
const alphaNumeric = /^[ A-Za-z0-9]*$/;

const LoginSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter valid email")
      .max(50, "Email can be maximum of hundred characters")
      .required("Email field cannot be blank"),
    password: Yup.string().trim().required("Password field cannot be blank"),
  });
};
const MobileNumber = () => {
  return Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Please enter your phone number")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Should contain 10 digits")
      .max(10, "Should contain 10 digits"),
  });
};
const SignupSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Numbers and special character are not allowed in name field.")
      .max(50, "Name can be maximum of 50 characters")
      .min(3, "Name must be minimum of three characters")

      .required("Name field cannot be blank"),
    email: Yup.string()
      .trim()
      .email("Please enter valid email")
      .max(100, "Email can be maximum of hundred characters")

      .required("Email field cannot be blank"),
    password: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,}$/,
        "Invalid password"
      )
      .matches(/^\S+$/, "Whitespace is not allowed")
      .required("Password field cannot be blank"),
    checked: Yup.boolean()
      .oneOf([true], "Please mark the checkbox !")
      .required("Please mark the checkbox !"),
  });
};
const ChangePasswordScheema = () => {
  return Yup.object().shape({
    currentpassword: Yup.string()
      .trim()
      .required("Current Password field cannot be blank"),
    newpassword: Yup.string()
      .trim()
      .min(6, "Password should be minimum of six characters")
      .max(20, "Password should be maximum of twenty characters")

      .required("New Password field cannot be blank")
      .test(
        "notBothAtTheSameTime",
        "Current Password and New Password should not be the same",
        function (newpassword) {
          const { currentpassword } = this.parent;
          if (currentpassword === newpassword) {
            return false;
          }
          return true;
        }
      ),
  });
};

const CompanyContactSchema = () => {
  return Yup.object().shape({
    mobileNo: Yup.string()
      .required("Please enter your phone number")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Should contain 10 digits")
      .max(10, "Should contain 10 digits"),
    officeNo: Yup.string()
      .required("Please enter your Office number")
      .matches(phoneRegExp, "Office number is not valid")
      .min(10, "Should contain 10 digits")
      .max(10, "Should contain 10 digits"),
  });
};

const BusinessDetailSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(100, "Name can be maximum of hundred characters")

      .required("Name field cannot be blank"),
    phone_number: Yup.string()
      .required("Please enter your phone number")
      // .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Should contain 10 digits")
      .max(10, "Should contain 10 digits"),
    location: Yup.object().required("Please enter the location"),
  });
};

const SoleTraderSchema = () =>
  Yup.object().shape({
    businessName: Yup.string()
      .trim()
      .required("Please enter your business name")
      .nullable(),
    abnNumber: Yup.string()
      .trim()
      .test("Abn_validity_check", "Invalid Abn number", (val: any) => {
        return Utils.CommonFunctions.checkAbn(val);
      })
      .min(11, "Should contain 11 digits")
      .max(11, "Should contain 11 digits")
      .required("Please enter your abn number")
      .nullable(),
    location: Yup.object()
      .shape({
        address: Yup.string(),
        coordinates: Yup.array(),
      })
      .required("Location is required")
      .nullable(),
    skills: Yup.array()
      .required("This field is required!")
      .min(1, "This field is required! "),
    // industryExp: Yup.array().min(1, "This field is required! "),
  });

const ForgotPassWordSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter a valid email")
      .required("Email required"),
  });

const ResetPasswordSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string().trim().required("Password filed is required"),
    confirmPassword: Yup.string()
      .trim()
      .required("Password field is required")
      .test(
        "check confirmPassword",
        "Password does not match",
        function (value: any) {
          const { newPassword } = this.parent;
          return value === newPassword;
        }
      ),
  });

const AddTeamMemberSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .trim()
      .required("Please enter email address"),
  });
};

const HomeSchema = () => {
  return Yup.object().shape({
    request: Yup.object()
      .shape({
        request: Yup.string(),
        coordinates: Yup.array(),
      })
      .required("Request Required"),
    roles: Yup.array().min(1, "Required"),
  });
};

const FILE_SIZE = 10 * 1024 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const MyDetailsSchema = () =>
  Yup.object({
    name: Yup.string()
      .strict(true)
      .min(3, "Please enter a full name")
      .max(50, "Enter a valid name")
      .trim("Please enter a full name")
      .matches(/^[a-zA-Z\s]*$/, "Please enter a valid name")
      .required("Please enter a full name"),
    // email: Yup.string().email("invalid email").required("email is required"),
    password: Yup.string()
      // .required("Current password is required")
      .min(8, "min 8 digits are required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,}$/,
        "Please enter a valid Password"
      ),
    // .test(
    //   "currentPassword-Match",
    //   "Please enter a valid Password",
    //   function (value) {
    //     return value === currentPassword;
    //   }
    // ),
    newPassword: Yup.string()
      // .required("New password is required")
      .min(8, "min 8 digits are required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,}$/,
        "Please enter a valid Password"
      )
      .test(
        "new-cur-notmatch",
        "Current Password and new password could not be same.",
        function (value) {
          if (!this.parent.password) {
            return true;
          }
          return this.parent.password !== value;
        }
      )
      .test(
        "newPassword-required",
        "New password is required",
        function (value) {
          if (this.parent.password && !value) {
            return false;
          } else {
            return true;
          }
        }
      ),
    profilePicture: Yup.mixed()
      // .required("A file is required")
      .test("fileSize", "Photo is not in proper size or format", (value) => {
        if (value) {
          return value && value.size <= FILE_SIZE;
        } else {
          return true;
        }
      })
      .test("fileFormat", "Photo is not in proper size or format", (value) => {
        if (value) {
          return value && SUPPORTED_FORMATS.includes(value.type);
        } else {
          return true;
        }
      }),
  });

const BusinessProfileSchema = (imagesLength: any) =>
  Yup.object({
    businessName: Yup.string()
      .trim()
      .required("Please enter company name")
      .max(100, "Invalid Company Name"),
    abnNumber: Yup.string()
      .required("Please enter your abn number")
      .min(11, "Should contain 11 digits")
      .max(11, "Should contain 11 digits")
      .test("Abn_validity_check", "Invalid Abn number", (val: any) => {
        return Utils.CommonFunctions.checkAbn(val || 0);
      }),
    companyTagline: Yup.string().max(250, "maximum characters reached"),
    services: Yup.string(),
    servicesLength: Yup.number().max(250, "maximum characters reached"),
    additionalInfo: Yup.string().max(250, "maximum characters reached"),
    businessProfilePicture: Yup.mixed()
      // .required("A file is required")
      .test("fileSize", "Photo is not in proper size or format", (value) => {
        if (value) {
          return value && value.size <= FILE_SIZE;
        } else {
          return true;
        }
      })
      .test("fileFormat", "Photo is not in proper size or format", (value) => {
        if (value) {
          return value && SUPPORTED_FORMATS.includes(value.type);
        } else {
          return true;
        }
      }),
    location: Yup.mixed().test(
      "isnull",
      "Please enter site location",
      (value) => {
        console.log(value);
        if (value === null) {
          return false;
        } else {
          return true;
        }
      }
    ),
    // industryExp: Yup.array().min(1, "Industry Experience is required"),
    // // .required("Atleast One item should be there"),
    // AvailableTrades: Yup.array().min(1, "Trades is required"),
    images: Yup.array()
      .max(
        imagesLength === 10 ? 10 : imagesLength,
        `maximum ${
          imagesLength === 10 ? "10" : `${imagesLength}`
        }  images are required`
      )
      .test("correct file size", "file too large", (files: any) => {
        let validate = true;
        if (!files.length) {
          validate = true;
          return validate;
        } else {
          files.forEach((el: any) => {
            if (el.file.size > FILE_SIZE) {
              validate = false;
              return;
            }
          });
          return validate;
        }
      })
      .test("is Correct File", "file is not in correctType", (files: any) => {
        let validate = true;
        if (!files.length) {
          validate = true;
          return validate;
        } else {
          files.forEach((el: any) => {
            if (el.file && !SUPPORTED_FORMATS.includes(el.file.type)) {
              validate = false;
              return;
            }
          });
          return validate;
        }
      }),
    // additionalInformation: `Additional Information`,
    // services: "",
  });

const StandardRatesCreateSchema = () => {
  return Yup.object({
    day: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    afternoon: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    night: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    overTime: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    weekEnd: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    publicHoliday: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
    callOut: Yup.number()
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty")
      .lessThan(10000, "More than maximum limit.")
      .moreThan(0, "Field cannot be empty")
      .required("Field Cannot be empty"),
  });
};

const DiscountRateSchema = (
  standardRateObj = {
    DAY: 0,
    AFTER_NOON: 0,
    NIGHT: 0,
    OVER_TIME: 0,
    WEEKEND: 0,
    PUBLIC_HOLIDAY: 0,
    CALL_OUT: 0,
  }
) => {
  return Yup.object({
    DAY: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["DAY"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    AFTER_NOON: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["AFTER_NOON"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    NIGHT: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["NIGHT"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    OVER_TIME: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["OVER_TIME"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    WEEKEND: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["WEEKEND"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    PUBLIC_HOLIDAY: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["PUBLIC_HOLIDAY"], "Not more than standard rate")
      .required("Field Cannot be empty"),
    CALL_OUT: Yup.number()
      .moreThan(0, "Greater than 0")
      .max(standardRateObj["CALL_OUT"], "Not more than standard rate")
      .required("Field Cannot be empty"),
  });
};

const SelectTemplateSchema = Yup.object().shape({
  validUntil: Yup.date().required("Required"),
  quoteNumber: Yup.string()
    .max(10, "Quote number not greater than 10")
    .required("Please enter Quote Number")
    .matches(
      alphaNumeric,
      "Can contain uppercase letters, lowercase letters and number"
    ),
  title: Yup.string()
    .required("Required")
    .max(100, "Title cannot be maximum of 100 characters"),
  summary: Yup.string()
    .required("Required")
    .max(250, "Name cannot be maximum of 250 characters"),
  dragData: Yup.array().of(
    Yup.object().shape({
      description: Yup.string()
        .required("Required field")
        .max(250, "Name cannot be maximum of 250 characters"),
      quantity: Yup.number()
        .required("Required")
        .integer()
        .min(1, "please enter quantity atLeast One"),
      unitPrice: Yup.number()
        .required("Required")
        .min(1, "please enter price more than 1$"),
      discount: Yup.number()
        .min(0, "number should be greater 0 than")
        .max(99, "number should be less 99 than")
        .nullable(),
      gst: Yup.string(),
    })
  ),
});

const SelectTemplateSchemaMobile = Yup.object().shape({
  validUntil: Yup.date().required("Required"),
  quoteNumber: Yup.string()
    .max(10, "Quote number not greater than 10")
    .required("Please enter your quoteNumber number")
    .matches(
      alphaNumeric,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  title: Yup.string()
    .required("Required")
    .max(100, "Title cannot be maximum of 100 characters"),
  summary: Yup.string()
    .required("Required")
    .max(250, "Name cannot be maximum of 250 characters"),
});

const SelectTemplateSchemaMobileField = Yup.object().shape({
  description: Yup.string()
    .required("Required field")
    .max(250, "Name cannot be maximum of 250 characters"),
  quantity: Yup.string()
    .matches(num, "Must be a number")
    .required("Required")
    .min(1, "please enter quantity atLeast One"),
  unitPrice: Yup.string()
    .matches(num, "Must be a number")
    .required("Required")
    .min(1, "please enter price more than 1$"),
  discount: Yup.string()
    .matches(num, "Must be a number")
    .min(0, "number should be greater 0 than")
    .max(99, "number should be less 99 than")
    .nullable(),
  gst: Yup.string(),
});

const ClientScratchSchema = Yup.object().shape({
  clientname: Yup.string()
    .required("Required field")
    .max(250, "Name cannot be maximum of 250 characters")
    .nullable(),
  todayDate: Yup.string().required("Required field").nullable(),
  validDate: Yup.string().required("Required field").nullable(),
  quoteno: Yup.string().required("Required field").nullable(),
  projectname: Yup.string().required("Required field").nullable(),
  clientlocation: Yup.string().required("Required field").nullable(),
});

const ScratchDraggableSchema1 = Yup.object().shape({
  title: Yup.string().required("Required field").nullable(),
  description: Yup.string()
    .test("htmlstring", "Required field", (htmlString: any) => {
      return htmlString !== "<p><br></p>";
    })
    .required("Required field")
    .nullable(),
});

const ScratchDraggableSchema2 = Yup.object().shape({
  title: Yup.string().required("Required field").nullable(),
  costItems: Yup.array().of(SelectTemplateSchemaMobileField).nullable(),
});
const FinalSubmitBuildQuoteOwnSchema = () => {
  return Yup.object().shape({
    client: Yup.string().required("Client is required"),
    quoteValidDate: Yup.date()
      .required("Valid Until date is required")
      .min(moment().toISOString(), "minimum date reached")
      .max(moment().add(1, "years").toISOString(), "maximum date reached"),
    comments: Yup.string().max(250, "Maximum characters reached."),
    totalCost: Yup.number().moreThan(0, "number should be greater 0 than"),
  });
};

let EnquiryFormSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must contain only alphabets with no numbers and special characters allowed including space."
      )
      .min(
        3,
        "It should contain minimum 3 characters and maximum 50 characters."
      )
      .max(
        50,
        "It should contain minimum 3 characters and maximum 50 characters."
      )
      .required("Full Name is required"),

    email: Yup.string()
      .matches(/^\S*$/, "space is not allowed")
      .trim("space is not allowed")
      .email("Please enter a valid email")
      .required("Email cannot be blank")
      .nullable(),

    contactNumber: Yup.string()
      .required("Please enter your phone number")
      .matches(phoneRegExp, "Your Phone number is invalid")
      .min(10, "Should contain 10 digits")
      .max(10, "Should contain 10 digits"),
    enquiry: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Equiry Field is required"),
  });
};

const ProjectSupportSchema = () => {
  return Yup.object({
    projectName: Yup.string()
      .trim()
      .matches(
        /^[/^\S*$/ ]*$/,
        "Numbers and special character are not allowed in name field"
      )
      .min(3, "ProjectName must be minimum of three characters")
      .max(100, "ProjectName can be maximum of hundred characters")
      .required("ProjectName field cannot be blank")
      .nullable(),
    estimateStartDate: new Yup.DateSchema()
      .required("estimateStartDate is required")
      .nullable(),
    estimateEndDate: new Yup.DateSchema()
      .required("estimateEndDate is required")
      .nullable(),
    projectDescription: Yup.string().max(250, "max characters reached"),
    tradeType: Yup.array()
      .of(
        Yup.object().shape({
          qty: Yup.number()
            .min(1, "min values is one")
            .max(10, "max value")
            .required("qty is required"),
          type: Yup.mixed()
            .test("nullCheck", "trade is required", (value: any) => {
              if (value === null) {
                return false;
              }
              return true;
            })
            .required("trade is required."),
        })
      )
      .max(3, "Max limit reached"),
    files: Yup.array()
      .of(
        Yup.mixed().test(
          "fileSize",
          "The file is not in proper format or size ",
          (file: any) => {
            if (file.url) {
              return true;
            } else {
              return file.size <= 10 * 1024 * 1024;
            }
          }
        )
      )
      .max(5, "Maximum 5 file can be Uploaded"),
    invitedCompany: Yup.array()
      .min(1, "min 1")
      .max(1, "Maximum 1 service provider can be selected"),
  });
};

const EmergencyCalloutSchema = () => {
  return Yup.object({
    tradeType: Yup.array()
      .of(
        Yup.object().shape({
          qty: Yup.number()
            .min(1, "min values is one")
            .max(10, "max value")
            .required("qty is required"),
          type: Yup.mixed()
            .test("nullCheck", "trade is required", (value: any) => {
              if (value === null) {
                return false;
              }
              return true;
            })
            .required("trade is required."),
        })
      )
      .max(3, "Max limit reached"),
    projectDescription: Yup.string().max(250, "max characters reached"),
    invitedCompany: Yup.array()
      .min(1, "service provider is required")
      .max(1, "Maximum 1 service provider can be selected"),
  });
};

const timeFrameSchema = (isRecurringShift?: any) => {
  return Yup.object({
    shiftType: Yup.string().required("Shift type is required"),
    recurringShift: isRecurringShift
      ? Yup.boolean()
          .required("Recurring shift is required")
          .test(
            "sameDay",
            "Start date and end date should be different",
            (value: any, { parent }: any) => {
              if (parent?.startDate && parent?.endDate) {
                if (
                  moment(moment(parent?.startDate).format("YYYY-MM-DD")).isSame(
                    moment(moment(parent?.endDate).format("YYYY-MM-DD"))
                  )
                ) {
                  return false;
                }
              }

              return true;
            }
          )
          .oneOf([true], "Recurring shift is required")
      : Yup.boolean().test(
          "sameDay",
          "Start date and end date should be different",
          (value: any, { parent }: any) => {
            if (parent?.startDate && parent?.endDate && value) {
              if (
                moment(moment(parent?.startDate).format("YYYY-MM-DD")).isSame(
                  moment(moment(parent?.endDate).format("YYYY-MM-DD"))
                )
              ) {
                return false;
              }
            }

            return true;
          }
        ),
    days: Yup.mixed().test(
      "isEmpty",
      "Recurring days are required",
      (value: any, { parent, createError }: any) => {
        if (parent?.recurringShift && !value?.length) {
          return createError({ message: "Recurring days are required" });
        }

        if (parent?.recurringShift && value?.length) {
          if (parent?.shiftType === "WEEKEND") {
            let weekEndArr: any = [0, 6];
            let validate = true;

            value.forEach((el: any) => {
              if (!weekEndArr.includes(el)) {
                validate = false;
              }
            });

            if (!validate) {
              return createError({
                message: "Only weekend days can be selected",
              });
            }
          }

          let startDate = moment(
            moment(parent?.startDate)?.format("YYYY-MM-DD")
          );
          let endDate = moment(moment(parent?.endDate)?.format("YYYY-MM-DD"));

          let DatesCount: any = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
          };

          let DatesArr: any = [];

          while (!moment(startDate).isAfter(endDate)) {
            DatesCount[`${moment(startDate)?.day()}`] =
              DatesCount[`${moment(startDate)?.day()}`] + 1;

            startDate = startDate.add(1, "day");
          }

          for (let i in DatesCount) {
            if (DatesCount[i]) {
              DatesArr.push(Number(i));
            }
          }

          let validate: Boolean = true;

          value?.forEach((el: any) => {
            if (!DatesArr.includes(el)) {
              validate = false;
            }
          });

          if (!validate) {
            return createError({
              message: "Some days are not present in selected date range",
            });
          }

          return true;
        }

        return true;
      }
    ),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date().required("End date is required"),
    startTime: Yup.mixed()
      .required("Start time is required")
      .test("isNull", "Start time is required", (value: any) => {
        if (value === null) {
          return false;
        }
        return true;
      })
      .test("Invalid Time", "Invalid time", (value: any) => {
        if (value?.$d?.toString() === "Invalid Date") {
          return false;
        }
        return true;
      })
      .test(
        "after now",
        "Should be after now",
        (value: any, { parent }: any) => {
          if (
            moment(moment(parent?.startDate).format("yyyy-MM-DD")).isSame(
              moment(value)
            )
          ) {
            if (dayjs(value).isBefore(dayjs())) {
              return false;
            }
          }

          return true;
        }
      )
      .test(
        "shifts",
        "Invalid time slot",
        (value: any, { createError, parent }: any) => {
          if (!parent?.shiftType) {
            return createError({ message: "Shift type is required" });
          }
          if (parent?.shiftType === "DAY") {
            if (
              dayjs(value).isAfter(dayjs("06:00 AM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("02:00 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (parent?.shiftType === "AFTER_NOON") {
            if (
              dayjs(value).isAfter(dayjs("02:00 PM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("10:00 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (parent?.shiftType === "NIGHT") {
            let val;
            if (dayjs(value).get("hour") < 13) {
              val = dayjs(value).add(12, "hours");
            }
            if (dayjs(value).get("hour") > 12) {
              val = dayjs(value).subtract(12, "hours");
            }

            if (
              dayjs(val).isAfter(dayjs("10:00 AM", "HH:mm A")) &&
              dayjs(val).isBefore(dayjs("06:00 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (
            parent?.shiftType === "WEEKEND" ||
            parent?.shiftType === "PUBLIC_HOLIDAY"
          ) {
            if (
              dayjs(value).isAfter(dayjs("06:00 AM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("08:00 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          return true;
        }
      ),
    endTime: Yup.mixed()
      .required("End time is required")
      .test("isNull", "End time is required", (value: any) => {
        if (value === null) {
          return false;
        }
        return true;
      })
      .test("Invalid Time", "Invalid time", (value: any) => {
        if (value?.$d?.toString() === "Invalid Date") {
          return false;
        }
        return true;
      })
      .test(
        "Same Time",
        "End time should be different",
        (value: any, { parent, createError }: any) => {
          if (!parent?.startTime) {
            return createError({ message: "Start time is required" });
          }

          if (parent?.shiftType === "NIGHT") {
            let val, startTime;
            if (dayjs(value).get("hour") < 13) {
              val = dayjs(value).add(12, "hours");
            }
            if (dayjs(value).get("hour") > 12) {
              val = dayjs(value).subtract(12, "hours");
            }
            if (dayjs(parent?.startTime).get("hour") < 13) {
              startTime = dayjs(parent?.startTime).add(12, "hours");
            }
            if (dayjs(parent?.startTime).get("hour") > 12) {
              startTime = dayjs(parent?.startTime).subtract(12, "hours");
            }

            if (
              moment(moment(parent?.startDate).format("YYYY-MM-DD")).isSame(
                moment(moment(parent?.endDate).format("YYYY-MM-DD"))
              )
            ) {
              let valStr = dayjs(val).format("hh:mm A").split(" ")[1];
              let startTimeStr = dayjs(startTime)
                .format("hh:mm A")
                .split(" ")[1];
              if (valStr !== startTimeStr) {
                return createError({ message: "Date should not be same" });
              }
            }

            if (dayjs(val)?.isSame(dayjs(startTime))) {
              return createError({
                message: "Start time and End time should not be same",
              });
            }

            if (dayjs(val).isBefore(dayjs(startTime))) {
              return createError({
                message: "End time should be after start time",
              });
            }

            if (dayjs(val).diff(dayjs(startTime), "minutes") < 59) {
              return createError({
                message: "Please select minimum 1 hour duration",
              });
            }
          } else {
            if (dayjs(value)?.isSame(dayjs(parent?.startTime))) {
              return createError({
                message: "Start time and End time should not be same",
              });
            }

            if (dayjs(value).isBefore(dayjs(parent?.startTime))) {
              return createError({
                message: "End time should be after start time",
              });
            }

            if (dayjs(value).diff(dayjs(parent?.startTime), "minutes") < 59) {
              return createError({
                message: "Please select minimum 1 hour duration",
              });
            }
          }

          return true;
        }
      )
      .test(
        "shifts",
        "Invalid time slot",
        (value: any, { createError, parent }: any) => {
          if (!parent?.shiftType) {
            return createError({ message: "Shift type is required" });
          }
          if (parent?.shiftType === "DAY") {
            if (
              dayjs(value).isAfter(dayjs("05:59 AM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("02:01 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (parent?.shiftType === "AFTER_NOON") {
            if (
              dayjs(value).isAfter(dayjs("01:59 PM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("10:01 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (parent?.shiftType === "NIGHT") {
            let val;
            if (dayjs(value).get("hour") < 13) {
              val = dayjs(value).add(12, "hours");
            }
            if (dayjs(value).get("hour") > 12) {
              val = dayjs(value).subtract(12, "hours");
            }

            if (
              dayjs(val).isAfter(dayjs("10:00 AM", "HH:mm A")) &&
              dayjs(val).isBefore(dayjs("06:01 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (parent?.shiftType === "WEEKEND") {
            if (
              dayjs(value).isAfter(dayjs("05:59 AM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("08:01 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }
          if (parent?.shiftType === "PUBLIC_HOLIDAY") {
            if (
              dayjs(value).isAfter(dayjs("05:59 AM", "HH:mm A")) &&
              dayjs(value).isBefore(dayjs("08:01 PM", "HH:mm A"))
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      ),
  });
};

const Schema = {
  LoginSchema,
  SignupSchema,
  ChangePasswordScheema,
  CompanyContactSchema,
  MobileNumber,
  BusinessDetailSchema,
  SoleTraderSchema,
  ForgotPassWordSchema,
  ResetPasswordSchema,
  AddTeamMemberSchema,
  HomeSchema,
  MyDetailsSchema,
  BusinessProfileSchema,
  StandardRatesCreateSchema,
  SelectTemplateSchema,
  SelectTemplateSchemaMobile,
  SelectTemplateSchemaMobileField,
  DiscountRateSchema,
  ClientScratchSchema,
  ScratchDraggableSchema1,
  ScratchDraggableSchema2,
  FinalSubmitBuildQuoteOwnSchema,
  EnquiryFormSchema,
  ProjectSupportSchema,
  EmergencyCalloutSchema,
  timeFrameSchema,
};

export default Schema;
