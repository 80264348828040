import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

type ButtonOutlinedProps = {
  children: React.ReactNode;
  icon: boolean;
  editValue: boolean;
  downloadValue: boolean;
} & ButtonProps;

const ButtonCustomize = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '1px solid #333333',
  textTransform: 'capitalize',
  padding: theme.spacing(0.8, 1.5),
  font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
    1.4
  )} Segoe UI, sans-serif`,
  color: 'white',
  borderRadius: 'unset',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#333333',
  },
}));

function ButtonOutlinedBlack({
  children,
  icon,
  editValue,
  downloadValue,
  ...otherProps
}: ButtonOutlinedProps) {
  return (
    <>
      {icon ? (
        editValue ? (
          downloadValue ? (
            <ButtonCustomize
              startIcon={
                <DownloadIcon style={{ color: 'white', fontSize: 'small' }} />
              }
              {...otherProps}
            >
              {children}
            </ButtonCustomize>
          ) : (
            <ButtonCustomize
              startIcon={
                <EditIcon style={{ color: 'white', fontSize: 'small' }} />
              }
              {...otherProps}
            >
              {children}
            </ButtonCustomize>
          )
        ) : (
          <ButtonCustomize endIcon={<ArrowDropDownIcon />} {...otherProps}>
            {children}
          </ButtonCustomize>
        )
      ) : (
        <ButtonCustomize {...otherProps}>{children}</ButtonCustomize>
      )}
    </>
  );
}

export default ButtonOutlinedBlack;
