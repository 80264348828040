import { ProfileDetailsModel } from "../../../../model";
import Utils from "../../../../Utils";

export const BusinessProfileReducer = (
  state = {
    allActivties: "",
    businessProfilePicture: "",
    businessName: "",
    abnNumber: "",
    availableTrades: [],
    companyJobNotificationSetting:[],
    companyTeamNotificationSetting:[],
    industryExp: [],
    businessTagline: "",
    images: [],
    siteLocation: null,
    additionalInfo: "",
    services: "",
  },
  action: any
) => {
  switch (action.type) {
    case "GET_CURRENT_BUSINESS_PROFILE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const profiledetailsdataReducer = (
  state: ProfileDetailsModel = new ProfileDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PROFILE_DETAILS_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ImageReducer = (
  state = {
    businessName: "",
    abnNumber: "",
    businessProfilePicture: null,
    businessProfilePictureUrl: "",
    industryExp: [],
    availableTrades: [],
    images: [],
    businessTagline: "",
    additionalInfo: "",
    location: null,
    uploadedImages: [],
    updated: false,
    services: "",
  },
  action: any
) => {
  switch (action.type) {
    case "UPDATE_IMAGE_PENDING":
      return {
        ...state,
        ...action.payload,
        updated: false,
      };
    case "UPDATE_IMAGE_SUCCESS":
      return {
        ...state,
        ...action.payload,
        updated: true,
      };

    default:
      return state;
  }
};

export const getBusinessProfileState = (state: any) =>
  state.BusinessProfileReducer;

export const getImagesState = (state: any) => state.ImageReducer;
