import { Theme, useTheme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useClassStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    chip: {},

    modalHeader:{
      padding:"30px",
      alignItems:"center",
      width:"100%",
      display:"flex",
      justifyContent:"space-between",
    },
  }),
);

export const useSxStyles = (): { [key: string]: React.CSSProperties } => {
  const theme = useTheme();
  return {
    assignBtn: {
      width: "7.76rem",
      marginRight: "1rem",
      zIndex: "10px",
    },
  };
};
