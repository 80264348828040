import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { styled } from "@mui/system";
  import SearchIcon from "@mui/icons-material/Search";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import { useDispatch, useSelector } from "react-redux";
  import LocalImages from "../../../../../Utils/localImages";
  import { getExternal } from "../../action";
  import { getExternalReducerState } from "../../reducer";
  import ViewAllDeskTop from "./DekstopView";
  import ViewAllMobile from "./DekstopView";
  import NoRequests from "../../common/NoRequests";
  import Filter from "../common/Filter";
  import MultipleSelectCheckmarks from "../../../../../components/mutipleselectCheckmark";
  import CloseIcon from "@mui/icons-material/Close";
  
  const { filterViewAll } = LocalImages;
  
  // const BoxContainer = styled(Box)(({ theme }) => ({
  //   padding: "2rem 10.5rem 2rem 10.5rem",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "2rem 1.5rem 2rem 1.5rem",
  //   },
  // }));
  
  const TitleBar = styled(Box)({
    padding: "1.5rem 1.5rem 1.62rem 1.5rem",
  });
  
  const PrimaryBlackTypoGraphy = styled(Typography)({
    color: "#4f4f4f",
  });
  const SecondaryBlackTypoGraphy = styled(Typography)({
    color: "#828282",
  });
  
  const TertiaryBlackTypoGraphy = styled(Typography)({
    color: "#344054",
  });
  
  const StyledTypography = styled(Typography)({
    fontWeight: 400,
    fontSize: ".87rem",
    color: "#828282",
  });
  
  const SubmitButton = styled(Button)(({ theme }) => ({
    // padding: ".9rem 2.2rem",
    background: "#0da4ce",
    "&:hover": {
      background: "#0da4ce",
    },
    color: "#fff",
    borderRadius: "0",
    textTransform: "initial",
  }));
  
  const FilterButton = styled(Button)(({ theme }) => ({
    padding: ".9rem 2.2rem",
    background: "#ffffff",
    "&:hover": {
      background: "#f2f2f2",
    },
    border: "1px solid #bdbdbd",
    color: "#4f4f4f",
    borderRadius: "0",
    textTransform: "initial",
    marginLeft: "2rem",
  }));
  
  const FilterIconButton = styled(IconButton)(({ theme }) => ({
    background: "#f2f2f2",
    "&:hover": {
      background: "#f2f2f2",
    },
    border: "1px solid #bdbdbd",
    color: "#4f4f4f",
    textTransform: "initial",
    marginLeft: "2rem",
  }));
  
  const PaginationButton = styled(Button)(({ theme }) => ({
    // padding: ".9rem 2.2rem",
    background: "#fff",
    "&:hover": {
      background: "#fff",
    },
    display: "flex",
    alignItems: "center",
    color: "#828282",
    borderRadius: "0",
    textTransform: "initial",
    marginLeft: "2rem",
  }));
  
  const filterData: any = {
    All: "ALL",
    Requested: "REQUESTED",
    Assigned: "ASSIGNED",
    "In Progress": "IN_PROGRESS",
    Pending:"PENDING"
  };
  
  const queryBuilderFunc = (queryData: any) => {
    let queryStr = "?";
    for (let k in queryData) {
      if (queryData[k]) {
        if (Array.isArray(queryData[k])) {
          let str = "";
          queryData[k]?.forEach((el: any) => {
            str = str + `${k}=${filterData[el]}&`;
          });
          queryStr = queryStr + str;
        } else {
          queryStr = queryStr + `${k}=${queryData[k]}&`;
        }
      }
    }
    queryStr = queryStr.slice(0, -1);
    return queryStr;
  };
  
  const ViewExternalJobs = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [firstRender, setFirstRender] = useState(true);
    const { ExternalList, totalPage, total } = useSelector(getExternalReducerState);
    const [filter, setFilter] = useState(false);
    const theme = useTheme();
    const [status, setStatus] = useState([
    "All",
    "Requested",
    "Assigned",
    "In Progress",
    "Pending"
    ]);
  
    const dispatch = useDispatch();
  
    const isSmallScreen = useMediaQuery("(max-width:900px)");
  
    useEffect(() => {
      let queryStr = queryBuilderFunc({
        companyId: localStorage.getItem("user_id"),
        pageNo: 1,
        limit,
        requestType: "",
        searchKey: "",
        status,
      });
      dispatch(getExternal(queryStr));
    }, []);
  
    useEffect(() => {
      if (firstRender) {
        setFirstRender(false);
        return;
      }
      let queryStr = queryBuilderFunc({
        companyId: localStorage.getItem("user_id"),
        pageNo: 1,
        limit,
        requestType: "",
        searchKey: search,
        status,
      });
  
      if (page > 1) {
        setFirstRender(true);
        setPage(1);
      }
      dispatch(getExternal(queryStr));
    }, [search]);
  
    useEffect(() => {
      if (firstRender) {
        setFirstRender(false);
        return;
      }
      let queryStr = queryBuilderFunc({
        companyId: localStorage.getItem("user_id"),
        pageNo: page,
        limit,
        requestType: "",
        searchKey: search,
        status,
      });
      dispatch(getExternal(queryStr));
    }, [page, status]);
  
    return (
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #e0e0e0",
          marginTop: "1.125rem",
        }}
      >
        <Stack
          spacing={2}
          sx={{
            paddingX: "1.625rem",
            paddingY: "1.125rem",
            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              onChange={(e: any) => {
                if (e.target.value === "") {
                  setSearch(e.target.value);
                }
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              sx={{
                "& input": {
                  background: "#fff",
                  paddingY: 0,
                  width: "16rem",
                  height: "2.75rem",
                },
  
                "& fieldset": {
                  borderRadius: 0,
                  border: "1px solid #bdbdbd !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchInput ? (
                      <IconButton
                        onClick={() => {
                          setSearch("");
                          setSearchInput("");
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              placeholder={"Search"}
            />
            <SubmitButton
              onClick={() => {
                setSearch(searchInput);
              }}
              sx={{
                width: "2.75rem",
                height: "2.75rem",
              }}
            >
              <SearchIcon sx={{ color: "#fff" }} />
            </SubmitButton>
  
            {isSmallScreen ? (
              <FilterIconButton
                onClick={() => {
                  setFilter(!filter);
                }}
              >
                <img src={filterViewAll} alt="filter" />
              </FilterIconButton>
            ) : (
              <FilterButton
                onClick={() => {
                  setFilter(!filter);
                  setPage(1);
                }}
                sx={{
                  height: "2.75rem",
                }}
              >
                <img
                  src={filterViewAll}
                  alt="filter"
                  style={{
                    marginRight: ".46rem",
                  }}
                />
                Filters
              </FilterButton>
            )}
          </Box>
  
          {filter ? (
            !isSmallScreen ? (
              <Stack direction="row" spacing={2} alignItems="center">
                <MultipleSelectCheckmarks
                  data={status}
                  setData={setStatus}
                  CheckBoxData={Object.keys(filterData)}
                  placeholder={"Status"}
                  style={{ minWidth: "200px", height: "40px" }}
                />
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setStatus([]);
                  }}
                >
                  Clear all filters
                </Typography>
              </Stack>
            ) : (
              <Filter
                open={filter}
                setOpen={setFilter}
                CheckBoxData={Object.keys(filterData)}
                data={status}
                setData={setStatus}
              />
            )
          ) : null}
        </Stack>
        {!isSmallScreen ? (
          <ViewAllDeskTop data={ExternalList} />
        ) : (
          <ViewAllMobile data={ExternalList} />
        )}
  
        <Stack
          sx={{
            padding: "2.1rem 2.3rem 1.68rem 2.3rem",
            [theme.breakpoints.down("md")]: {
              padding: "1.76rem",
            },
          }}
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          {!isSmallScreen ? (
            <Box>
              {page !== 1
                ? `${limit * page + 1 - limit} - ${
                    limit * page > total ? total : limit * page
                  }`
                : `${total ? "1" : "0"} - ${limit > total ? total : limit}`}{" "}
              of {total} Job Postings
            </Box>
          ) : null}
  
          <Stack
            direction="row"
            sx={{
              [theme.breakpoints.down("md")]: {
                justifyContent: "space-between",
                flex: 1,
              },
            }}
            spacing={2}
          >
            <>
              <PaginationButton
                onClick={() => {
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                  }
                }}
                disabled={page === 1}
              >
                <ArrowBackIcon sx={{ marginRight: "12px" }} />
                Previous
              </PaginationButton>
  
              {!isSmallScreen ? (
                <Box
                  sx={{
                    // height: "1.25rem",
                    width: "1.25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "2px solid #333333",
                  }}
                >
                  {page}
                </Box>
              ) : (
                <Box
                  sx={{
                    // height: "1.25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {page !== 1
                    ? `${limit * page + 1 - limit} of ${
                        limit * page > total ? total : limit * page
                      }`
                    : `${total ? "1" : "0"} of ${
                        limit > total ? total : limit
                      }`}{" "}
                </Box>
              )}
  
              <PaginationButton
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
                disabled={page === totalPage}
              >
                Next
                <ArrowForwardIcon sx={{ marginLeft: "12px" }} />
              </PaginationButton>
            </>
          </Stack>
        </Stack>
      </Box>
    );
  };
  
  export default ViewExternalJobs;
  