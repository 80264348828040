import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import GlobalLoader from "../../components/globalLoader";
import {
  getStartedToolTipOpen,
  globalLoaderEnd,
  hideGetStartedTooltipPermanent,
} from "../../reducer/globalReducer";
import Utils from "../../Utils";
import ActionName from "../../Utils/actionName";
import MainContainer from "../mainContainer/index";

interface Props {
  path: string;
  component: any;
  exact?: boolean;
}
const PrivateRoute = ({ component: Component, exact, ...rest }: Props) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const currentUser = () => {
    Utils.API.getApiCall(
      Utils.endpoint.currentUser,
      "",
      (data: any) => {
        localStorage.setItem("pdays", data?.data?.data?.paymentDays);
        // for get started checkbox Section

        let isCompanyProfileComplete =
          data?.data?.data?.isCompanyProfileComplete || false;
        let isCreateRateCard = data?.data?.data?.isCreateRateCard || false;
        let isAddEmployee = data?.data?.data?.isAddEmployee || false;
        let isRecevivedFirstReq =
          data?.data?.data?.isRecevivedFirstReq || false;

        if (data?.data?.data?.userType === "COMPANY") {
          if (localStorage.getItem("roles")) {
            isCreateRateCard = data?.data?.data?.companyDetail?.isAddEmployee;
            isAddEmployee = data?.data?.data?.companyDetail?.isCreateRateCard;
            isRecevivedFirstReq =
              data?.data?.data?.companyDetail?.isRecevivedFirstReq;
          }
          if (
            isCompanyProfileComplete &&
            isCreateRateCard &&
            isAddEmployee &&
            isRecevivedFirstReq &&
            !data?.data?.data?.toolTip
          ) {
            dispatch(hideGetStartedTooltipPermanent());
          }
          dispatch({
            type: ActionName.GETSTARTED_STEPS_COMPANY,
            payload: {
              isShow: true,
              isCompanyProfileComplete,
              isCreateRateCard,
              isAddEmployee,
              isRecevivedFirstReq,
            },
          });
          dispatch({
            type: ActionName.GETSTARTED_STEPS_SOLETRADER,
            payload: {
              isShow: false,
              isCompanyProfileComplete: false,
              isCreateRateCard: false,
              isRecevivedFirstReq: false,
            },
          });
        }

        if (data?.data?.data?.userType === "COMPANYSOLETRADER") {
          if (
            isCompanyProfileComplete &&
            isCreateRateCard &&
            isRecevivedFirstReq &&
            !data?.data?.data?.toolTip
          ) {
            dispatch(hideGetStartedTooltipPermanent());
          }
          dispatch({
            type: ActionName.GETSTARTED_STEPS_SOLETRADER,
            payload: {
              isShow: true,
              isCompanyProfileComplete,
              isCreateRateCard,
              isRecevivedFirstReq,
            },
          });
          dispatch({
            type: ActionName.GETSTARTED_STEPS_COMPANY,
            payload: {
              isShow: false,
              isCompanyProfileComplete: false,
              isCreateRateCard: false,
              isAddEmployee: false,
              isRecevivedFirstReq: false,
            },
          });
        }
      },
      (err: any) => {
        console.log("error", err);
        if (err?.statusCode === 401) {
          localStorage.clear();
          Utils.showAlert(2, "Session Expired");
          history.push("/");
          dispatch(globalLoaderEnd());
        } else if (err?.statusCode === 500) {
          localStorage.clear();
          Utils.showAlert(2, "Session Expired");
          history.push("/");
          dispatch(globalLoaderEnd());
        }
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      currentUser();
    }
  }, [pathname]);

  return (
    <>
      <GlobalLoader />
      <Route
        exact={Boolean(exact)}
        {...rest}
        render={(props) => (
          <React.Fragment>
            {localStorage.getItem("accessToken") ||
            sessionStorage.getItem("verify_access_Token") ? (
              <MainContainer>
                <Suspense fallback={""}>
                  <Component {...props} />
                </Suspense>
              </MainContainer>
            ) : (
              <Redirect to="/" />
            )}
          </React.Fragment>
        )}
      />
    </>
  );
};
export default PrivateRoute;
