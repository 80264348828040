// @ts-ignore
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import React, { useState } from "react";
// @ts-ignore
import { Waypoint } from "react-waypoint";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  styled,
  Link,
} from "@mui/material";
import GlobalLoader from "../../../components/globalLoader";
import LocalImages from "../../../Utils/localImages";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";

const { downloadBqOwn, edit } = LocalImages;

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#333",
  "&:hover": {
    background: "#333",
  },
  color: "#fff",
  borderRadius: "0",
  textTransform: "initial",
  paddingY: "9px",
}));

const PreviewPage = ({ link, handleEdit, isDraft }: any) => {
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const zoomOut = () => {
    if (scale === 0.1) return;
    let num = Number(parseFloat(`${scale - 0.1}`).toFixed(2));
    setScale(num);
  };

  const zoomIn = () => {
    if (scale === 1) return;
    let num = Number(parseFloat(`${scale + 0.1}`).toFixed(2));
    setScale(num);
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setPages(numPages);
    setPageNumber(1);
  }

  if (!link) {
    return <></>;
  }

  return (
    <Box sx={{ height: "85vh", background: "#828282" }}>
      <Stack
        sx={{
          height: "10%",
          background: "#4f4f4f",
          alignItems: "center",
          paddingX: "1rem",
        }}
        justifyContent="space-between"
        direction="row"
      >
        <Typography color="#fff">Preview</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            sx={{
              color: "#fff",
              borderRight: "1px solid #fff",
              paddingX: "33px",
            }}
          >
            {pageNumber} / {pages}
          </Stack>
          <Stack
            sx={{
              color: "#fff",
            }}
            spacing={1}
            direction={"row"}
            alignItems="center"
          >
            <Button
              sx={{
                minWidth: "10px",
                width: "10px",
                color: "#fff",
              }}
              onClick={zoomOut} 
            >
              -
            </Button>
            <Typography
              sx={{
                background: "#333",
                padding: "5px 15px",
              }}
            >
              {scale * 100}%
            </Typography>

            <Button
              sx={{
                minWidth: "10px",
                width: "10px",
                color: "#fff",
              }}
              onClick={zoomIn}
            >
              +
            </Button>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <StyledButton
            sx={{
              fontSize: ".9rem",
              fontWeight: 600,
            }}
            disabled={!isDraft}
            onClick={handleEdit}
          >
            <EditIcon
              sx={{
                width: 13,
                height: 13,
                marginRight: ".5rem",
              }}
            />
            Replace
          </StyledButton>
          <StyledButton
            sx={{
              fontSize: ".9rem",
              fontWeight: 600,
            }}
          >
            <DownloadIcon
              sx={{
                width: 13,
                height: 13,
                marginRight: ".5rem",
              }}
            />
            <Link
              href={link}
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "inherit",
              }}
              download
            >
              Download
            </Link>
          </StyledButton>
        </Stack>
      </Stack>

      <Box
        sx={{
          height: "90%",
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Document
          file={link}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error: any) => console.log("Inside Error", error)}
          loading={
            <Stack height="50vh" justifyContent={"center"} alignItems="center">
              <CircularProgress color="inherit" />
            </Stack>
          }
        >
          {Array.apply(null, Array(pages))
            .map((x, i) => i + 1)
            .map((page, i) => (
              <Waypoint
                topOffset="15%"
                // bottomOffset="49%"
                onEnter={() => {
                  setPageNumber(i + 1);
                }}
                key={i}
              >
                <Box marginTop=".7rem">
                  <Page pageNumber={page} scale={scale} loading={""} />
                </Box>
              </Waypoint>
            ))}
        </Document>
      </Box>
    </Box>
  );
};
export default PreviewPage;
