import Utils from "../../../../Utils";
import { scheduleBookingModel } from "../../../../model";

export const scheduleBookingReducer = (
  state: scheduleBookingModel = new scheduleBookingModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SCHEDULE_BOOKING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};