import AWS from "aws-sdk";
import AwsCred from "./AWSCred";

const { bucketAccessKey, bucketSecretKey, bucketRegion, BUCKETNAME } = AwsCred;

AWS.config.update({
  accessKeyId: bucketAccessKey,
  secretAccessKey: bucketSecretKey,
});

const myBucket = new AWS.S3({
  params: { Bucket: BUCKETNAME },
  region: bucketRegion,
});

export const uploadFile = async (file: any) => {
  const params: any = {
    ACL: "public-read",
    Body: file,
    Bucket: BUCKETNAME,
    Key: file.name,
    ContentType: file.type,
  };

  return new Promise((resolve, reject) => {
    myBucket
      .upload(params, (err: any, data: any) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data);
        }
      })
      .on("httpUploadProgress", (evt) => {
        //   setProgress(Math.round((evt.loaded / evt.total) * 100));
      });
    //   .send((err) => {
    //     if (err) console.log(err);
    //   });
  });
};
