// @ts-ignore 
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import React, { useState } from "react";
// @ts-ignore
import { Waypoint } from "react-waypoint";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LocalImages from "../../../Utils/localImages";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  styled,
  Link,
  Menu,
  MenuItem,
  menuClasses,
  Divider,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import GlobalLoader from "../../../components/globalLoader";
import { Edit } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import SpPreviewEditModalMobile from "./SpPreviewEditModalMobile";

const { downloadBqOwn } = LocalImages;

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#333",
  "&:hover": {
    background: "#333",
  },
  color: "#fff",
  borderRadius: "0",
  textTransform: "initial",
  paddingY: "9px",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  padding: "1.2rem 2.2rem",
  background: "#0da4ce",
  "&:hover": {
    background: "#0da4ce",
  },
  color: "#fff",
  borderRadius: "0",
  textTransform: "initial",
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333",
  },
}));

const PreviewMobile = ({ link, handleEdit, finalSubmitOpen, isDraft }: any) => {
  const [pages, setPages] = useState(null);

  const [editOpen, setEditOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [scale, setScale] = useState(1);

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (param: any) => {
    setAnchorEl(null);
    setEditOpen(false);
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setPages(numPages);
    setPageNumber(1);
  }

  if (!link) {
    return <></>;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        background: "#828282",
        position: "absolute",
        width: "100%",
        top: 0,
        zIndex: 1000,
      }}
    >
      <Stack
        sx={{
          height: "10%",
          background: "#4f4f4f",
          alignItems: "center",
          paddingX: "31px",
        }}
        justifyContent="space-between"
        direction="row"
      >
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeftIcon
            sx={{
              color: "#fff",
              fontSize: "1.6rem",
            }}
          />
        </IconButton>

        <Typography
          sx={{
            color: "#fff",
            fontSize: "1.1rem",
            fontWeight: 500,
          }}
        >
          Quote Preview
        </Typography>

        <IconButton onClick={handleMenu}>
          <MoreHorizIcon
            sx={{
              color: "#fff",
              fontSize: "1.6rem",
            }}
          />
        </IconButton>

        <Menu
          sx={{
            zIndex: 1000,
            [`.${menuClasses.paper}`]: {
              boxShadow: "none",
              border: "1px solid #e0e0e0",
              borderRadius: 0,
            },
          }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {isDraft ? (
            <StyledTooltip
              open={editOpen}
              title={<SpPreviewEditModalMobile handleClose={handleClose} />}
            >
              <MenuItem
                onClick={() => {
                  setEditOpen(!editOpen);
                }}
              >
                <Edit
                  sx={{
                    marginRight: ".7rem",
                    fontSize: "1.2rem",
                  }}
                />
                Edit
              </MenuItem>
            </StyledTooltip>
          ) : null}

          <Divider />
          <MenuItem onClick={handleClose}>
            <img
              src={downloadBqOwn}
              style={{ marginRight: "10px" }}
              alt="download"
            />
            <Link
              href={link}
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "inherit",
              }}
              download
            >
              Download
            </Link>
          </MenuItem>
        </Menu>

        {/* <Typography color="#fff">Preview</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            sx={{
              color: "#fff",
              borderRight: "1px solid #fff",
              paddingX: "33px",
            }}
          >
            {pageNumber} / {pages}
          </Stack>
          <Stack
            sx={{
              color: "#fff",
            }}
            spacing={1}
            direction={"row"}
            alignItems="center"
          >
            <Button
              sx={{
                minWidth: "10px",
                width: "10px",
                color: "#fff",
              }}
              onClick={zoomOut}
            >
              -
            </Button>
            <Typography
              sx={{
                background: "#333",
                padding: "5px 15px",
              }}
            >
              {scale * 100}%
            </Typography>

            <Button
              sx={{
                minWidth: "10px",
                width: "10px",
                color: "#fff",
              }}
              onClick={zoomIn}
            >
              +
            </Button>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <StyledButton onClick={handleEdit}>Edit</StyledButton>
          <StyledButton>
          
          </StyledButton>
        </Stack> */}
      </Stack>

      <Box
        sx={{
          height: "90%",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Document
          file={link}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error:any) => console.log("Inside Error", error)}
          loading={
            <Stack height="50vh" justifyContent={"center"} alignItems="center">
              <CircularProgress color="inherit" />
            </Stack>
          }
        >
          {Array.apply(null, Array(pages))
            .map((x, i) => i + 1)
            .map((page, i) => (
              <Waypoint
                topOffset="15%"
                // bottomOffset="49%"
                onEnter={() => {
                  setPageNumber(i + 1);
                }}
                key={i}
              >
                <Box marginTop=".7rem">
                  <Page pageNumber={page} scale={scale} loading={""} />
                </Box>
              </Waypoint>
            ))}
        </Document>
      </Box>
      <Stack
        sx={{
          position: "absolute",
          zIndex: 1000,
          bottom: "2rem",
          width: "100%",
        }}
        direction="row"
        justifyContent="center"
      >
        {isDraft ? (
          <SubmitButton
            sx={{
              width: "calc(100% - 2*1.5rem)",
            }}
            onClick={finalSubmitOpen}
          >
            Next
          </SubmitButton>
        ) : null}
      </Stack>
    </Box>
  );
};
export default PreviewMobile;
