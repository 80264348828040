import {
  Box,
  Breadcrumbs,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PreviewPage from "./Preview";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getBuildQuoteOwnState } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { GetBqOwnDetails } from "../actions";
import SpOwnModal from "../SpCreate/SpOwnModal";
import { StyledButton } from "../../../components/styledComponents/StyledButton";
import SpOwnPreviewModal from "./SpOwnPreviewModal";
import FinalSubmitModal from "./FinalSubmitModal";
import PreviewMobile from "./PreviewMobile";
import FinalSubmitModalMobile from "./FinalSubmitModalMobile";
import Utils from "../../../Utils";

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "1rem 10.5rem",
}));

const SPownPreview = () => {
  const BqOwnState = useSelector(getBuildQuoteOwnState);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [editOpen, setEditOpen] = useState(false);

  const [finalSubmitOpen, setFinalSubmitOpen] = useState(false);

  const { state }: any = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = () => {
    setEditOpen(true);
  };

  useEffect(() => {
    if (!state) {
      history.push("/");
    }
    dispatch(GetBqOwnDetails(state?.jobId));
  }, []);

  if (isSmallScreen) {
    return (
      <>
        <PreviewMobile
          link={BqOwnState?.downloadUrl}
          finalSubmitOpen={() => {
            setFinalSubmitOpen(true);
          }}
          isDraft={BqOwnState?.isDraft}
        />
        <FinalSubmitModalMobile
          editModalOpen={setEditOpen}
          open={finalSubmitOpen}
          setOpen={setFinalSubmitOpen}
        />
      </>
    );
  }

  return (
    <BoxContainer>
      <Breadcrumbs
        sx={{ marginBottom: "1rem" }}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Typography
          sx={{
            color: "var(--outlineColor)",
            fontWeight: 500,
            fontSize: "1.2rem",
          }}
        >
          Quote Builder
        </Typography>
      </Breadcrumbs>
      <PreviewPage
        link={BqOwnState?.downloadUrl}
        handleEdit={handleEdit}
        isDraft={BqOwnState?.isDraft}
      />

      {BqOwnState?.isDraft ? (
        <Stack
          justifyContent={"space-between"}
          direction="row"
          marginTop="1rem"
        >
          <StyledButton
            color="#4f4f4f"
            border="1px solid #e0e0e0"
            padding="6px 14px"
            fullWidth={false}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </StyledButton>
          <Stack direction="row" spacing={2}>
            <StyledButton
              color="#4f4f4f"
              border="1px solid #e0e0e0"
              padding="6px 14px"
              fullWidth={false}
              onClick={() => {
                history.push(Utils.Pathname.ViewAllRequest);
              }}
            >
              Save as draft
            </StyledButton>
            <StyledButton
              color="#fff"
              border="1px solid #e0e0e0"
              padding="6px 14px"
              background="#0da4ce"
              fullWidth={false}
              onClick={() => {
                setFinalSubmitOpen(true);
              }}
            >
              Submit
            </StyledButton>
          </Stack>
        </Stack>
      ) : null}

      <SpOwnPreviewModal open={editOpen} setOpen={setEditOpen} />
      <FinalSubmitModal
        editModalOpen={setEditOpen}
        open={finalSubmitOpen}
        setOpen={setFinalSubmitOpen}
      />
    </BoxContainer>
  );
};

export default SPownPreview;
