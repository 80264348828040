import React, { useState } from "react";
import { styled } from "@mui/system";
import moment from "moment";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  useTheme,
  Box,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import LocalImages from "../../../../../../Utils/localImages";
import { capitalizeFirstLetter, getNotificationTimeStatus } from "../../../../../../Utils/commonFunctions";
import { useHistory } from "react-router-dom";
import Utils from "../../../../../../Utils";
import NoRequests from "../../../common/NoRequests";
import {
    checkTimeRange,
    getMomentDate,
} from "../../../../../../Utils/commonFunctions";
import { useStyles } from "../../../../settings/team/style";
import { useSxStyles } from "./style";
import { useDispatch } from "react-redux";
import { PendingChip, RfqCancelledChip, RfqCompletedChip, RfqSentChip} from "../../common/styledComponent";
import EnquireModal from "../../../Requests/maintainance/enquireModal";

const { rectangle, verticalDots } = LocalImages;

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  fontSize: "14px",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
  fontWeight: 400,
  fontSize: "12px",
});

const StyledButton = styled(Button)({
  textTransform: "initial",
  border: "1px solid #9EDBEB",
  color: "#097390",
  fontSize: ".875rem",
  fontWeight: 500,
  borderRadius: 0,
  padding: ".688rem 1.35rem",
  "&:hover": {
    border: "1px solid #9EDBEB",
    color: "#097390",
  },
});

// const getMonth = (date: any) => {
//   return `${moment(date).format("MMM")} ${moment(date).format("D")}, ${moment(
//     date
//   ).format("YYYY")}`;
// };

const ExternalTableDesktop = ({ data = [] }: any) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const sxStyle = useSxStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [jobId, setJobId] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isTeamUp, setIsTeamUp]=useState(false);
  const [jobData, setJobData] = React.useState<any>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setJobId("");
    setAnchorEl(null);
  };
  const enquireHandler = (jobId: any) => (e: any) => {
    setModalOpen(true);
  };

  const routeToDetailHandler = (jobId: any) => (e: any) => {
      setIsTeamUp(true)
    e?.stopPropagation();
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`);
  };

  const underDev = () => {
    history.push(Utils.Pathname.NAVBAR.AsCompany.roster);
  };

  console.log(data[0], "dataExternal")
  return (
    <>
      <TableContainer
        className="disableScrollbar"
        sx={{
          marginTop: "1rem",
          height: data.length ? "790px" : "auto",
          overflowY: "auto",
        }}
      >
        <Table
          sx={{
            "& thead": {
              background: "rgba(240, 240, 240, 0.2)",
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              "& th": {
                fontWeight: 400,
                fontSize: ".75rem",
                minheight: "2.25rem",
                color: "#828282",
                paddingY: ".625rem",
              },
            },
            "& tbody": {
              background: "#fff",

              "& tr": {
                paddingY: "1.5rem",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Date Sent</TableCell>
              {/* <TableCell>Client</TableCell> */}
              <TableCell>Request Details</TableCell>
              <TableCell>Providers</TableCell>
              <TableCell>Date Needed</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ?
            data?.map((el:any)=>{
                
                return(
                    <TableRow
                    sx={{
                        cursor: "pointer",
                      }}
                      key={el._id}
                    >
                        <TableCell onClick={routeToDetailHandler(el?.teamCompanyJobId)}>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {getMomentDate(el?.createdAt, "DD/MM/YYYY")}
                          </PrimaryBlackTypoGraphy>
                          <SecondaryBlackTypoGraphy>
                            {getNotificationTimeStatus(el?.createdAt)} ago
                          </SecondaryBlackTypoGraphy> 
                        </Box>
                        </TableCell>
                        {/* <TableCell onClick={routeToDetailHandler(el?._id)}>
                        <Stack direction="row" alignItems={"center"}>
                          {el?.job_detail?.clientDetail
                            ?.businessProfilePicture ? (
                            <Avatar
                              sx={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: ".625rem",
                              }}
                              src={
                                el?.job_detail?.clientDetail
                                  ?.businessProfilePicture
                              }
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: ".625rem",
                              }}
                              src={rectangle}
                            />
                          )}

                          <PrimaryBlackTypoGraphy>
                            {el?.job_detail?.clientDetail?.businessName
                              ?.length < 20
                              ? el?.job_detail?.clientDetail?.businessName
                              : `${el?.job_detail?.clientDetail?.businessName?.slice(
                                  0,
                                  12
                                )}...`}
                          </PrimaryBlackTypoGraphy>
                        </Stack>
                      </TableCell> */}
                        <TableCell onClick={routeToDetailHandler(el?.teamCompanyJobId)}>
                        <Box>
                        <PrimaryBlackTypoGraphy
                              className="rfqRequestType"
                              marginBottom={".31rem"}
                            >
                              {el?.job_detail[0]?.tradeType.length > 0
                                ? el?.job_detail[0]?.tradeType.map(
                                    (data: any, i: any, arr: any) => {
                                      if (i === arr.length - 1) {
                                        return (
                                          <React.Fragment key={data}>
                                            {`${
                                              data?.qty
                                            } ${capitalizeFirstLetter(
                                              data?.type
                                            )}`}
                                          </React.Fragment>
                                        );
                                      }
                                      return (
                                        <React.Fragment key={data}>
                                          {`${`${
                                            data?.qty
                                          } ${capitalizeFirstLetter(
                                            data?.type
                                          )}`}, `}
                                        </React.Fragment>
                                      );
                                    }
                                  )
                                : "-"}
                            </PrimaryBlackTypoGraphy>
                          <SecondaryBlackTypoGraphy>
                          {capitalizeFirstLetter(
                              el?.requestType.replaceAll("_", " ")
                            )}
                          </SecondaryBlackTypoGraphy>
                        </Box>
                      </TableCell>
                      <TableCell onClick={routeToDetailHandler(el?.teamCompanyJobId)}>
                      <PrimaryBlackTypoGraphy
                              className="rfqRequestType"
                              marginBottom={".31rem"}
                            >
                              {el?.companyDetail?.businessName}
                            </PrimaryBlackTypoGraphy>
                      </TableCell>
                      <TableCell>ASAP</TableCell>
                      <TableCell onClick={routeToDetailHandler(el?.teamCompanyJobId)}>
                      {el?.status === "PENDING" ? (
                            <PendingChip>Pending</PendingChip>
                          ) : el?.status === "COMPLETED" ? (
                            <RfqCompletedChip>Completed</RfqCompletedChip>
                          ) : el?.status === "IN_PROGRESS" ? (
                            <RfqSentChip>In Progress</RfqSentChip>
                          ) :el?.status === "REQUESTED" ? (
                            <RfqCancelledChip>Requested</RfqCancelledChip>)
                            : el?.status === "ASSIGNED" ? (
                                <RfqCompletedChip>Assigned</RfqCompletedChip>)
                                 :(
                            ""
                          )}
                      </TableCell>
                     
                      <TableCell onClick={routeToDetailHandler(el?.teamCompanyJobId)}>
                        <Stack direction="row" alignItems={"center"}>

                          <IconButton
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setJobId(el?.teamCompanyJobId);
                              handleMenu(e);
                            }}
                          >
                            <img src={verticalDots} alt="actions" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                      
                    </TableRow>
                )
            }):""}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e: any) => {
            routeToDetailHandler(jobId)(e);
            //  handleClose();
          }}
        >
          View job details
        </MenuItem>
        <MenuItem onClick={(e: any) => {
            enquireHandler(jobId)(e);
             handleClose();
          }} >Message Client</MenuItem>
      </Menu>
      <EnquireModal ModalOpen={modalOpen} setModalOpen={setModalOpen} />
      
      {data?.length ? null : <NoRequests />}
    </>
  );
};

export default ExternalTableDesktop;