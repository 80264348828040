const Pathname = {
  LOGIN: "/",
  NAVBAR: {
    AsCompany: {
      home: "/home",
      roster: "/roster",
      employee: "/employee",
      reports: "/reports",
    },
  },
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/user/forgot-password",
  SIGNUP: "/signup",
  VERIFY: "/verify",
  Dashboard: "/home",
  SELECT_PROFILE: "/user/emailverify",
  SELECT_OPTION: "/selectoption",
  SELECT_OPTION_LOGIN: "/selectoptionlogin",
  SOLE_TRADER_CONTACT: "/soletradercontact",
  SOLE_TRADER_DETAILS: "/soletraderdetails",
  COMPANY_DETAILS: "/companydetails",
  COMPANY_CONTACTS: "/companycontact",
  Link_SENT: "/linksent",
  Settings: {
    base: "/settings",
    Mydetails: "/settings/my-details",
    CompanyDetails: "/settings/companyprofile",
    Teams: "/settings/team",
    Financial: "/settings/financial",
    Notification: "/settings/notification",
    Invite: "/settings/invite",
    rates: {
      index: "/settings/rates",
      standard: "/settings/rates/standardrates",
      discount: "/settings/rates/discountrates",
    },
  },
  terms: "/terms",
  privacy:"/privacy",
  buildQuoteOwnPreview: "/buildquoteown/preview",
  SP_BUILD_QUOTE: "/sp/buildquote",
  SP_BUILD_QUOTE_TEMPLATE: "/sp/buildquote/template",
  SP_BUILD_QUOTE_TEMPLATE_PREVIEW: "/sp/buildquote/template/preview",
  ViewAllRequest: "/viewallrequest",
  JOB_DETAILS: "/jobDetails",
  BUILD_QUOTE_TEMPLATE: "/build-quote/template",
  BUILD_QUOTE_TEMPLATE_PREVIEW: "/build-quote/template/preview",
  BUILD_QUOTE_TEMPLATE_FINAL_SUBMIT: "/build-quote/template/final/submit",
  UPLOAD_OWN_QUOTE_TEMPLATE: "/upload-own/template",
  BUILD_QUOTE_SCRATCH: "/build-quote/scratch",
  BUILD_QUOTE_SCRATCH_PREVIEW: "/build-quote/scratch-preview",
  Landing: "/landing",
  LandingPublic: "/landingpublic",
  SAVEDCOMPANY: "/savedCompany",
  SCHEDULEBOOKING: "/ScheduleBooking",
  QUOTEASSESSMENT: "/Quoteassessment",
  EmergencyCallOut: "/EmergencyCallout",
  ProjectService: "/ProjectService",
  MaintenanceServices: "/MaintenanceServices",
};
export default Pathname;
