
const AwsCred = {
  BUCKETNAME: "app-development",
  bucketRegion: "us-east-1",
  bucketAccessKey: "AKIAQUD4MO3QQDFU67NZ",
  bucketSecretKey: "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",
  bucketUrl: "https://app-development.s3.amazonaws.com/",

};

export default AwsCred;
