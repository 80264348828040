import {
    InviteModel,
  } from "../../../../model";
  import Utils from "../../../../Utils";

export const inviteReducer = (
    state: InviteModel = new InviteModel(),
    action: any
  ) => {
    switch (action.type) {
      case Utils.ActionName.INVITE:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  