import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type BuildButtonProps = {
  children: React.ReactNode;
} & ButtonProps;

const ButtonCustomize = styled(Button)<BuildButtonProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: '#0DA4CE',
  flexBasis: '25%',
  padding: theme.spacing(0.8, 1.5),
  height: '48px',
  borderRadius: 'unset',
  '&.css-dctn0o-MuiButtonBase-root-MuiButton-root': {
    borderRadius: 'unset',
  },
  ' &:hover': {
    backgroundColor: '#097390',
  },
}));

function BuildButton({ children, ...otherProps }: BuildButtonProps) {
  return (
    <>
      <ButtonCustomize {...otherProps}>{children}</ButtonCustomize>
    </>
  );
}

export default BuildButton;
