import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Box, IconButton } from "@mui/material";
import SelectFileDrop from "../SpCreate/common/SelectFileDrop";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EditUploadSpQuoteAsDraft } from "../actions";

type SpOwnModalTypes = {
  open: boolean;
  setOpen: any;
}; 

export default function SpOwnPreviewModal({ open, setOpen }: SpOwnModalTypes) {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { state } = useLocation<any>();

  const FileHandler = (pdf: any) => {
    const data = {
      reqJobId: state?.jobId,
      pdf,
    };
    dispatch(EditUploadSpQuoteAsDraft(data, setOpen));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "#fff",
          width: "60vw",
          height: "65vh",
          position: "relative",
          padding: "3.75rem",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <SelectFileDrop fileHandler={FileHandler} />
      </Box>
    </Modal>
  );
}
