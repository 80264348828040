
const Roles = {
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
};

const UserTypes = {
  COMPANY: "COMPANY",
  COMPANYSOLETRADER: "COMPANYSOLETRADER",
};

export { Roles, UserTypes };
