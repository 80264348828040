import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";

export default function NormalButton({
  children,
  disabled,
  ...rest
}: ButtonProps) {
  const attributes: ButtonProps = {
    ...rest,
    fullWidth: true,
    disabled: disabled,
  };
  const theme = useTheme();
  return <Button {...attributes}>{children}</Button>;
}
