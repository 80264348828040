// import { profileSelectorReducer } from "./reducer";
import Utils from "../../Utils";
import {
  globalLoaderEnd,
  globalLoaderStart,
  getStartedModalOpen,
} from "../../reducer/globalReducer";
import { NotificationSettingdefaultTrue } from "../dashboard/settings/notification/action";
import { makeToken } from "../../Utils/firebase";

export const signUp = (values: any, setSubmitting: any, history: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }
    let dataToSend = {
      ...values,
      deviceId: "3",
    };

    delete dataToSend.checked;
    dispatch(globalLoaderStart());
    Utils.API.postApiCall(
      Utils.endpoint.signUp,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (data.statusCode === Utils.Constants.api_success_code.success) {
          const { accessToken, email, name, userId } = data.data;

          localStorage.setItem("verify_account_token", accessToken);
          localStorage.setItem("user_email", email);
          localStorage.setItem("user_name", name);
          localStorage.setItem("user_id", userId);
          localStorage.setItem("recieverId", data.data.userId);

          history.push(Utils.Pathname.VERIFY);

          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });

          setSubmitting(false);
          // Utils.showAlert(1, "Successfully logged in!");
        } else {
          setSubmitting(false);
          Utils.showAlert(2, data.message);

          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        setSubmitting(false);
        let { data } = error;
        console.log(data);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: false,
        });
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const resendMail = () => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    let dataToSend = {
      email:
        localStorage.getItem("user_email") || localStorage.getItem("email"),
      type: 1,
      deviceId: "string",
      deviceToken:
        localStorage.getItem("verify_account_token") || "email_not_verified",
    };
    dispatch(globalLoaderStart());
    Utils.API.postApiCall(
      Utils.endpoint.resendLink,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (data.statusCode === Utils.Constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });

          Utils.showAlert(1, data?.message);
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        Utils.showAlert(2, error?.data?.message);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: false,
        });
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const getSkills = () => {
  return (dispatch: any) => {
    Utils.API.getApiCall(
      Utils.endpoint.skills,
      ``,
      (respData: any) => {
        let { data } = respData;
        if (data.statusCode === 200) {
          console.log(data);
          dispatch({
            type: Utils.ActionName.DROPDOWN,
            payload: {
              skills: [...data.data.skills],
              industrys: [...data.data.industryExp],
              roles: [...data.data.company_roles],
            },
          });
        }
      },
      (error: any) => {
        let { data } = error;
        if (data.length === 0) {
          Utils.showAlert(2, data.message);
        }
      }
    );
  };
};

export const verifyAccount = (history: any) => {
  return async (dispatch: Function) => {
    if (!navigator.onLine) {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }
    let fbToken: any = "deviceToken";
    try {
      fbToken = await makeToken();
    } catch (error) {
      console.log(error);
    }

    let dataToSend = {
      token:
        (window.location.search.split("=")[1] as string) ||
        localStorage.getItem("verify_account_token") ||
        localStorage.getItem("accessToken"),
      deviceId: "3",
      deviceToken: fbToken,
    };

    // return;
    // dispatch(globalLoaderStart());
    Utils.API.postApiCall(
      Utils.endpoint.verify,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        console.log(data);
        if (data.statusCode === Utils.Constants.api_success_code.success) {
          Utils.CommonFunctions.setLocalStorage(data.data);
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });
          localStorage.setItem(
            "profile_complete",
            data.data.isCompanyProfileComplete
          );

          if (data?.data?.isCompanyProfileComplete === true) {
            history.push(Utils.Pathname.VERIFY);
          }
        } else {
          // console.log("hh");
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });
        }
      },
      (error: any) => {
        let { data } = error;
        if (data.statusCode === 400 || data.statusCode === 401) {
          history.push(Utils.Pathname.LOGIN);
          Utils.showAlert(2, data.message);
        }

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: false,
        });
        // dispatch(globalLoaderEnd());
      }
    );
  };
};

export const soleTraderProfileComplete = (
  values: { [key: string]: any },
  history: any
) => {
  return async (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    const { userType, mobileNo } = getState().profileSelectorReducer;

    let location = values.location;
    let valToSend = { ...values };
    delete valToSend.address;
    delete valToSend.location;
    // delete valToSend.industryExp;
    valToSend.skills = valToSend?.skills?.map((val: any) => val.TYPE);
    valToSend.industryExp = valToSend?.industryExp?.map((val: any) => val.TYPE);
    let fbToken: any = "deviceToken";
    try {
      fbToken = await makeToken();
    } catch (error) {
      console.log(error);
    }
    let dataToSend = {
      userType,
      mobileNo: `${mobileNo}`,
      location,
      companySoleTraderDetail: { ...valToSend },
      deviceId: "3",
      deviceToken: fbToken,
    };

    // console.log(
    //   "profile complete reducer",
    //   values,
    //   getState().profileSelectorReducer,
    //   dataToSend
    // );
    dispatch(globalLoaderStart());
    Utils.API.putApiCall(
      Utils.endpoint.profileComplete,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        localStorage.setItem("accessToken", data?.data?.accessToken);
        localStorage.setItem("recieverId", data.data.userId);
        // if (data?.data?.userType === "COMPANYSOLETRADER") {
        //   localStorage.setItem("user_name", data?.data?.name);
        // } else {
        //   localStorage.setItem("user_name", data?.data?.name);
        // }
        dispatch(getStartedModalOpen());
        if (!data?.data?.companyJobNotificationSetting) {
          dispatch(NotificationSettingdefaultTrue());
        }
        if (data?.data?.userType === "COMPANYSOLETRADER") {
          localStorage.setItem(
            "user_name",
            data?.data?.companySoleTraderDetail?.businessName
          );
        } else {
          localStorage.setItem(
            "user_name",
            data?.data?.companyDetail?.businessName
          );
        }

        localStorage.setItem("isEmailVerified", data?.data?.isEmailVerified);
        localStorage.setItem(
          "profile_complete",
          data.data.isCompanyProfileComplete
        );
        localStorage.setItem("userType", data.data.userType);

        if (data.statusCode === Utils.Constants.api_success_code.success) {
          history.push(Utils.Pathname.Dashboard);
        } else {
          Utils.showAlert(2, data.message);
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: false,
        });
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const CompanyProfileComplete = (
  values: { [key: string]: any },
  history: any
) => {
  console.log("action called");

  return async (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    const { userType, location, skills, industryExp } =
      getState().profileSelectorReducer;

    let valToSend = { ...getState().profileSelectorReducer };
    delete valToSend.address;
    delete valToSend.location;
    delete valToSend.skills;
    delete valToSend.industryExp;
    delete valToSend.userType;
    delete valToSend?.mobileNo;

    valToSend.availableTrades = skills.map((val: any) => val.TYPE);
    valToSend.industryExp = industryExp.map((val: any) => val.TYPE);

    let copyCompanyDetail = { ...values.companyDetail };
    copyCompanyDetail.officeNo = `${copyCompanyDetail.officeNo}`;

    delete copyCompanyDetail.mobileNo;
    let fbToken: any = "deviceToken";
    try {
      fbToken = await makeToken();
    } catch (error) {
      console.log(error);
    }

    let dataToSend = {
      userType,
      mobileNo: `${values.mobileNo}`,
      location,
      companyDetail: { ...valToSend, ...copyCompanyDetail },
      deviceId: "3",
      deviceToken: fbToken,
    };

    console.log(dataToSend);
    dispatch(globalLoaderStart());
    Utils.API.putApiCall(
      Utils.endpoint.profileComplete,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (data.statusCode === Utils.Constants.api_success_code.success) {
          localStorage.setItem("accessToken", data?.data?.accessToken);
          localStorage.setItem("recieverId", data.data.userId);

          dispatch(getStartedModalOpen());

          // if (data?.data?.userType === "COMPANYSOLETRADER") {
          //   localStorage.setItem("user_name", data?.data?.name);
          // } else {
          //   localStorage.setItem("user_name", data?.data?.name);
          // }
          if (data?.data?.userType === "COMPANYSOLETRADER") {
            localStorage.setItem(
              "user_name",
              data?.data?.companySoleTraderDetail?.businessName
            );
          } else {
            localStorage.setItem(
              "user_name",
              data?.data?.companyDetail?.businessName
            );
          }
          localStorage.setItem(
            "profile_complete",
            data?.data?.isCompanyProfileComplete
          );
          localStorage.setItem("userType", data.data.userType);

          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });
          history.push(Utils.Pathname.Dashboard);

          Utils.showAlert(1, data.message);
        } else {
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: false,
          });
          Utils.showAlert(2, data.message);
        }
        if (!data?.data?.companyJobNotificationSetting) {
          dispatch(NotificationSettingdefaultTrue());
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: false,
        });
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const ContactUs = (values: any, type: any) => {
  return (dispatch: Function) => {
    // if (!navigator.onLine) {
    //   dispatch({
    //     type: Utils.ActionName.LOADING,
    //     payload: false,
    //   });
    //   return;
    // }
    let dataToSend = {
      ...values,
      type: type,
    };

    dispatch(globalLoaderStart());
    Utils.API.postApiCall(
      Utils.endpoint.contactUs,
      dataToSend,
      (respData: any) => {
        let { data } = respData;
        if (data.statusCode === Utils.Constants.api_success_code.success) {
          // dispatch({
          //   type: Utils.ActionName.LOADING,
          //   payload: false,
          // });
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
          // dispatch({
          //   type: Utils.ActionName.LOADING,
          //   payload: false,
          // });
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        console.log(data);
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: false,
        // });
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const getRoles = () => {
  return (dispatch: any) => {
    Utils.API.getApiCall(
      Utils.endpoint.roles,
      ``,
      (respData: any) => {
        let { data } = respData;
        if (data.statusCode === 200) {
          console.log(data);
          dispatch({
            type: Utils.ActionName.ROLES,
            payload: {
              roles: [...data.data.roles],
              skills: [...data.data.skills],
              industryExp: [...data.data.industryExp],
            },
          });
        }
      },
      (error: any) => {
        let { data } = error;
        console.log(data);
      }
    );
  };
};
