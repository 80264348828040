import { FormikErrors, FormikTouched, validateYupSchema } from "formik";
import moment from "moment-timezone";

export const getInitialValues = (obj: any) => ({
  clientname:
    obj && obj["job_detail.clientDetail.businessName"]
      ? obj["job_detail.clientDetail.businessName"]
      : "",
  todayDate:
    obj && obj["quoteDate"]
      ? moment(new Date(obj["quoteDate"])).format("DD/MM/YYYY")
      : moment(new Date()).format("DD/MM/YYYY"),
  validDate:
      obj && obj["quoteValidDate"]
        ? moment(new Date(obj["quoteValidDate"])).subtract(1,"days").format("YYYY-MM-DD")
        : "",
  
  quoteno: obj && obj["quoteNumber"] ? obj["quoteNumber"] : "",
  projectname: obj && obj["job_detail.projectName"] ? obj["job_detail.projectName"] : "",
  clientlocation:
    obj && obj["job_detail.clientDetail.location.address"]
      ? obj["job_detail.clientDetail.location.address"]
      : "",
});

export const getObjectValues = (obj: { [key: string]: any }) => {
  const values = Object.values(obj);
  values.forEach((val) =>
    val && typeof val === "object"
      ? getObjectValues(val)
      : console.log("MMM", val)
  );
};

export const checkErrors = (
  errors: FormikErrors<{ [key: string]: any }>,
  touched: FormikTouched<{ [key: string]: any }>
) => {
  if (Object.keys(errors).length === 0 && Object.keys(touched).length > 0)
    return 1;
  return 0;
};
