import React from "react";
import { useEffect } from "react";
import ModalBox from "../../../../../components/modal/modalBox";
import AssignModal from "./assignEmployeeList";
// import MultiInput from "../../../../components/inputfield/MultiInput";
import {
  useTheme,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  styled,
  Tooltip,
} from "@mui/material";

import { useStyles } from "../../../settings/team/style";
import Utils from "../../../../../Utils";
import LocalImages from "../../../../../Utils/localImages";
// import { deleteTeamMember } from "./action";
import { capitalizeFirstLetter } from "../../../../../Utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getEmployeeJobListReducerState,
  assignJobsReduserState,
  assignRequestTypeState,
} from "../../reducer";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material";
import { useClassStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { getEmployeeJobs } from "../../action";
import NormalButton from "../../../../../components/button/NormalButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { assignJob, deleteBooking } from "../../action";
import { ReducersModel } from "../../../../../model";
import { getExternalTradeDetails } from "../../../../sp/spBuildsQuotes/action";

const { circle } = LocalImages;

type AssignEmployeeProps = {
  handleClose: () => void;
  modalContainerStyle: string;
  data?: any;
  userId?: any;
};

type ModalHeaderProps = {
  handleClose: () => void;
  data?: any;
};

const ModalHeader = ({ handleClose, data }: ModalHeaderProps) => {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();

  const remove = () => {
    dispatch(deleteBooking(data._id));
    // history?.push(Utils.Pathname.ViewAllRequest);
    handleClose();
    // window.location.reload();
  };

  return (
    <>
      <div className={classes.modalHeader}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "16px",
            color: "#4F4F4F",
          }}
        >
          Assign Job
        </Typography>

        <CloseIcon onClick={remove} sx={{ cursor: "pointer" }}></CloseIcon>
      </div>
    </>
  );
};

function AssignEmployee({
  data,
  handleClose,
  modalContainerStyle,
}: AssignEmployeeProps) {
  const theme = useTheme();
  const classes = useClassStyles({ theme });
  const dispatch = useDispatch();
  const history = useHistory();
  const userList: any = [];
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [jobData, setJobData] = React.useState<any>(null);
  const [jobData2, setJobData2] = React.useState<any>(null);
  const [tradeIndex, setTradeIndex] = React.useState<number>(0);
  const { EmployeeList } = useSelector(getEmployeeJobListReducerState);
  const { invitedEmployee, invitedEmployee2 } = useSelector(
    assignJobsReduserState
  );
  const { externalTradeDetails } = useSelector(
    (state: ReducersModel) => state.externalTradeDetailsReducer
  );

  const { tradeList, tradeTypeC } = useSelector(assignRequestTypeState);

  const searchList: any = [];

  EmployeeList &&
    EmployeeList.map((val: any) => {
      searchList.push({
        empId: val?._id,
        title: val.name,
        userTrade: val.userTrades[0],
        profile: val?.profilePicture,
      });
    });

  useEffect(() => {
    localStorage.setItem("isTeamUp", data?.isTeamUp);
    const tradeTypeData =
      data?.job_detail?.tradeType.length > 0 &&
      data?.job_detail?.tradeType.map((el: any) => {
        return el?.type;
      });
    const tradeTypeString = `&tradeType=${tradeTypeData
      ?.map((i: any) => {
        return i;
      })
      .join("&tradeType=")}`;
    console.log(tradeTypeString, "data");

    localStorage.setItem("_id", data?._id);
    localStorage.setItem("jobId", data?.jobId);
  }, []);

  useEffect(() => {
    dispatch(
      getExternalTradeDetails(
        localStorage.getItem("jobId"),
        localStorage.getItem("_id")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  externalTradeDetails &&
    externalTradeDetails?.map((value: any) => {
      if (value?.userDetail[0]) {
        invitedEmployee2.push({
          userId: value?.userDetail[0]?._id,
          trade: value?.trade,
          isExternal: value?.isExternal,
        });
      }
    });

  invitedEmployee2 &&
    invitedEmployee2?.map((value: any) => {
      userList?.push(value?.userId);
    });



    

  const assignJobs = () => {
    dispatch(assignJob(userList, invitedEmployee2, data._id, data.jobId));
    history?.push(Utils.Pathname.ViewAllRequest);
    handleClose();
    // window.location.reload();
  };

  // const underDev = () => {
  //   history.push(Utils.Pathname.NAVBAR.AsCompany.roster);
  // };

  tradeTypeC.push(data?.job_detail?.tradeType);
  return (
    <div
      className={modalContainerStyle}
      style={{
        minHeight: "500px",
      }}
    >
      <ModalHeader handleClose={handleClose} data={data} />
      <TableContainer sx={{ maxHeight: "350px" }}>
        <Table
          sx={{
            width: 400,
            maxHeight: "400px",
            marginLeft: "30px",
            overflow: "scroll",
            [`& .${tableCellClasses.root}`]: {
              border: "1px solid #E0E0E0",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4f4f4f",
                  }}
                >
                  Trade Needed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#4F4F4F",
                  }}
                >
                  Employee
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tradeList?.length > 0
              ? tradeList?.map((item: any, index: number) => {
                  console.log(
                    externalTradeDetails[index]?.userDetail[0]?.name,
                    "externalTradeNAme"
                  );
                  return (
                    <>
                      <TableRow
                        key={`row-${index}`}
                        sx={{
                          "&:hover": {
                            background: "#c0e8f2",
                          },
                        }}
                      >
                        <Tooltip
                          title={capitalizeFirstLetter(
                            item.trade?.replaceAll("_", " ")
                          )}
                        >
                          <TableCell>
                            {capitalizeFirstLetter(
                              item.trade?.replaceAll("_", " ")
                            )}
                          </TableCell>
                        </Tooltip>
                        {!data?.isTeamUp === true ? (
                          <TableCell
                            onClick={() => {
                              setJobData(item.trade);
                              setJobData2(data);
                              setModalOpen1(true);
                              setTradeIndex(index);
                            }}
                          >
                            {item.employee ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={
                                    item.employee?.profile
                                      ? item.employee?.profile
                                      : circle
                                  }
                                  srcSet={item.employee?.profile}
                                  alt=""
                                />

                                <Tooltip title={item.employee?.title}>
                                  <Typography sx={{ ml:"18px" , mt: "3px", width: "100%" }}>
                                    {`${item.employee?.title.slice(
                                          0,
                                          10
                                        )}...`}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ) : (
                              <>
                                <ArrowDropDownIcon sx={{ marginLeft: "90%" }} />
                              </>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                          // onClick={() => {
                          //   setJobData(item.trade);
                          //   setJobData2(data);
                          //   setModalOpen1(true);
                          //   setTradeIndex(index);
                          // }}
                          >
                            {externalTradeDetails[index]?.userDetail[0] ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  style={{
                                    width: "35px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  src={
                                    item.employee?.profile
                                      ? item.employee?.profile
                                      : circle
                                  }
                                  srcSet={item.employee?.profile}
                                  alt=""
                                />

                                <Tooltip
                                  title={
                                    externalTradeDetails[index]?.userDetail[0]
                                      ?.name
                                  }
                                >
                                  <Typography sx={{ mt: "3px", width: "100%" }}>
                                    {externalTradeDetails[index]?.userDetail[0]
                                      ?.name.length > 5
                                      ? externalTradeDetails[index]
                                          ?.userDetail[0]?.name
                                      : `${externalTradeDetails[
                                          index
                                        ]?.userDetail[0]?.name.slice(0, 5)}...`}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ) : (
                              <>
                                <ArrowDropDownIcon sx={{ marginLeft: "90%" }} />
                              </>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          // backgroundColor: "#fff",
          flexDirection: "row",
          paddingLeft: "6.5%",
          paddingRight: "5.5%",
          [theme.breakpoints.down(600)]: {
            backgroundColor: "#E5E5E5",
          },
        }}
      >
        {invitedEmployee?.length === tradeList?.length ||
        invitedEmployee?.length > tradeList?.length || data?.isTeamUp === true ? (
          <NormalButton
            disabled
            sx={{
              width: "49%",
              height: "48px",
              // float: "right",
              mt: "2%",
              mr: "1%",
              backgroundColor: "#FFFFFF",
              color: "#0DA4CE",
              border: "1px solid #E0E0E0",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
              [theme.breakpoints.down(600)]: {
                marginRight: "-30px",
              },
            }}
          >
            <img
              src={LocalImages.addIcon}
              style={{ marginRight: "8px" }}
              alt="add"
            />
            Team Up
          </NormalButton>
        ) : data?.requestType === "EMERGENCY_CALLOUT" ? (
          <NormalButton
            sx={{
              width: "49%",
              height: "48px",
              // float: "right",
              mt: "2%",
              mr: "1%",
              backgroundColor: "#FFFFFF",
              color: "#0DA4CE",
              border: "1px solid #E0E0E0",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
              [theme.breakpoints.down(600)]: {
                marginRight: "-30px",
              },
            }}
            onClick={() => history.push(Utils.Pathname.EmergencyCallOut, data)}
          >
            <img
              src={LocalImages.addIcon}
              style={{ marginRight: "8px" }}
              alt="add"
            />
            Team Up
          </NormalButton>
        ) : (
          <NormalButton
            sx={{
              width: "49%",
              height: "48px",
              // float: "right",
              mt: "2%",
              mr: "1%",
              backgroundColor: "#FFFFFF",
              color: "#0DA4CE",
              border: "1px solid #E0E0E0",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
              [theme.breakpoints.down(600)]: {
                marginRight: "-30px",
              },
            }}
            onClick={() => history.push(Utils.Pathname.ProjectService, data)}
          >
            <img
              src={LocalImages.addIcon}
              style={{ marginRight: "8px" }}
              alt="add"
            />
            Team Up
          </NormalButton>
        )}

        {invitedEmployee?.length === tradeList?.length ||
        invitedEmployee?.length > tradeList?.length  || data?.isTeamUp==true
        ? (
          <NormalButton
            sx={{
              width: "50%",
              height: "48px",
              float: "right",
              mt: "2%",
              backgroundColor: "#0DA4CE",
              color: "#fff",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0DA4CE",
              },
            }}
            onClick={assignJobs}
          >
            Assign
          </NormalButton>
        ) : (
          <NormalButton
            disabled
            sx={{
              width: "50%",
              height: "48px",
              float: "right",
              mt: "2%",
              backgroundColor: "#0DA4CE",
              color: "#fff",
              borderRadius: "0px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0DA4CE",
              },
              "&:disabled": {
                backgroundColor: "#E5E5E5",
              },
            }}
          >
            Assign
          </NormalButton>
        )}
        <ModalBox
          isOpen={modalOpen1}
          handleClose={() => setModalOpen1(false)}
          modalStyle={"deletemodalStyle"}
          children={
            <AssignModal.AssignEmployeeList
              tradeIndex={tradeIndex}
              data={jobData}
              data2={jobData2}
              handleClose={() => setModalOpen1(false)}
              modalContainerStyle={classes.deletemodalStyle}
            ></AssignModal.AssignEmployeeList>
          }
        />
      </div>
    </div>
  );
}

const TeamModal = {
  AssignEmployee,
};

export default TeamModal;
