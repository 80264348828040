import moment from "moment";
import { Formik, Form } from "formik";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import DateTimePicker from "../../../../components/DataTimePicker";
import TextfieldWrapper from "../../../../components/Textfield";
import PaperBodyMobile from "../../PaperBody/paperBodyMobile";
import { mobileUseStyles } from ".././selectTemplateCss";
import BuildQuoteItemModal from "./buildQuoteItemModal";
import { SubmitSelectTemplate } from ".././action";
import { ReducersModel } from "../../../../model";
import { theme } from "../../../../theme/theme";
import Schema from "../../../../schema";
import Utils from "../../../../Utils";
import Title from "../../Title";

interface LocationState {
  pathname: string;
  id: string;
  quoteSelection: string;
  history: any;
}
export interface INITIAL_FORM_FORMIK_VALUE {
  quoteSelection: string;
  date: string;
  validUntil: string;
  quoteNumber: string;
  title: string;
  summary: string;
  dragData: {
    description: string;
    quantity: string;
    unitPrice: string;
    discount: string;
    gst: string;
    amountAud: string;
  }[];
  isDraft: boolean;
  notes: string;
  id: string;
  comments: string;
}

const INITIAL_FORM_STATE = {
  quoteSelection: "",
  date: moment().format("YYYY-MM-DD"),
  validUntil: "",
  quoteNumber: "",
  title: "",
  summary: "",
  dragData: [
    {
      description: "",
      quantity: "",
      unitPrice: "",
      discount: "0",
      gst: "10",
      amountAud: "",
    },
  ],
  isDraft: false,
  notes: "Thank You !",
  id: "",
  comments: "",
};

const MobileResponsiveQuote = () => {
  const classes = mobileUseStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);

  const handleCloseSuccess = useCallback(() => {
    setOpenSuccess(false);
  }, []);

  const [initialValue, setInitialValue] =
    useState<INITIAL_FORM_FORMIK_VALUE>(INITIAL_FORM_STATE);

  const {
    JobDetailsData,
    itemCosting,
    date,
    quoteValidDate,
    title,
    summary,
    draft,
    notes,
    comments,
    _id,
    quoteNumber,
  } = useSelector((state: ReducersModel) => state.jobDetailsModelReducer);

  useEffect(() => {
    if (date || quoteValidDate || summary || title || itemCosting) {
      setInitialValue({
        quoteSelection: location.state.quoteSelection,
        date: moment().format("YYYY-MM-DD"),
        validUntil: moment(quoteValidDate).format("YYYY-MM-DD"),
        quoteNumber: quoteNumber,
        title: title,
        summary: summary,
        dragData: itemCosting,
        isDraft: draft,
        notes: notes,
        id: _id,
        comments: comments,
      });
    }
  }, [
    comments,
    date,
    draft,
    _id,
    itemCosting,
    notes,
    quoteNumber,
    quoteValidDate,
    summary,
    title,
    location.state.quoteSelection,
  ]);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.SELECT_TEMPLATE_MODEL,
      payload: {
        data: initialValue,
        id: location.state.id,
      },
    });
  }, [location.state.id, dispatch, initialValue]);

  const handleSaveDraft = (values: INITIAL_FORM_FORMIK_VALUE) => {
    const newAllValues = { ...values, isDraft: true };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        location.state.id,
        location.state.quoteSelection,
        history
      )
    );
  };

  const handleModalOpen = () => {
    setOpenSuccess(true);
  };
  const handleSubmit = (value: INITIAL_FORM_FORMIK_VALUE) => {
    const newAllValues = { ...value, isDraft: true };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        location.state.id,
        location.state.quoteSelection,
        history
      )
    );
  };

  const computeSubTotal = () => {
    let number: number = 0;
    initialValue?.dragData?.forEach((data: any) => {
      const { amountAud } = data;
      number = number + Number(amountAud);
    });
    return number;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Formik
          initialValues={{
            ...initialValue,
          }}
          validationSchema={Schema.SelectTemplateSchemaMobile}
          onSubmit={(values: INITIAL_FORM_FORMIK_VALUE) => {
            handleSubmit(values);
          }}
          enableReinitialize
        >
          {({ values }) => (
            <>
              <Form>
                <Title>
                  <Button
                    sx={{
                      size: "small",
                      borderRadius: "0px",
                      color: "var(--outlineColor)",
                    }}
                    // onClick={() => handleSaveDraft(values)}
                  >
                    {"<"}
                  </Button>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    variant="h4"
                    color="text.secondary"
                  >
                    Build Quote
                  </Typography>
                  <Box className={classes.titleChildren}>
                    <Button
                      sx={{
                        size: "small",
                        borderRadius: "0px",
                        color: "var(--outlineColor)",
                      }}
                      // onClick={() => handleSaveDraft(values)}
                    >
                      ...
                    </Button>
                  </Box>
                </Title>
                <Box
                  sx={{
                    borderTop: "5px solid var(--modalBoxShadowColor)",
                    borderRadius: "5px",
                  }}
                ></Box>
                <PaperBodyMobile>
                  <Box className={classes.gridMuiroot}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={3.5}>
                        <Typography sx={{ padding: "3px 0px" }} variant="h5">
                          Quote for
                        </Typography>

                        <TextfieldWrapper
                          name="quoteSelection"
                          className={classes.textField}
                          size="small"
                          value={
                            JobDetailsData.length > 0 &&
                            JobDetailsData.map((item: any) => {
                              return item?.job_detail?.clientDetail
                                ?.businessName;
                            })
                          }
                          inputProps={{ readOnly: true, width: "80%" }}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={2.5}></Grid>

                      <Grid item xs={6} sm={6} md={2}>
                        <Typography variant="h5">Date</Typography>
                        <DateTimePicker
                          name="date"
                          size="small"
                          className={classes.textField}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} md={2}>
                        <Typography variant="h5">Valid Until</Typography>

                        {values.date && (
                          <DateTimePicker
                            inputProps={{
                              min: moment(new Date(values.date))
                                .add(1, "day")
                                .format("YYYY-MM-DD"),
                              placeholder: "Please Select",
                            }}
                            name="validUntil"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h5">Quote Number</Typography>
                        <TextfieldWrapper
                          name="quoteNumber"
                          size="small"
                          className={classes.textField}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Typography sx={{ padding: "10px 0" }} variant="h5">
                          Title & Summary of Project
                        </Typography>
                        <TextfieldWrapper
                          name="title"
                          size="small"
                          placeholder="Title of project"
                          className={classes.textField}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={7}></Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextfieldWrapper
                          name="summary"
                          size="small"
                          placeholder="Summary of what needs to be done"
                          className={classes.textField}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Typography sx={{ padding: "10px 0" }} variant="h5">
                          Items
                        </Typography>
                        <Box
                          sx={{
                            border: "3px solid var(--modalBoxShadowColor)",
                          }}
                        >
                          {initialValue?.dragData?.length > 0 &&
                            initialValue?.dragData.map(
                              (item: any, indx: number) => {
                                return (
                                  <Box>
                                    {item?.unitPrice && (
                                      <Box
                                        key={indx}
                                        sx={{ backgroundColor: "white", pt: 2 }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            ml: 1.5,
                                            mr: 1.5,
                                          }}
                                        >
                                          <Box>{item?.description}</Box>

                                          <Box>
                                            <Typography>AUD</Typography>
                                            {item?.unitPrice}
                                          </Box>
                                        </Box>

                                        <Box sx={{ ml: 1.5, mr: 1.5 }}>
                                          {`${item?.quantity} X AUD${item?.unitPrice}`}
                                        </Box>
                                      </Box>
                                    )}
                                    <Divider />
                                  </Box>
                                );
                              }
                            )}
                        </Box>
                        <Box className={classes.addButtonRow}>
                          <Button
                            fullWidth
                            onClick={handleModalOpen}
                            variant="outlined"
                            sx={{
                              border: "1px solid var(--modalBoxShadowColor)",
                              borderRadius: "unset",
                            }}
                          >
                            + Add Item{" "}
                          </Button>
                          <Box className={classes.calculation}>
                            <Box className={classes.totalCal}>
                              <Typography variant="body2">Subtotal</Typography>
                              <Typography variant="body2">
                                {computeSubTotal().toFixed(2)}
                              </Typography>
                            </Box>
                            <Box className={classes.totalCal}>
                              <Typography variant="body2">
                                Total GST 10%
                              </Typography>
                              <Typography variant="body2">
                                {((computeSubTotal() * 10) / 100).toFixed(2)}
                              </Typography>
                            </Box>

                            <Divider
                              style={{
                                backgroundColor: "var(--outlineColor)",
                                borderColor: "unset",
                              }}
                            />
                            <Box className={classes.totalAmount}>
                              <Typography variant="subtitle1">Total</Typography>
                              <Typography variant="subtitle1">
                                {(
                                  computeSubTotal() +
                                  (computeSubTotal() * 10) / 100
                                ).toFixed(2)}
                              </Typography>
                            </Box>
                            <Divider
                              style={{
                                backgroundColor: "var(--outlineColor)",
                                borderColor: "unset",
                              }}
                            />
                            <Box sx={{ marginTop: 0.2 }}>
                              <Divider
                                style={{
                                  backgroundColor: "var(--outlineColor)",
                                  borderColor: "unset",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.gridMuirootValue}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography variant="h5">Notes</Typography>
                        <TextfieldWrapper
                          name="notes"
                          fullWidth
                          size="small"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </PaperBodyMobile>
                <Box className={classes.buttonPositions}>
                  <Box className={classes.buttonAll}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      sx={{
                        marginBottom: 2,
                        color: "var(--white)",
                        borderRadius: "unset",
                        "&:hover": {
                          backgroundColor: "var(--buttonHover)",
                          color: "var(--white)",
                        },
                      }}
                      // disabled={!(isValid && dirty)}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      sx={{
                        marginBottom: 2,
                        borderRadius: "unset",
                        "&:hover": {
                          backgroundColor: "var(--buttonHover)",
                          color: "var(--white)",
                        },
                      }}
                      onClick={() => handleSaveDraft(values)}
                    >
                      Save as Draft
                    </Button>
                  </Box>
                </Box>
              </Form>
            </>
          )}
        </Formik>
        <BuildQuoteItemModal
          open={openSuccess}
          handleClose={handleCloseSuccess}
          heading="Add Item"
        />
      </ThemeProvider>
    </>
  );
};

export default MobileResponsiveQuote;
