import { Box, InputLabel, TextField, TextFieldProps } from "@mui/material";
import React from "react";

const InputField = ({
  fieldTag,
  id,
  ...rest
}: { fieldTag: string } & TextFieldProps) => {
  return (
    <Box>
      <InputLabel
        sx={{
          color: "var(--clientColor)",
        }}
        htmlFor={id}
      >
        {fieldTag}
      </InputLabel>
      <TextField
        id={id}
        sx={{
          marginTop: ".7rem",
          "& input": {
            padding: ".9rem .7rem",
            background: "#fff",
            "&:disabled": {
              background: "#fff",
              color:"black",
            },
          },
          "& fieldset": {
            borderRadius: 0,
            border: "1px solid #bdbdbd !important",
          },
        }}
        {...rest}
      />
    </Box>
  );
};

export default React.memo(InputField);
