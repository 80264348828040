import React, { lazy } from "react";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UnderDevlopmentInvite from "../components/UnderDevlopmentInvite";
import ViewAll from "../screen/dashboard/dashboardPage/ViewAll";
import SelectTemplate from "../screen/sp/selectTemplate/index";
import SpBuildsQuotes from "../screen/sp/spBuildsQuotes";
import Navbar from "../components/Navbar/Navbar";
import SpPreviews from "../screen/sp/spPreview";
import PrivateRoute from "../hoc/privateRoute";
import PublicRoute from "../hoc/publicRoute";
import PublicRouter from "../hoc/publicRouter";
import Utils from "../Utils";
import buildQuoteFinalSubmission from "../screen/sp/spPreview/mobileResponsive/buildQuoteFinalSubmission";
import SpCreateScratch from "../screen/spScratch";
import Preview from "../screen/SpOwn/SpPreview/Preview";
import SPownPreview from "../screen/SpOwn/SpPreview";
import Notification from "../components/Notifications";

const SoleTraderDetails = lazy(
  () => import("../screen/signup/soleTraderDetails")
);
const LandingPublic = lazy(() => import("../screen/LandingPublic"));
const LandingPage = lazy(() => import("../screen/LandingPage"));
const Login = lazy(() => import("../screen/login/index"));
const Signup = lazy(() => import("../screen/signup/index"));
const Verify = lazy(() => import("../screen/signup/verify"));
const Terms = lazy(() => import("../screen/signup/t&c/terms"));
const Privacy = lazy(() => import("../screen/signup/t&c/privacy"));
const LinkSent = lazy(() => import("../screen/login/linkSent"));
const Dashboard = lazy(() => import("../screen/dashboard/dashboardPage"));
const Settings = lazy(() => import("../screen/dashboard/settings"));
const ResetPassword = lazy(() => import("../screen/login/resetPassword"));
const SelectProfile = lazy(() => import("../screen/signup/selectProfile"));
const SelectOption = lazy(() => import("../screen/signup/selectOption"));
const SelectOptionLogin = lazy(
  () => import("../screen/signup/selectOptionLogin")
);
const ForgotPassword = lazy(() => import("../screen/login/forgotPassword"));
const SoleTrader = lazy(() => import("../screen/signup/soleTraderContact"));
const CompanyDetails = lazy(() => import("../screen/signup/companyDetails"));
const CompanyContact = lazy(() => import("../screen/signup/companyContact"));
const SpScratchPreview = lazy(() => import("../screen/spScratch/spPreview"));
const ViewAllScheduleBooking = lazy(
  () => import("../screen/dashboard/dashboardPage/Bookings/viewAll")
);
const SavedCompany = lazy(() => import("../screen/LandingPage/savedCompany"));
const QuoteAssessment = lazy(
  () => import("../screen/sp/spBuildsQuotes/quoteAssessment")
);
const EmergencyCallout = lazy(
  () => import("../screen/EmergencyCallout")
);
const MaintenanceServices = lazy(
  () => import("../screen/MaintenanceSupport")
);
const ProjectSupport = lazy(
  () => import("../screen/ProjectSupport")
);

const Routers: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2aaebc",
        light: "#f5f6fa",
      },
      secondary: {
        main: "#363353",
        light: "#4f4f4f",
      },
      text: {
        primary: "#000000",
        secondary: "#100d2e",
      },
    },
    typography: {
      fontFamily: "Inter",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Notification />
        <Switch>
          <PublicRouter
            path={`${Utils.Pathname.LandingPublic}`}
            component={LandingPublic}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.LOGIN}`}
            component={Login}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.terms}`}
            component={Terms}
            exact
            testing
          />
          <PublicRoute
            path={`${Utils.Pathname.privacy}`}
            component={Privacy}
            exact
            testing
          />
          <PublicRoute
            path={`${Utils.Pathname.FORGOT_PASSWORD}`}
            component={ForgotPassword}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SIGNUP}`}
            component={Signup}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.VERIFY}`}
            component={Verify}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.RESET_PASSWORD}`}
            component={ResetPassword}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SELECT_PROFILE}`}
            component={SelectProfile}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SELECT_OPTION}`}
            component={SelectOption}
            exact
          />

          <PublicRoute
            path={`${Utils.Pathname.SELECT_OPTION_LOGIN}`}
            component={SelectOptionLogin}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SOLE_TRADER_CONTACT}`}
            component={SoleTrader}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SOLE_TRADER_DETAILS}`}
            component={SoleTraderDetails}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.COMPANY_DETAILS}`}
            component={CompanyDetails}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.Link_SENT}`}
            component={LinkSent}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.COMPANY_CONTACTS}`}
            component={CompanyContact}
            exact
          />

          <PrivateRoute
            path={`${Utils.Pathname.Dashboard}`}
            component={Dashboard}
            exact
          />

          <PrivateRoute
            path={`${Utils.Pathname.Settings.base}`}
            component={Settings}
          />

          <PrivateRoute
            path={`${Utils.Pathname.BUILD_QUOTE_TEMPLATE}`}
            component={SelectTemplate}
            exact
          />
          <PrivateRoute
            path={`${Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW}`}
            component={SpPreviews}
            exact
          />
          <PrivateRoute
            path={`${Utils.Pathname.QUOTEASSESSMENT}/:id`}
            component={QuoteAssessment}
            exact
          />

          {/* <PrivateRoute
            path={`${Utils.Pathname.UPLOAD_OWN_QUOTE_TEMPLATE}`}
            component={UploadYourOwn}
            exact
          /> */}
          {/* For mobile view last submit build quote */}
          <PrivateRoute
            exact
            path={`${Utils.Pathname.BUILD_QUOTE_TEMPLATE_FINAL_SUBMIT}`}
            component={buildQuoteFinalSubmission}
          />
          {/* Jobs Details Here */}
          <PrivateRoute
            exact
            path={`${Utils.Pathname.JOB_DETAILS}/:id`}
            component={SpBuildsQuotes}
          />

          <PrivateRoute
            exact
            path={`${Utils.Pathname.BUILD_QUOTE_SCRATCH_PREVIEW}/:id`}
            component={SpScratchPreview}
          />

          <PrivateRoute
            exact
            path={`${Utils.Pathname.BUILD_QUOTE_SCRATCH}/:id`}
            component={SpCreateScratch}
          />

          <PrivateRoute
            exact
            path={`${Utils.Pathname.ViewAllRequest}`}
            component={ViewAll}
          />

          <PrivateRoute
            path={`${Utils.Pathname.EmergencyCallOut}`}
            component={EmergencyCallout}
          />

          <PrivateRoute
            path={`${Utils.Pathname.MaintenanceServices}`}
            component={MaintenanceServices}
          />
          <PrivateRoute
            path={`${Utils.Pathname.ProjectService}`}
            component={ProjectSupport}
          />

          {/* SPBUILDQUOTEOWN */}

          <PrivateRoute
            exact
            path={`${Utils.Pathname.buildQuoteOwnPreview}`}
            component={SPownPreview}
          />
          <PrivateRoute
            path={`${Utils.Pathname.SCHEDULEBOOKING}`}
            component={ViewAllScheduleBooking}
          />
          <PrivateRoute path={`/landing`} component={LandingPage} exact />

          <PrivateRoute
            path={`${Utils.Pathname.SAVEDCOMPANY}`}
            component={SavedCompany}
          />

          <Route path="/underDevlopment">
            <UnderDevlopmentInvite />
          </Route>

          <Route path="/*">
            <Navbar />
            <Typography
              sx={{ marginTop: "7rem" }}
              textAlign="center"
              variant="h3"
            >
              Under Development
            </Typography>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default Routers;
