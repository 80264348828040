import React from "react";
import { Box, IconButton, Modal, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

type PropTypes = {
  ModalOpen: boolean;
  handleLogout?: any;
  setModalOpen?: any;
};

interface MyThemeComponentProps {
  bgColor?: string;
  theme?: any;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<MyThemeComponentProps>(({ bgColor, theme }) => ({
  background: bgColor ,
  borderRadius: "0",
  padding: ".9rem 3rem",
  color: "#fff",
  textTransform: "initial",
  "&:hover": {
    background: bgColor,
    // color: "#fff",
  },
  [theme.breakpoints.down("md")]: {
    padding: ".9rem 2.6rem",
  },
}));

const LogoutModal = ({ ModalOpen, setModalOpen, handleLogout }: PropTypes) => {
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      open={ModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={(theme) => ({
          width: "28rem",
          height: "11.5rem",
          backgroundColor: "#fff",
          position: "relative",
          padding: "1.6rem 1.5rem",
          [theme.breakpoints.down("md")]: {
            width: "24rem",
            height:"13.5rem"
          },
        })}
      >
        <IconButton
          sx={{
            position: "absolute",
            padding: "0",
            top: "1.25rem",
            right: "1.25rem",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography
            sx={{
              color: "#4f4f4f",
              fontSize: "1.12rem",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Log out
          </Typography>
          <Typography
            sx={{
              color: "#4f4f4f",
              marginBottom: "1rem",
              fontWeight: "400",
            }}
            id="modal-modal-description"
          >
            Are you sure you want to log out ?
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
            },
          })}
        >
          <StyledButton
            sx={{
              color: "#000",
              border: "1px solid #e0e0e0",
              marginRight: "1rem",
            }}
            onClick={handleClose}
            bgColor="#fff"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleLogout} bgColor="#0da4ce">
            Yes, Logout
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
