import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import CommonFunction, {
  checkTimeRange,
  capitalizeFirstLetter,
  getNotificationTimeStatus,
} from "../../../../../../Utils/commonFunctions";
import LocalImages from "../../../../../../Utils/localImages";
import moment from "moment";
import {
  RfqAcceptedChip,
  PendingChip,
  CompletedChip,
  RfqCancelledChip,
  RfqCompletedChip,
  RfqDraftChip,
  RfqSentChip,
} from "../../common/styledComponent";
import { useStyles } from "../../../../settings/team/style";
import { useHistory } from "react-router-dom";
import Utils from "../../../../../../Utils";
import NoRequests from "../../../common/NoRequests";
import ModalBox from "../../../../../../components/modal/modalBox";
import AssignModal from "../../../Requests/maintainance/assignModal";
import AssignModalMS from "../../../Requests/maintainance/assignMS";
import AssignModalMSR from "../../../Requests/maintainance/assignJobMsr";
import NoResult from "../../../Bookings/noResult";
import { getDatesInRange, getDatesInRangeM } from "../../../../../../Utils/commonFunctions";
import EllipsisToolTip from "ellipsis-tooltip-react-chan";
import { useDispatch } from "react-redux";
import { requestType } from "../../../../../../Utils/data";
import EnquireModal from "../../../Requests/maintainance/enquireModal";
// import SearchNotFound from "../../common/SearchNotFound";
const { verticalDots } = LocalImages;

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
  fontWeight: 400,
  fontSize: "14px",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
  fontWeight: 400,
  fontSize: "12px",
});

const StyledButton = styled(Button)({
  textTransform: "initial",
  border: "1px solid #9EDBEB",
  color: "#097390",
  fontSize: ".875rem",
  fontWeight: 500,
  borderRadius: 0,
  padding: ".688rem 1.35rem",
  "&:hover": {
    border: "1px solid #9EDBEB",
    color: "#097390",
  },
});

const ViewAllDeskTop = ({ data, search }: any) => {
  const getMonth = (date: any) => {
    return `${moment(date).format("MMM")} ${moment(date).format("D")}, ${moment(
      date
    ).format("YYYY")}`;
  };
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const [jobData, setJobData] = useState<any>(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [modalOpen2, setModalOpen2] = React.useState(false);
  const [modalOpen3, setModalOpen3] = React.useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [job, setJob] = useState<any>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setJob(null);
    setAnchorEl(null);
  };
  const underDev = () => {
    history.push(Utils.Pathname.NAVBAR.AsCompany.roster);
  };
  const routeToDetailBqmodalOpen = (jobId: any) => (e: any) => {
    history.push(`${Utils.Pathname.JOB_DETAILS}/${jobId}`, true);
  };
  const enquireHandler = (jobId: any) => (e: any) => {
    setModalOpen(true);
  };

  const BuildQuoteHandler = (job: any) => (e: any) => {
    // if (job?.status === "SENT" || job?.status === "COMPLETED" ) {
    //   history.push({
    //     pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
    //     state: {
    //       id: job?._id,
    //       quoteSelection: "TEMPLATE",
    //       status: true,
    //     },
    //   });
    //   return;
    // }
    history.push(`${Utils.Pathname.JOB_DETAILS}/${job?._id}`);
  };
  const options = {
    effect: "solid",
    place: "top",
  };
  const handleAssign = (el: { [key: string]: any }) => {
    if (requestType[el?.requestType as string] === 0) {
      if (el?.job_detail?.timeFrame[0]?.recurringShift === false) {
        let dateData = [];
        let dateTest = [];
        let dateDataUTC = [];
        let nonRecurringList = [];
        let dateList = [];
        let tradeList = [];
        let time = [];
        // let searchList = [];

        if (
          moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)) ===
          moment(new Date(el?.job_detail?.timeFrame[0]?.endDate))
        ) {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        } else {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        }
        for (let i = 0; i < el?.job_detail?.timeFrame?.length; i++) {
          time.push(
            `${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.startDate
            )}`}-${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.endDate
            )}`}`
          );
        }
        console.log(time, "time");

        for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
          for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.job_detail?.tradeType[i]?.type);
          }
        }

        for (let i = 0; i < el?.allDateArray[0]?.date?.length; i++) {
          dateTest.push(el?.allDateArray[0]?.date[i]);
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let i = 0; i < dateTest.length; i++) {
            dateData.push({
              date: dateTest[i],
              shift: el?.job_detail?.timeFrame[0].shiftType,
              time: time[0],
            });
          }
        } else {
          for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
            dateData.push({
              date: getDatesInRangeM(
                new Date(el?.job_detail?.timeFrame[i]?.startDate),
                new Date(el?.job_detail?.timeFrame[i]?.endDate)
              ),
              shift: el?.job_detail?.timeFrame[i].shiftType,
              time: time[i],
              dateTime: el?.allDateArray[0]?.date,
            });
          }
          console.log(dateData, "date");
        }

        for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
          dateDataUTC.push({
            date: getDatesInRangeM(
              el?.job_detail?.timeFrame[i]?.startDate,
              el?.job_detail?.timeFrame[i]?.endDate
            ),
          });
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let k = 0; k < dateData.length; k++) {
            for (let i = 0; i < tradeList.length; i++) {
              nonRecurringList.push({
                tradeType: tradeList[i],
                date: dateData[k]?.date,
                shift: dateData[k]?.shift,
                time: dateData[k]?.time,
                employee: null,
              });
            }
          }
        } else {
          for (let k = 0; k < dateData.length; k++) {
            for (let j = 0; j < dateData[k]?.date.length; j++) {
              for (let i = 0; i < tradeList.length; i++) {
                nonRecurringList.push({
                  tradeType: tradeList[i],
                  date: dateData[k]?.date[j],
                  dateTime: dateData[k]?.dateTime[j],
                  shift: dateData[k]?.shift,
                  time: dateData[k]?.time,
                  employee: null,
                });
              }
            }
          }
        }
        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            nonRecurringList,
          },
        });

        setModalOpen2(true);
        console.log(nonRecurringList, "AssignMS1");
      } else {
        let monday: any = [];
        let tuesday: any = [];
        let wednesday: any = [];
        let thursday: any = [];
        let friday: any = [];
        let saturday: any = [];
        let sunday: any = [];
        let daysData: any = [];
        let tableData2: any = [];
        let daySlots: any = [];
        let tradeList: any = [];
        let time :any=[]
        // let searchList: any = [];

        daysData.push(el?.job_detail?.timeFrame[0]?.days);

        daySlots.push(el?.daySlots[0]);

        for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
          for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.job_detail?.tradeType[i]?.type);
          }
        }
        for (let i = 0; i < el?.job_detail?.timeFrame?.length; i++) {
          time.push(
            `${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.startDate
            )}`}-${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.endDate
            )}`}`
          );
        }

        //For MSR
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["friday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              friday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["friday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["monday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              monday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["monday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["tuesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              tuesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["tuesday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["wednesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              wednesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["wednesday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["thursday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              thursday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["thursday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["saturday"]?.length; k++) {
          daySlots[0]["saturday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              saturday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["saturday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["sunday"]?.length; k++) {
          daySlots[0]["sunday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              sunday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["sunday"][k]?.shiftType,
                time:time[0],
                employee: null,
              });
            }
          });
        }

        tableData2.push(sunday);
        tableData2.push(monday);
        tableData2.push(tuesday);
        tableData2.push(wednesday);
        tableData2.push(thursday);
        tableData2.push(friday);
        tableData2.push(saturday);

        let dList: any = [];
        let recurringList: any = [];

        tableData2 &&
          tableData2?.map((item: any) => {
            dList.push(item);
          });

        dList &&
          dList?.map((item: any) => {
            if (item?.length) {
              recurringList.push(item);
            } else {
              recurringList.push([]);
            }
          });

        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            sunday,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            recurringList,
          },
        });

        setModalOpen3(true);
      }
    } else if (
      requestType[el?.requestType as string] === 1 ||
      requestType[el?.requestType as string] === 2
    ) {
      let tradeList = [];
      for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
        for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
          tradeList.push({
            trade: el?.job_detail?.tradeType[i]?.type,
            employee: null,
          });
        }
      }
      dispatch({
        type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          tradeList,
        },
      });
      setModalOpen1(true);
    }
    setJobData(el);
  };
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            "& thead": {
              background: "rgba(240, 240, 240, 0.2)",
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              "& th": {
                fontWeight: 400,
                fontSize: ".75rem",
                minheight: "2.25rem",
                color: "#828282",
                paddingY: ".625rem",
              },
            },

            "& tbody": {
              background: "#fff",

              "& tr": {
                paddingY: "1.5rem",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Date Recieved</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Request Type</TableCell>
              <TableCell>Request Details</TableCell>
              <TableCell>Date Needed</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length
              ? data?.map((el: any) => {
                  return (
                    <TableRow key={el?._id}>
                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {moment(new Date(el?.createdAt)).format(
                              "DD/MM/YYYY"
                            )}
                            {/* {/ {getMomentDate(el?.createdAt, "DD/MM/YYYY")} /} */}
                          </PrimaryBlackTypoGraphy>
                          <SecondaryBlackTypoGraphy>
                            Received {getNotificationTimeStatus(el?.createdAt)} ago
                          </SecondaryBlackTypoGraphy>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {el?.job_detail?.clientDetail?.businessName.length <
                            20
                              ? el?.job_detail?.clientDetail?.businessName
                              : `${el?.job_detail?.clientDetail?.businessName?.slice(
                                  0,
                                  20
                                )}...`}
                          </PrimaryBlackTypoGraphy>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box>
                          <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                            {el?.requestType =="RFQ"? "RFQ": capitalizeFirstLetter(
                              el?.requestType.replaceAll("_", " ")
                            )}
                          </PrimaryBlackTypoGraphy>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <PrimaryBlackTypoGraphy className="rfqRequestType">
                          <EllipsisToolTip options={options}>
                            {el?.job_detail?.projectType?.length &&
                            el?.job_detail?.projectType?.length > 1 ? (
                              el?.job_detail?.projectType === "RFQ" ? (
                                el?.job_detail?.projectType.map(
                                  (el: any, i: any, arr: any) => {
                                    if (i === arr.length - 1) {
                                      return (
                                        <React.Fragment key={el}>
                                          {el}
                                        </React.Fragment>
                                      );
                                    }
                                    return (
                                      <React.Fragment key={el}>
                                        {`${el}, `}
                                      </React.Fragment>
                                    );
                                  }
                                )
                              ) : (
                                ""
                              )
                            ) : el?.job_detail?.projectType?.length ? (
                              <>{el?.job_detail?.projectType[0]}</>
                            ) : el?.job_detail?.tradeType.length > 0 ? (
                              el?.job_detail?.tradeType.map(
                                (el: any, i: any, arr: any) => {
                                  console.log(i, "fjndjknfjnjd");
                                  if (i === arr.length - 1) {
                                    return (
                                      <React.Fragment key={el}>
                                        {el?.qty}{" "}
                                        {capitalizeFirstLetter(
                                          el?.type?.replaceAll("_", " ")
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                  return (
                                    <React.Fragment key={el}>
                                      {`${el?.qty} ${capitalizeFirstLetter(
                                        el?.type?.replaceAll("_", " ")
                                      )},`}
                                    </React.Fragment>
                                  );

                                  // return(
                                  //   <>
                                  //   <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                                  //         {i.qty} {capitalizeFirstLetter(i.type.replaceAll('_', ' '))}
                                  //   </PrimaryBlackTypoGraphy>
                                  //   </>
                                  // )
                                }
                              )
                            ) : (
                              "-"
                            )}
                          </EllipsisToolTip>
                        </PrimaryBlackTypoGraphy>
                      </TableCell>

                      <TableCell>
                        <Box>
                          {el?.requestType === "RFQ" ? (
                            "-"
                          ) : el?.requestType === "EMERGENCY_CALLOUT" ? (
                            <>
                              <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                                ASAP
                              </PrimaryBlackTypoGraphy>
                            </>
                          ) : el?.requestType === "PROJECT_SUPPORT" ? (
                            <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                              {moment(
                                new Date(el?.job_detail?.estimateStartDate)
                              ).format("DD/MM/YYYY")}

                              <SecondaryBlackTypoGraphy>
                                {/* {checkTimeRange(el?.createdAt)} */}~
                                {
                                   getDatesInRange(
                                      new Date(
                                        el?.job_detail?.estimateStartDate
                                      ),
                                      new Date(el?.job_detail?.estimateEndDate)
                                    )
                                      }
                              </SecondaryBlackTypoGraphy>
                            </PrimaryBlackTypoGraphy>
                          ) : (
                            <PrimaryBlackTypoGraphy marginBottom={".31rem"}>
                              {moment(
                                new Date(
                                  el?.job_detail?.timeFrame[0]?.startDate
                                )
                              ).format("DD/MM/YYYY")}
                              <SecondaryBlackTypoGraphy>
                                {/* {checkTimeRange(el?.createdAt)} */}
                                {`${` (${CommonFunction.getTime(
                                    el?.job_detail?.timeFrame[0]?.startDate
                                  )}`}-${`${CommonFunction.getTime(
                                    el?.job_detail?.timeFrame[0]?.endDate
                                  )})`}`}
                              </SecondaryBlackTypoGraphy>
                            </PrimaryBlackTypoGraphy>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          justifyContent="space-between"
                        >
                          {el?.requestType === "RFQ" &&
                          el?.status === "SENT" ? (
                            <RfqSentChip>Sent</RfqSentChip>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "DRAFT" ? (
                            <RfqDraftChip>draft</RfqDraftChip>
                          ) : (el?.requestType === "EMERGENCY_CALLOUT" ||
                              el?.requestType === "PROJECT_SUPPORT" ||
                              el?.requestType === "MAINTENANCE_SERVICE") &&
                            el?.status === "COMPLETED" ? (
                            <CompletedChip>Completed</CompletedChip>
                          ) : (el?.requestType === "EMERGENCY_CALLOUT" ||
                              el?.requestType === "PROJECT_SUPPORT" ||
                              el?.requestType === "MAINTENANCE_SERVICE") &&
                            el?.status === "PENDING" ? (
                            <PendingChip>Pending</PendingChip>
                          ) : (el?.requestType === "EMERGENCY_CALLOUT" ||
                              el?.requestType === "PROJECT_SUPPORT" ||
                              el?.requestType === "MAINTENANCE_SERVICE") &&
                            el?.status === "IN_PROGRESS" ? ( 
                            <RfqSentChip>In Progress</RfqSentChip>
                          ) : (el?.requestType === "EMERGENCY_CALLOUT" ||
                          el?.requestType === "PROJECT_SUPPORT" ||
                          el?.requestType === "MAINTENANCE_SERVICE") &&
                        el?.status === "ASSIGNED" ? (
                          <CompletedChip>Assigned</CompletedChip>
                        ) : el?.requestType === "RFQ" &&
                            el?.status === "BUILD_QUOTE" ? (
                            el?.isDraft ? (
                              <RfqDraftChip>Draft</RfqDraftChip>
                            ) : (
                              <StyledButton
                                sx={{
                                  width: "7.76rem",
                                  marginRight: "1rem",
                                }}
                                onClick={routeToDetailBqmodalOpen(el?._id)}
                                disableRipple
                              >
                                Build Quote
                              </StyledButton>
                            )
                          ) : el?.status === "REQUESTED" ? (
                            <StyledButton
                              sx={{
                                width: "7.76rem",
                                marginRight: "1rem",
                              }}
                              onClick={() => handleAssign(el)}
                              disableRipple
                            >
                              Assign
                              <img
                                style={{ marginLeft: "10%" }}
                                src={`${LocalImages.arrowDown}`}
                                alt="arrowDown"
                              />
                            </StyledButton>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "COMPLETED" ? (
                            <RfqCompletedChip>Completed</RfqCompletedChip>
                          ) : el?.requestType === "RFQ" &&
                            el?.status === "CANCELLED" ? (
                            <RfqCancelledChip>Cancelled</RfqCancelledChip>
                          ) : (
                            <Typography>{el?.status}</Typography>
                          )}

                          <IconButton
                            onClick={(e: any) => {
                              setJob(el);
                              localStorage.setItem(
                                "clientId",
                                el?.job_detail?.clientDetail?.clientId
                              );
                              handleMenu(e);
                            }}
                          >
                            <img src={verticalDots} alt="actions" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            BuildQuoteHandler(job)("");
          }}
        >
          View request
        </MenuItem>
        <MenuItem onClick={(e: any) => {
            enquireHandler(job)(e);
            
             handleClose();
          }} >Message Client</MenuItem>
      </Menu>
      <EnquireModal ModalOpen={modalOpen} setModalOpen={setModalOpen} />
      {!search && !data?.length ? <NoResult/> : null}
      <ModalBox
        isOpen={modalOpen1}
        handleClose={() => setModalOpen1(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModal.AssignEmployee
            data={jobData}
            handleClose={() => setModalOpen1(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModal.AssignEmployee>
        }
      />
      <ModalBox
        isOpen={modalOpen2}
        handleClose={() => setModalOpen2(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModalMS.AssignEmployeeMS
            data={jobData}
            handleClose={() => setModalOpen2(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModalMS.AssignEmployeeMS>
        }
      />
      <ModalBox
        isOpen={modalOpen3}
        handleClose={() => setModalOpen3(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModalMSR.AssignEmployeeMSR
            data={jobData}
            handleClose={() => setModalOpen3(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModalMSR.AssignEmployeeMSR>
        }
        />
    </>
  );
};

export default ViewAllDeskTop;
