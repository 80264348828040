import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import LocalImages from "../../../../Utils/localImages";
import { getRfqJobs, jobsCounter } from "../action";
import {
  getRQFJobsReducerState,
  getMaintainanceJobsReducerState,
  jobsCounterReducerState,
  getAllJobsReducerState,
  getExternalReducerState
} from "../reducer";
import ViewAllDeskTop from "./ViewAllJobs";
import ViewAllMobile from "./ViewAllJobs/ViewAllMobile";
import NoRequests from "../common/NoRequests";
import ViewAllRfqs from "./ViewAllRfqs";
import ViewAllMaintainance from "./ViewAllMaintainance/index";
import ViewALlExternal from "./ViewAllExternal/index";

const { filterViewAll } = LocalImages;

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "2rem 10.5rem 2rem 10.5rem",
  [theme.breakpoints.down("md")]: {
    padding: "2rem 1.5rem 2rem 1.5rem",
  },
}));

const TitleBar = styled(Box)({
  padding: "1.5rem 1.5rem 1.62rem 1.5rem",
});

const PrimaryBlackTypoGraphy = styled(Typography)({
  color: "#4f4f4f",
});
const SecondaryBlackTypoGraphy = styled(Typography)({
  color: "#828282",
});

const TertiaryBlackTypoGraphy = styled(Typography)({
  color: "#344054",
});

const StyledTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: ".87rem",
  color: "#828282",
});

const SubmitButton = styled(Button)(({ theme }) => ({
  // padding: ".9rem 2.2rem",
  background: "#0da4ce",
  "&:hover": {
    background: "#0da4ce",
  },
  color: "#fff",
  borderRadius: "0",
  textTransform: "initial",
}));

const FilterButton = styled(Button)(({ theme }) => ({
  padding: ".9rem 2.2rem",
  background: "#f2f2f2",
  "&:hover": {
    background: "#f2f2f2",
  },
  border: "1px solid #bdbdbd",
  color: "#4f4f4f",
  borderRadius: "0",
  textTransform: "initial",
  marginLeft: "2rem",
}));

const FilterIconButton = styled(IconButton)(({ theme }) => ({
  background: "#f2f2f2",
  "&:hover": {
    background: "#f2f2f2",
  },
  border: "1px solid #bdbdbd",
  color: "#4f4f4f",
  textTransform: "initial",
  marginLeft: "2rem",
}));

const PaginationButton = styled(Button)(({ theme }) => ({
  // padding: ".9rem 2.2rem",
  background: "#fff",
  "&:hover": {
    background: "#fff",
  },
  display: "flex",
  alignItems: "center",
  color: "#828282",
  borderRadius: "0",
  textTransform: "initial",
  marginLeft: "2rem",
}));

const RequestTypesData = ["All", "Support", "RFQ", "External"];

const queryBuilderFunc = (queryData: any) => {
  // console.log(queryData, "Data")
  let queryStr = "?";
  for (let k in queryData) {
    if (queryData[k]) {
      queryStr = queryStr + `${k}=${queryData[k]}&`;
    }
  }
  queryStr = queryStr.slice(0, -1);
  return queryStr;
};

const ViewAll = () => {
  const [currentTab, setCurrentTab] = useState("All");
  const {ExternalList} = useSelector(getExternalReducerState)
  // const { RfqList } = useSelector(getRQFJobsReducerState);
  // const { MaintainanceList } = useSelector(getMaintainanceJobsReducerState);
  // const { AllJobList } = useSelector(getAllJobsReducerState);
  const { counterList } = useSelector(jobsCounterReducerState);
  const theme = useTheme();
  const tabChangeHandler = (tab: any) => () => {
    setCurrentTab(tab);
  };

  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    let queryStr = queryBuilderFunc({
      companyId: localStorage.getItem("user_id"),
      pageNo: 1,
      limit: 5,
      requestType:
        currentTab === "All" ? "" : currentTab === "RFQ" ? currentTab : "",
      searchKey: "",
      sortOrder: -1,
      sortBy: "createdAt",
    });
    dispatch(getRfqJobs(queryStr));
  }, [currentTab]);

  useEffect(() => {
    dispatch(jobsCounter(`?companyId=${localStorage.getItem("user_id")}`));
  }, []);

  return (
    <BoxContainer>
      <PrimaryBlackTypoGraphy
        sx={{
          fontWeight: 500,
          fontSize: "1.12rem",
          marginBottom: "1.25rem",
        }}
      >
        {isSmallScreen ? "My Requests" : "Requests"}
      </PrimaryBlackTypoGraphy>
      {!isSmallScreen ? (
        <Stack
          sx={{
            borderBottom: "1px solid #e0e0e0",
            paddingLeft: "1.3rem",
            "& div": {
              [theme.breakpoints.down("md")]: {
                paddingX: "0",
              },
              "& div:last-of-type": {
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              },
            },
            [theme.breakpoints.down("md")]: {
              paddingLeft: ".4rem",
            },
          }}
          direction="row"
          spacing={theme.breakpoints.up("md") ? 3 : 0}
        >
          {RequestTypesData?.map((el: any, index: any, arr: any) => {
            return (
              <Box
                key={el}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "1.06rem",
                  cursor: "pointer",
                  borderBottom:
                    currentTab === arr[index] ? "3px solid #0da4ce" : "none",
                  color: currentTab === arr[index] ? "#0da4ce" : "#828282",
                  [theme.breakpoints.down("md")]: {
                    flex: "1",
                  },
                }}
                onClick={tabChangeHandler(el)}
              >
                <SecondaryBlackTypoGraphy
                  sx={{
                    color: "inherit",
                    marginRight: ".3rem",
                    fontWeight: 400,
                    fontSize: ".9rem",
                  }}
                >
                  {el}
                </SecondaryBlackTypoGraphy>

                <Box
                  sx={{
                    color: currentTab === arr[index] ? "#097390" : "#344054",
                    height: "1.3rem",
                    width: "1.3rem",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: ".75rem",
                    fontWeight: "500",
                    background:
                      currentTab === arr[index] ? "#f0f9ff" : "#f2f4f7",
                  }}
                >
                  {arr[index] === "RFQ"
                    ? counterList?.rfq
                    : arr[index] === "Support"
                    ? counterList?.maintenance
                    : arr[index] === "All" 
                    ? counterList?.all: arr[index]==="External"? ExternalList.length :"0"
                  }
                </Box>
              </Box>
            );
          })}
        </Stack>
      ) : null}

      {currentTab === "RFQ" ? (
        <ViewAllRfqs />
      ) : currentTab === "Support" ? (
        <ViewAllMaintainance />
      ) :currentTab === "All" ? (
        <ViewAllDeskTop/>
        
         ) : currentTab==="External"? (<ViewALlExternal/>): ( 
        <NoRequests />
      )}
    </BoxContainer>
  );
};

export default ViewAll;
