import Utils from "../../Utils";
import { ExternalTradeDetailsModel } from "../../model";

export const externalTradeDetailsReducer = (
  state: ExternalTradeDetailsModel = new ExternalTradeDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EXTERNAL_TRADE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
