import { Box, Typography } from "@mui/material";
import React from "react";

const SearchNotFound = () => {
  return (
    <Box
      height="25vh"
      display="flex"
      justifyContent={"center"}
      alignItems="center"
    >
      <Typography color="#4f4f4f">Search not found!</Typography>
    </Box>
  );
};

export default SearchNotFound;
