import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box, Typography } from "@mui/material";

export default function MultipleSelectCheckmarks({
  data,
  setData,
  CheckBoxData = [],
  placeholder,
  style = {},
}: {
  data: any;
  setData: any;
  CheckBoxData: any;
  placeholder: any;
  style?: React.CSSProperties;
}) {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    if (
      data?.includes("All") &&
      value?.includes("All") &&
      value?.length < data?.length
    ) {
      setData(value.filter((el: any) => el !== "All"));
      return;
    }

    if (data?.includes("All") && !value?.includes("All")) {
      setData([]);
      console.log("condition rane");
      return;
    }
    // (!value?.includes("All") && value?.length === CheckBoxData?.length - 1)
    if (value?.includes("All")) {
      setData(CheckBoxData);
      return;
    }

    setData(value);
  };
  return (
    <FormControl>
      <Select
        sx={{
          ...style,
          background: "#fff",
          "& .MuiSelect-select": {},
          "& fieldset": {
            borderRadius: 0,
            border: "1px solid #D0D5DD !important",
          },

          "& .MuiSelect-outlined": {
            textAlign: "start",
            color: "#4F4F4F",
            borderRadius: "0",
          },
          "& li": {
            color: "#4f4f4f !important",
            background: "#fff !important",
          },
        }}
        id="demo-multiple-checkbox"
        multiple
        value={data}
        onChange={handleChange}
        input={<OutlinedInput />}
        displayEmpty
        renderValue={() => {
          if (data?.includes("All")) {
            return <Typography sx={{ color: "#4f4f4f" }}>All</Typography>;
          }
          return data?.length ? (
            data?.join(",")
          ) : (
            <Typography sx={{ color: "#4f4f4f" }}>Status</Typography>
          );
        }}
        MenuProps={
          {
            // style:{
            //   height:""
            // }
          }
        }
        placeholder={placeholder || ""}
      >
        {CheckBoxData?.map((name: any, i: number, arr: any) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={data.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
