import { SelectTemplateModel } from '../../../model';
import Utils from '../../../Utils';

interface ActionName {
  LOADING?: string;
  SELECT_TEMPLATE_MODEL?: string;
}

interface ActionType {
  type: ActionName;
  payload: GlobalPayload;
}

type GlobalPayload = SelectTemplateModelPayload;
type SelectTemplateModelPayload = Partial<SelectTemplateModelDefine>;

interface SelectTemplateModelDefine {
  quoteSelection: string;
  date: string;
  validUntil: string;
  quoteNumber: string;
  title: string;
  summary: string;
  dragData: {
    description: string;
    quantity: string;
    unitPrice: string;
    discount: string;
    gst: string;
    amountAud: string;
  }[];
  isDraft: boolean;
  notes: string;
  id: string;
  addComment: string;
}

export const selectTemplateModelReducer = (
  state: SelectTemplateModel = new SelectTemplateModel(),
  action: ActionType
) => {
  switch (action.type) {
    case Utils.ActionName.SELECT_TEMPLATE_MODEL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
